export default function (font, id = "") {
    if (!font) {
        return
    }

    const styleId = "editorFont"
    const courseId = `${id ? `#${id}` : ""}`

    const text = `

    ${courseId} .ProseMirror, ${courseId} .text-view {
        ${font.family ? `font-family: ${font.family};` : ""}
        ${font.size ? `font-size: ${font.size};` : ""}
    }

    ${courseId} .ProseMirror p , ${courseId} .text-view p  {
        ${font.height ? `line-height: ${font.height};` : ""}
    }`

    let selector

    if (document.getElementById(styleId)) {
        selector = document.getElementById(styleId)
        selector.textContent = text
    } else {
        selector = document.createElement("style")
        selector.id = styleId
        selector.textContent = text
        document.head.appendChild(selector)
    }
}
