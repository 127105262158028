
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#52565C" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.75 16C12.75 15.5858 12.4142 15.25 12 15.25C11.5858 15.25 11.25 15.5858 11.25 16H12.75ZM16 20.75C16.4142 20.75 16.75 20.4142 16.75 20C16.75 19.5858 16.4142 19.25 16 19.25V20.75ZM8 19.25C7.58579 19.25 7.25 19.5858 7.25 20C7.25 20.4142 7.58579 20.75 8 20.75V19.25ZM5 5.75H19V4.25H5V5.75ZM19.25 6V15H20.75V6H19.25ZM19 15.25H5V16.75H19V15.25ZM4.75 15V6H3.25V15H4.75ZM5 15.25C4.86193 15.25 4.75 15.1381 4.75 15H3.25C3.25 15.9665 4.0335 16.75 5 16.75V15.25ZM19.25 15C19.25 15.1381 19.1381 15.25 19 15.25V16.75C19.9665 16.75 20.75 15.9665 20.75 15H19.25ZM19 5.75C19.1381 5.75 19.25 5.86193 19.25 6H20.75C20.75 5.0335 19.9665 4.25 19 4.25V5.75ZM5 4.25C4.0335 4.25 3.25 5.0335 3.25 6H4.75C4.75 5.86193 4.86193 5.75 5 5.75V4.25ZM11.25 16V20H12.75V16H11.25ZM12 20.75H16V19.25H12V20.75ZM12 19.25H8V20.75H12V19.25Z"
        />
    </svg>
</template>
<script>
export default {
    name: "DesktopIcon"
}
</script>
