import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core"

import { ListItem } from "@tiptap/extension-list-item"
import { TextStyle } from "@tiptap/extension-text-style"

export const inputRegex = /^(\d+)\.\s$/

export const Detail = Node.create({
    name: "orderedList",

    addOptions() {
        return {
            itemTypeName: "listItem",
            HTMLAttributes: {},
            keepMarks: false,
            keepAttributes: false
        }
    },

    group: "block list",

    content() {
        return `${this.options.itemTypeName}+`
    },

    addAttributes() {
        return {
            start: {
                default: 1,
                parseHTML: element => {
                    return element.hasAttribute("start") ? parseInt(element.getAttribute("start") || "", 10) : 1
                }
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: "ol"
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        const { start, ...attributesWithoutStart } = HTMLAttributes

        return start === 1
            ? ["ol", mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0]
            : ["ol", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            toggleDetail:
                () =>
                ({ commands, chain }) => {
                    if (this.options.keepAttributes) {
                        return chain()
                            .toggleList(this.name, this.options.itemTypeName, this.options.keepMarks)
                            .updateAttributes(ListItem.name, this.editor.getAttributes(TextStyle.name))
                            .run()
                    }
                    return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks)
                }
        }
    },

    addKeyboardShortcuts() {
        return {
            "Mod-Shift-7": () => this.editor.commands.toggleOrderedList()
        }
    },

    addInputRules() {
        let inputRule = wrappingInputRule({
            find: inputRegex,
            type: this.type,
            getAttributes: match => ({ start: +match[1] }),
            joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1]
        })

        if (this.options.keepMarks || this.options.keepAttributes) {
            inputRule = wrappingInputRule({
                find: inputRegex,
                type: this.type,
                keepMarks: this.options.keepMarks,
                keepAttributes: this.options.keepAttributes,
                getAttributes: match => ({
                    start: +match[1],
                    ...this.editor.getAttributes(TextStyle.name)
                }),
                joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
                editor: this.editor
            })
        }
        return [inputRule]
    }
})
