export default {
    methods: {
        t(key, parameters) {
            let value, group, groupKey

            for (group in this.langsKeys) {
                for (groupKey in this.langsKeys[group]) {
                    if (groupKey === key) value = this.langsKeys[group][groupKey]
                }
            }

            if (typeof value === "string" && typeof parameters === "object" && parameters !== null) {
                for (const [paramKey, paramValue] of Object.entries(parameters)) {
                    if (paramValue) {
                        if (value.includes(`:${paramKey}`)) {
                            value = value.replace(`:${paramKey}`, paramValue)
                        } else {
                            value = value.replace(`${paramKey}`, paramValue)
                        }
                    }
                }
            }

            return value ? value : key
        }
    }
}
