<template>
    <flex-container :align="isIpad() ? 'flex-start' : 'center'">
        <editor-dropdown is-child :editor="editor" class="min-150" ref="dropdownFont">
            <template #handler>
                <p class="font-overflow" :data-tippy-content="t('builder.font')" :style="{ fontFamily: font.family }">
                    {{ fonts[font.family] || font.family }},
                </p>
            </template>
            <template #content>
                <template v-for="(fontValue, fontKey, index) in fonts">
                    <div
                        v-if="fontValue !== Object.values(fonts)[index - 1]"
                        :key="index"
                        class="editor-menu-item"
                        @click="setFontFamily(fontKey)"
                        :style="{ fontFamily: fontKey }"
                        :class="{
                            active:
                                editor.isActive('textStyle', {
                                    fontFamily: fontKey
                                }) || fontValue === fonts[font.family]
                        }"
                    >
                        {{ fontValue }}
                    </div>
                </template>
            </template>
        </editor-dropdown>
        <editor-dropdown
            is-child
            :editor="editor"
            :handler-style="{ fontSize: '14px', padding: '0 1px', marginLeft: '-4px', marginRight: '4px' }"
            class="l-25"
            ref="dropdownSize"
        >
            <template #handler>
                <p :data-tippy-content="t('builder.font_size')">{{ font.size }}</p>
            </template>
            <template #content>
                <DefaultButton
                    v-if="!isOpenCustomFontSize"
                    @mousedown.native.stop.prevent="isOpenCustomFontSize = true"
                    class="mb-5 custom-font-container"
                    background="white"
                    :text="t('builder.own_size')"
                    size="sm"
                />
                <flex-container class="mb-5 custom-font-container" v-else>
                    <input
                        :max="100"
                        v-model="customFontSize"
                        :placeholder="t('builder.own_size_descr')"
                        class="custom-input-container"
                        type="number"
                    />

                    <FloatingButton
                        :radius="4"
                        border-hover-color="green"
                        :class="{ disabled: !customFontSize || customFontSize > 100 }"
                        @click="addCustomFont"
                        :size="33"
                    >
                        <CheckIcon fill="#fff" />
                    </FloatingButton>
                </flex-container>
                <template v-for="(fontSize, index) in sizes">
                    <div
                        :key="index"
                        class="editor-menu-item"
                        @click="setFontSize(fontSize)"
                        :class="{
                            active:
                                editor.isActive('textStyle', {
                                    fontSize: fontSize
                                }) || fontSize === font.size
                        }"
                    >
                        {{ fontSize }}
                    </div>
                </template>
            </template>
        </editor-dropdown>
        <div class="divider" style="margin: 0 0.25rem"></div>
        <editor-dropdown is-child :editor="editor" class="l-16" ref="dropdownHeight">
            <template #handler>
                <p
                    :data-tippy-content="t('builder.height')"
                    style="display: flex; align-items: center; justify-content: center"
                >
                    <svg
                        style="margin-right: 4px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11 4H21V6H11V4ZM6 7V11H4V7H1L5 3L9 7H6ZM6 17H9L5 21L1 17H4V13H6V17ZM11 18H21V20H11V18ZM9 11H21V13H9V11Z"
                        ></path>
                    </svg>
                    {{ getLineHeight() }}
                </p>
            </template>
            <template #content>
                <template v-for="(line, index) in lines">
                    <div
                        :key="index"
                        class="editor-menu-item"
                        @click="setLineHeight(line)"
                        :class="{
                            active: line == parseFloat(font.height) / parseFloat(font.size)
                        }"
                    >
                        {{ line }}
                    </div>
                </template>
            </template>
        </editor-dropdown>
    </flex-container>
</template>

<script>
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import FloatingButton from "@components/Buttons/FloatingButton.vue"
import EditorDropdown from "@components/Editor/components/EditorDropdown.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import { fontSizes } from "@components/Editor/extensions/font-size-extension"
import CheckIcon from "@icons/CheckIcon.vue"
import IsOpen from "@mixins/is-open"
import checkDevice from "../../../mixins/checkDevice"

export default {
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    name: "EditorFont",
    components: {
        CheckIcon,
        FloatingButton,
        DefaultButton,
        FlexContainer,
        EditorDropdown
    },
    mixins: [checkDevice, IsOpen],
    data() {
        return {
            isOpenCustomFontSize: false,
            customFontSize: "",
            fonts: {
                [`Lato  , sans-serif`]: "Lato",
                [`ubuntu, serif`]: "Ubuntu",
                [`montserrat, sans-serif`]: "Montserrat",
                [`comfortaa, sans-serif`]: "Comfortaa",
                [`inter`]: "Inter",
                [`arial, 'helvetica neue', helvetica, sans-serif`]: "Arial",
                [`'comic sans ms', 'marker felt-thin', arial, sans-serif`]: "Comic Sans",
                [`'courier new', courier, 'lucida sans typewriter', 'lucida typewriter', monospace`]: "Courier New",
                [`georgia, times, 'times new roman', serif`]: "Georgia",
                [`'impact', 'helvetica neue', helvetica, sans-serif`]: "Impact",
                [`impact, 'helvetica neue', helvetica, sans-serif`]: "Impact",
                [`tahoma, verdana, segoe, sans-serif`]: "Tahoma",
                [`'times new roman', times, baskerville, georgia, serif`]: "Times New Roman",
                [`'trebuchet ms', 'lucida grande', 'lucida sans unicode', 'lucida sans', tahoma, sans-serif`]:
                    "Trebuchet",
                [`verdana, geneva, sans-serif`]: "Verdana",
                [`monospace, sans-serif`]: "Monospace",
                [`Oswald, sans-serif`]: "Oswald",
                [`Pacifico, sans-serif`]: "Pacifico",
                [`JetBrainsMono, sans-serif`]: "JetBrainsMono",
                [`lato, sans-serif`]: "Lato",
                [`geologica, sans-serif`]: "Geologica"
            },
            sizes: fontSizes(),
            font: {
                family: `lato, sans-serif`,
                size: `16px`,
                height: "1.3"
            },
            lines: new Array(13).fill(7).map((number, index) => `${(number + index + 1) / 10}`),
            loading: false
        }
    },
    mounted() {
        document.addEventListener("selectionchange", () => {
            if (this.getCurrentFont()) {
                this.font = this.getCurrentFont()
            }

            this.$forceUpdate()
        })

        /* tippy(this.$el.querySelectorAll("[data-tippy-content]"), {
            appendTo: () => this.$el,
            arrow: false
        })*/
    },
    methods: {
        addCustomFont() {
            const val = `${parseFloat(this.customFontSize)}px`
            updatePersonalSettings("custom_font_sizes", [...(getPersonalSettings("custom_font_sizes") || []), val])
            this.sizes = fontSizes()

            this.isOpenCustomFontSize = false
            this.customFontSize = ""

            setTimeout(() => {
                this.$forceUpdate()
                this.setFontSize(val)
            }, 50)
        },
        getLineHeight() {
            if (!this?.font?.height || !this?.font?.size) {
                return "1.3"
            }

            const val = parseFloat(this.font.height) / parseFloat(this.font.size)

            if (Number.isNaN(val)) {
                return "1.3"
            }

            return val.toFixed(1)
        },
        setLineHeight(lineHeight) {
            this.editor.chain().focus().setLineHeight(lineHeight).run()
            this.$refs.dropdownHeight.hide()

            setTimeout(() => {
                this.font.height = parseFloat(this.font.size) * lineHeight
                this.$forceUpdate()
            }, 50)
        },
        setFontSize(fontSize) {
            this.editor.chain().focus().setFontSize(fontSize).run()
            setTimeout(() => {
                this.editor.commands.focus()
            })
            this.$refs.dropdownSize.hide()

            setTimeout(() => {
                this.font.size = fontSize
                this.$forceUpdate()
            }, 50)
        },
        setFontFamily(fontKey) {
            this.editor.chain().focus().setFontFamily(fontKey).run()
            this.$refs.dropdownFont.hide()

            setTimeout(() => {
                this.font.family = fontKey
                this.$forceUpdate()
            }, 50)
        },
        getCurrentFont() {
            const selection = window.getSelection()
            if (selection && selection.anchorNode) {
                let el = selection.anchorNode.parentElement

                if (el.closest(".editor-menu")) {
                    return
                }

                if (
                    el.classList &&
                    el.classList.contains("tiptap") &&
                    selection?.anchorNode?.firstChild &&
                    selection?.anchorNode?.firstChild instanceof HTMLElement
                ) {
                    el = selection.anchorNode.firstChild
                }

                const anchor = window.getComputedStyle(el, null)

                const family = anchor.getPropertyValue("font-family").replaceAll(`"`, `'`)
                const size = anchor.getPropertyValue("font-size").replaceAll(`"`, `'`)
                const height = anchor.getPropertyValue("line-height").replaceAll(`"`, `'`)

                return {
                    family,
                    size,
                    height
                }
            } else {
                return {
                    family: `lato, sans-serif`,
                    size: 13,
                    height: "1.3"
                }
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.custom-input-container
    height: 33px
    text-indent: 10px
    max-width: 120px
    width: 120px
    border: 1px solid #ECF1F5
    margin-right: 5px
    border-radius: 4px
.custom-font-container
    padding: 0
    margin-right: 5px
    width: calc(100% - 7px)
    margin-left: 2px
.editor-font-layout
    &::v-deep
        .default-dropdown__placeholder
            color: #3965FF !important
.font-overflow
    max-width: 160px
    overflow: hidden
    text-overflow: ellipsis
</style>
