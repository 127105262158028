<template>
    <div class="practice-quiz-preview">
        <div v-if="getUser() === null">
            <default-title inter :size="28" class="text-center">
                {{ t("create-course.access_denied") }}
            </default-title>
            <button @click="login" class="mx-auto block mt-35">
                <span class="course__content_actions__item course__content_actions__item_green">
                    {{ t("expert.usermenu.login") }}
                </span>
            </button>
        </div>
        <div v-else class="practice-quiz-preview-content">
            <flex-container align="center" id="practice-main">
                <PracticeTimerIcon class="mr-7" v-if="quiz.options.timer_duration || quizIncludesQuestionWithTimer()" />
                <practice-title>
                    {{ quiz.name }}
                </practice-title>
            </flex-container>

            <template v-if="!quiz.attempt">
                <practice-red-alert v-if="quiz.options.timer_duration || quizIncludesQuestionWithTimer()" class="mt-15">
                    <template v-if="!quizIncludesQuestionWithTimer() && quiz.options.timer_duration">
                        <strong>{{ t("course.practice.attension") }}</strong>
                        <span v-html="t('course.practice.quiz_4')"></span>
                        <br />
                        {{ t("course.quiz_alloted_time") }}:
                        <strong class="inline">
                            <PracticeTimer
                                track-by="duration_in_seconds"
                                :attempt="quiz.options.timer_duration"
                                only-display
                            />
                        </strong>
                    </template>
                    <template v-if="quizIncludesQuestionWithTimer() && !quiz.options.timer_duration">
                        <strong>{{ t("course.practice.attension") }}</strong>
                        {{ t("course.practice.quiz_5") }}
                    </template>
                    <template v-if="quizIncludesQuestionWithTimer() && quiz.options.timer_duration">
                        <strong>{{ t("course.practice.attension") }}</strong>
                        {{ t("course.practice.quiz_6") }}
                        <ul>
                            <li>
                                {{ t("course.practice.quiz_7") }}
                            </li>
                            <li>
                                {{ t("course.practice.quiz_8") }}
                            </li>
                        </ul>
                    </template>
                </practice-red-alert>

                <PracticeQuestionDescription class="mt-10" :description="startPage.text" />
            </template>

            <practice-blue-alert v-if="quiz.estimate.is_on && quiz.estimate.is_show && quiz.attempt" class="mt-15">
                <span class="text-black"> {{ t("course.detail.quiz_scores") }}</span
                >:
                <template v-if="quiz.common_scores_min">{{ quiz.common_scores_min }} -</template>
                {{ quiz.common_scores_max }}
            </practice-blue-alert>

            <practice-blue-alert
                :key="quiz.attempt.id"
                style="margin-top: 10px"
                v-if="quiz.versioning_type_id === 3 && quiz.attempts.length > 1"
            >
                <span class="text-black"> {{ t("course.detail.version_attempt") }}:</span>

                {{ format(quiz.attempt.finished_at) }}
                <DefaultDropdown
                    ref="dropdown"
                    v-if="quiz.attempts.length > 1"
                    :cases="getAttemptsCases()"
                    scroll-active
                    @update="setAttempt(quiz.attempts.find(a => $event.id === a.id))"
                    :active-case="{ id: quiz.attempt.id, name: quiz.attempt.finished_at }"
                    class="inline-block versions-dropdown"
                >
                    <template #handler>
                        <a
                            style="text-underline-offset: 3px"
                            class="ml-10 text-blue cursor-pointer underline"
                            @click="show()"
                            >+ {{ t("course.detail.once_versions").replaceAll("$", quiz.attempts.length - 1) }}
                        </a>
                    </template>
                </DefaultDropdown>
            </practice-blue-alert>

            <!-- QUIZ PREV COMMENTS -->
            <!-- <PracticeQuizPrevComments :quiz="quiz" v-if="quiz.versioning_type_id === 2" />-->

            <flex-container :direction="$screenWidth > 640 ? 'row' : 'column'" class="mt-10 gap-10">
                <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 6" class="yellow">
                    {{ t("course.practice.quiz_1") }}
                </practice-blue-alert>

                <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 5">
                    {{ t("course.detail.in_process") }}
                </practice-blue-alert>

                <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 4" class="yellow">
                    {{ t("course.detail.last_attempt_status_changed") }}
                </practice-blue-alert>

                <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 1" class="green">
                    {{ quiz.passed_text ? quiz.passed_text : t("course.practice.quiz_2") }}
                </practice-blue-alert>

                <practice-blue-alert v-if="quiz.attempt && quiz.attempt.status_id === 2" class="red">
                    {{ t("course.practice.quiz_3") }}
                    <div class="mt-5" v-if="quiz.estimate.is_on && quiz.estimate.is_show">
                        {{ t("course.practice.current_score") }} <strong>{{ quiz.attempt.scores }}</strong>
                    </div>
                </practice-blue-alert>

                <practice-blue-alert v-if="needRework" class="yellow">
                    {{ t("course.need_rework") }}
                </practice-blue-alert>

                <practice-blue-alert
                    v-if="quiz.attempt && quiz.attempt.status_id === 1 && quiz.estimate.is_on && quiz.estimate.is_show"
                >
                    <span class="text-black">{{ t("course.practice.points_received") }}</span>
                    {{ quiz.attempt.scores }}
                </practice-blue-alert>

                <practice-blue-alert v-if="quiz.attempt && quiz.attempt.passed_time_formatted">
                    <flex-container>
                        <PracticeStopwatchIcon class="mr-8" />

                        <span class="text-black">{{ t("create-course.passage_time") }}:</span>
                        &nbsp;{{ quiz.attempt.passed_time_formatted }}
                    </flex-container>
                </practice-blue-alert>
            </flex-container>

            <div v-if="needRework || [1, 2, 3, 4, 6].includes(quiz.attempt && quiz.attempt.status_id)" class="">
                <practice-comment
                    :quiz="quiz"
                    v-if="quiz.attempt_comments && quiz.attempt_comments.length"
                    :attempt="quiz.attempt"
                ></practice-comment>
            </div>

            <flex-container
                v-show="mount"
                :direction="$screenWidth > 640 ? 'row' : 'column'"
                :align="$screenWidth > 640 ? 'center' : 'flex-start'"
            >
                <button
                    v-if="showStartButton"
                    @click="startAttempt()"
                    :style="buttonStyle()"
                    :class="{ 'practice-quiz-preview__btn_load': loading, loading, 'custom-button': !!buttonStyle() }"
                    class="practice-quiz-preview__btn mt-10"
                >
                    <span>
                        <template v-if="quiz.attempt && quiz.attempt.status_id === 3">
                            {{ t("course.practice.finalize") }}
                        </template>
                        <template v-else-if="quiz.attempt && quiz.attempt.status_id !== 5">
                            {{ t("course.practice.replay") }}
                        </template>
                        <template v-else>
                            {{ buttonText() }}
                        </template>
                    </span>
                </button>
                <button
                    v-if="showResultButton"
                    @click="resultDisplayed = !resultDisplayed"
                    class="practice-quiz-preview__btn practice-quiz-preview__btn_result mt-10"
                    :class="{ 'practice-quiz-preview__btn_result_only': !showStartButton || $screenWidth <= 640 }"
                >
                    <span v-if="!resultDisplayed">
                        {{ t("course.practice.display_result") }}
                    </span>
                    <span v-else>
                        {{ t("course.practice.hide_result") }}
                    </span>
                </button>
            </flex-container>

            <red-alert style="max-width: 700px" v-if="showResultAlert" class="mt-15 mb-25">
                <strong>
                    {{ t("expert.attention") }}
                </strong>
                <div v-html="quiz.attempt_limit_text ? quiz.attempt_limit_text : t('course.practice.cant_retry')"></div>
            </red-alert>

            <AttemptResult
                class="mt-20"
                v-if="showResultButton && resultDisplayed && !didUpdate"
                :lesson="lesson"
                :quiz="quiz"
                :course-id="lesson.course_id"
                :attempt-id="quiz.attempt.id"
            />

            <!-- HOT FIX -->
            <PracticeOnlyTextGraphicAnswersBlock
                :unit-id="unitId"
                :lesson="lesson"
                :quiz="quiz"
                v-if="
                    quiz.attempt &&
                    !quiz.result_attempt.is_show &&
                    quiz.attempt.status_id !== 5 &&
                    quiz.questions &&
                    quiz.type !== 'text_graphic_answer' &&
                    quiz.questions.find(q => q.type.slug === 'text_graphic_answer')
                "
            />
        </div>
    </div>
</template>

<script>
import RedAlert from "@components/Alerts/RedAlert"
import { resolveMargin, resolvePadding } from "@components/Builder/widget/utils/style"
import FlexContainer from "@components/Containers/FlexContainer"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import AttemptResult from "@components/Practice/AttemptResult"
import PracticeBlueAlert from "@components/Practice/common/alerts/PracticeBlueAlert"
import PracticeRedAlert from "@components/Practice/common/alerts/PracticeRedAlert"
import PracticeStopwatchIcon from "@components/Practice/common/timer/PracticeStopwatchIcon.vue"
import PracticeTimer from "@components/Practice/common/timer/PracticeTimer.vue"
import PracticeTimerIcon from "@components/Practice/common/timer/PracticeTimerIcon"
import PracticeComment from "@components/Practice/common/typography/PracticeComment"
import PracticeTitle from "@components/Practice/common/typography/PracticeTitle"
import PracticeRepository from "@components/Practice/shared/repository/practice-repository"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"

import { DEFAULT_AUTH_KEY } from "@public-modal/shared/keys/default-auth-key"
import moment from "moment-timezone"
import { EventBus } from "~events"
import PracticeOnlyTextGraphicAnswersBlock from "./PracticeOnlyTextGraphicAnswersBlock"
import PracticeQuestionDescription from "@components/Practice/PracticeQuestions/components/PracticeQuestionDescription.vue"

export default {
    props: {
        unitId: {
            type: Number,
            default: 0
        },
        quiz: {
            type: Object,
            default: null
        },
        lesson: {
            type: Object,
            default: null
        }
    },
    name: "PracticeQuizPreview",
    components: {
        PracticeQuestionDescription,
        PracticeStopwatchIcon,
        DefaultDropdown,
        DefaultTitle,
        PracticeTimer,
        RedAlert,
        AttemptResult,
        PracticeOnlyTextGraphicAnswersBlock,
        PracticeComment,
        PracticeTitle,
        PracticeRedAlert,
        PracticeBlueAlert,
        PracticeTimerIcon,
        FlexContainer
    },
    data() {
        return {
            loading: false,
            resultDisplayed: false,
            mount: false,
            didUpdate: false,
            startPage: {
                text: "",
                button: {
                    hover: "#4261CF",
                    color: "#ffffff",
                    background: "#5E7BE0",
                    buttonText: "",
                    padding: [8, 20, 8, 20],
                    margin: [0, 0, 0, 0],
                    justifyContent: "flex-start"
                }
            }
        }
    },
    async created() {
        window.onbeforeunload = null

        if (!this.getUser()) {
            return
        }
        this.resultDisplayed = location.href.includes("quizzes")

        if (this.quiz.type === "text_graphic_answer") {
            return
        }

        this.quiz.can_start_attempt = false

        EventBus.$emit("practice:update-status", { id: this.quiz.id, status: this.quiz.status_id })

        const { data: quiz } = await PracticeRepository.quiz({
            quiz_id: this.quiz.id,
            lesson_id: this.lesson.id,
            unit_id: this.unitId
        })

        const { can_start_attempt, is_run_out_of_attempts, attempts, attempt_comments, attempt_comment_type } = quiz

        this.quiz.can_start_attempt = can_start_attempt
        this.quiz.attempts = attempts
        this.quiz.is_run_out_of_attempts = is_run_out_of_attempts
        this.quiz.attempt_comments = attempt_comments
        this.quiz.attempt_comment_type = attempt_comment_type

        if (this.quiz.start_info && this.quiz?.start_type?.id !== 1) {
            this.startPage.text = this.quiz.start_info.text
            this.startPage.button = this.quiz.start_info.button || {
                hover: "#4261CF",
                color: "#ffffff",
                background: "#5E7BE0",
                buttonText: this.t("partners.participate"),
                padding: [8, 20, 8, 20],
                margin: [0, 0, 0, 0],
                justifyContent: "flex-start"
            }
        }

        const url = new URL(location)
        const attemptId = url.searchParams.get("quiz_attempt_id")

        if (attemptId) {
            const attempt = quiz.attempts.find(attempt => attempt.id == attemptId)

            if (attempt) {
                this.setAttempt(attempt)
                this.resultDisplayed = true

                url.searchParams.delete("quiz_attempt_id")

                window.history.replaceState({}, "", url)
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.mount = true
        }, 100)
    },
    methods: {
        show() {
            this.$refs.dropdown.show()
        },
        setAttempt($event) {
            this.$set(this.quiz, "attempt", {
                ...$event,
                results: null
            })
            this.forceUpdate()
        },
        forceUpdate() {
            this.didUpdate = true
            this.$nextTick(() => {
                this.didUpdate = false
            })
        },
        format(date) {
            return moment(date).format("DD MMMM, YYYY HH:mm")
        },
        getAttemptsCases() {
            return [...this.quiz.attempts].map(a => ({
                id: a.id,
                name: a.finished_at ? moment(a.finished_at).format("DD MMMM, YYYY HH:mm") : "-"
            }))
        },
        login() {
            EventBus.$emit(`${DEFAULT_AUTH_KEY}_OPEN`)
        },
        getUser() {
            return window.user
        },
        quizIncludesQuestionWithTimer() {
            if (!this.quiz) {
                return false
            }
            return !!this.quiz.questions.find(q => q.timer)
        },
        buttonText() {
            if (this.quiz?.start_type?.id === 1) {
                return this.t("course.practice.start")
            }
            return (this.startPage.button && this.startPage.button.buttonText) || this.t("course.practice.start")
        },
        buttonStyle() {
            if (this.quiz.attempt || this.quiz?.start_type?.id === 1) {
                return
            }

            const { background, color, hover, padding, margin, justifyContent } = this.startPage.button

            if (justifyContent !== "flex-start") {
                if (justifyContent === "center") {
                    margin[1] = margin[3] = "auto"
                } else if (justifyContent === "flex-end") {
                    margin[3] = "auto"
                }
            }

            return {
                background,
                color,
                "--color-hover": hover,
                ...resolvePadding(padding),
                ...resolveMargin(margin)
            }
        },
        async startAttempt() {
            this.loading = true
            try {
                const payload = {
                    lesson_id: this.lesson.id,
                    quiz_id: this.quiz.id,
                    unit_id: this.unitId
                }
                const { data: attempt } = await PracticeRepository.attempt(payload)
                if (attempt) {
                    const { data: questions } = await PracticeRepository.questions({
                        ...payload,
                        attempt_id: attempt.id
                    })

                    this.$set(this.quiz, "questions", questions)
                    this.$set(this.quiz, "attempt", attempt)

                    // Set finalize key to true in quiz entity and show answer block
                    if (this.quiz.attempt && this.quiz.attempt.status_id === 3 && !this.quiz.finalize) {
                        this.$set(this.quiz, "finalize", true)
                    }

                    EventBus.$emit("practice:update-status", { id: this.quiz.id, status: this.quiz.status_id })

                    this.$emit("rerender")
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    computed: {
        showResultButton() {
            return (
                this.quiz.attempt &&
                this.quiz.attempt.status_id !== 5 &&
                this.quiz.type !== "text_graphic_answer" &&
                this.quiz.result_attempt.is_show
            )
        },
        showResultAlert() {
            if (!this.quiz) {
                return false
            }
            return this.quiz.is_run_out_of_attempts && !this.loading
        },
        showStartButton() {
            if (this.quiz.can_start_attempt) {
                if (this.quiz.type !== "text_graphic_answer") {
                    return true
                } else {
                    if (this.quizIncludesQuestionWithTimer() || this.quiz.options.timer_duration) {
                        return !this.quiz.attempt
                    }
                }
            }
            return false
        },
        needRework() {
            let attempt = this.quiz.attempt
            let previousAttempt = this.quiz.attempt?.previous_attempt
            return (
                (attempt && attempt.status_id === 3) ||
                (attempt && attempt.status_id === null && previousAttempt && previousAttempt.status_id === 3)
            )
        }
    }
}
</script>

<style scoped lang="sass">

.versions-dropdown
    &::v-deep
        .default-dropdown__modal
            top: 30px
            min-width: 190px
.practice-quiz-preview
    .pracricle-timer-icon
        margin-right: 8px
    &__btn
        height: 45px
        display: flex
        align-items: center
        padding: 10px 20px
        min-width: 170px
        justify-content: center
        background: #30C58F
        border-radius: 7px
        font-size: 15px
        color: #fff
        line-height: 22px
        font-weight: 500
        transition: .2s
        font-family: 'Inter', sans-serif

        &:hover
            background: #28BA85
        &_load
            opacity: .8
            span
                visibility: hidden
            pointer-events: none
            &::after
                width: 26px
                height: 26px
                opacity: 1
                margin-top: -13px
                margin-left: -13px
                background: url("@images/portal/loader-icon.svg")
        &_result
            background: transparent
            color: #3965FF
            text-decoration: underline
            text-underline-offset: 2px
            &_only
                width: max-content
                padding: 0 10px
            &:hover
                background: transparent
        &.custom-button
            height: initial
            min-width: 100px
            min-height: 20px
            &:hover
                background: var(--color-hover) !important
</style>
