import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import FileTemplate from "./FileTemplate.vue"
import { bytesToBase64 } from "@helpers"

export default Node.create({
    name: "FileTemplate",

    group: "block",

    atom: true,

    addAttributes() {
        return {
            files: {
                default: ""
            },
            fileIds: {
                default: ""
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: "file-component"
            }
        ]
    },

    renderHTML(value) {
        const { HTMLAttributes } = value
        return ["file-component", mergeAttributes(HTMLAttributes)]
    },

    addCommands() {
        return {
            setFile:
                ({ files, fileIds }) =>
                ({ chain }) => {
                    const result = bytesToBase64(new TextEncoder().encode(JSON.stringify(files)))
                    const ids = JSON.stringify(fileIds)

                    return chain()
                        .insertContent(`<file-component fileIds="${ids}" files="${result}"></file-component>`)
                        .run()
                }
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(FileTemplate)
    }
})
