
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 9.5C17 9.04037 16.9095 8.58525 16.7336 8.16061C16.5577 7.73597 16.2999 7.35013 15.9749 7.02513C15.6499 6.70012 15.264 6.44231 14.8394 6.26642C14.4148 6.09053 13.9596 6 13.5 6H9C8.73478 6 8.48043 6.10536 8.29289 6.29289C8.10536 6.48043 8 6.73478 8 7V17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H13.5C14.1967 18.003 14.8783 17.7975 15.4572 17.4098C16.036 17.0221 16.4856 16.4701 16.7481 15.8247C17.0105 15.1794 17.0738 14.4703 16.9298 13.7886C16.7858 13.107 16.4411 12.4841 15.94 12C16.2743 11.6749 16.5403 11.2863 16.7223 10.857C16.9044 10.4277 16.9988 9.96631 17 9.5ZM13.5 16H10V13H13.5C13.8978 13 14.2794 13.158 14.5607 13.4393C14.842 13.7206 15 14.1022 15 14.5C15 14.8978 14.842 15.2794 14.5607 15.5607C14.2794 15.842 13.8978 16 13.5 16ZM13.5 11H10V8H13.5C13.8978 8 14.2794 8.15804 14.5607 8.43934C14.842 8.72064 15 9.10218 15 9.5C15 9.89782 14.842 10.2794 14.5607 10.5607C14.2794 10.842 13.8978 11 13.5 11Z"
        />
    </svg>
</template>
<script>
export default {
    name: "BoldIcon"
}
</script>
