<template>
    <div v-click-outside="hide" class="simple-color-input">
        <default-title :class="`mr-${mr}`" small coal v-if="label"> {{ label }}: </default-title>
        <button
            v-if="!flat"
            ref="button"
            @mousedown.stop.prevent="toggle"
            :style="{ backgroundColor: modelValue }"
            class="input-circle cursor-pointer button-scale"
            :class="{ white: isWhite(modelValue) }"
        ></button>
        <transition name="fade-up">
            <div :style="modalStyle" v-if="isOpen || flat" class="simple-color-modal">
                <div class="input-circle-grid m-10">
                    <button
                        :title="color"
                        :key="index"
                        v-for="(color, index) in popular"
                        @mousedown.stop.prevent="setColor(color)"
                        :style="{ backgroundColor: color, outlineColor: color }"
                        :class="{ white: isWhite(color), active: isActive(color) }"
                        class="input-circle button-scale"
                    ></button>
                </div>

                <template v-if="usedColors && usedColors.length">
                    <span class="simple-color-label mx-10 mt-15 block"> {{ t("expert.own_colors") }}: </span>
                    <div class="input-circle-grid mx-10 mb-15 mt-8">
                        <div :key="index" v-for="(color, index) in usedColors" class="relative input-circle-wrapper">
                            <button
                                @mousedown.stop.prevent.stop="removeUsedColor(index)"
                                class="w-12 remove-color h-12 flex items-center justify-center absolute"
                            >
                                <close-icon bold />
                            </button>
                            <button
                                :title="color"
                                @mousedown.stop.prevent="setColor(color)"
                                :style="{ backgroundColor: color, outlineColor: color }"
                                :class="{ white: isWhite(color), active: isActive(color) }"
                                class="input-circle button-scale"
                            ></button>
                        </div>
                    </div>
                </template>

                <div v-if="!showCustomColor" class="simple-color-custom p-10">
                    <button @mousedown.stop.prevent.stop="showCustomColorMode()" class="simple-color-add">
                        + <span> {{ t("expert.add_custom_color") }}</span>
                    </button>
                </div>

                <div v-else class="p-10 simple-color-custom">
                    <div class="flex items-center">
                        <span>{{ t("expert.own_color") }}:</span>
                        <label
                            v-if="!isIOS()"
                            ref="label"
                            :class="{ white: isWhite(customColor), active: isActive(customColor) }"
                            :style="{ backgroundColor: customColor, outlineColor: customColor }"
                            class="ml-10 input-circle button-scale type-color-wrapper cursor-pointer"
                        >
                            <input
                                @input="handleInput"
                                ref="input"
                                @change="customColor = $event.target.value"
                                type="color"
                            />
                        </label>
                        <input
                            class="ml-10"
                            v-else
                            @input="handleInput"
                            ref="input"
                            @change="customColor = $event.target.value"
                            type="color"
                        />
                    </div>

                    <button @mousedown.stop.prevent="addCustomColor" class="add-button button-scale">
                        <PlusIcon width="24" height="24" fill="#fff" />
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import IsOpen from "@mixins/is-open"
import PopularColors from "@components/Builder/components/ColorInput/popular-colors"
import PlusIcon from "@icons/PlusIcon.vue"
import { EventBus } from "~events"
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import CloseIcon from "@icons/CloseIcon.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import checkDevice from "@mixins/checkDevice"

export default {
    components: { DefaultTitle, CloseIcon, PlusIcon },
    props: {
        fixed: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            default: ""
        },
        flat: {
            type: Boolean,
            default: false
        },
        mr: {
            type: String,
            default: "14"
        },
        label: {
            type: String,
            default: ""
        },
        usedKey: {
            type: String,
            default: "used_colors"
        },
        emitOnChange: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: "modelValue",
        event: "update:modelValue"
    },
    name: "BuilderSimpleColor",
    mixins: [IsOpen, checkDevice],
    data() {
        return {
            popular: PopularColors,
            showCustomColor: false,
            customColor: "#000",
            usedColors: []
        }
    },
    created() {
        this.usedColors = this.getUsedColors()

        EventBus.$on(`update-settings:${this.usedKey}`, () => {
            this.usedColors = this.getUsedColors()
        })
    },
    methods: {
        handleInput(event) {
            if (this.emitOnChange) {
                this.$emit("update:modelValue", event.target.value)
            }

            this.customColor = event.target.value
        },
        getUsedColors() {
            return getPersonalSettings(this.usedKey) ?? []
        },
        addCustomColor() {
            this.setColor(this.customColor)

            if (window.user) {
                const colors = [this.customColor, ...this.getUsedColors()]
                updatePersonalSettings([{ key: this.usedKey, value: colors }])
            }

            this.showCustomColor = false
            this.customColor = "#000"
        },
        isWhite(color) {
            return [
                "white",
                "#ffffff",
                "#fff",
                "#FFF",
                "#FFFFFF",
                "#00000000",
                "transparent",
                "rgba(255,255,255,0)"
            ].includes(color)
        },
        isActive(color) {
            return color === this.modelValue
        },
        onHide() {
            this.showCustomColor = false
            this.customColor = "#000"
        },
        showCustomColorMode() {
            this.showCustomColor = true
            this.$nextTick(() => {
                this.$refs.label.click()
            })
        },
        removeUsedColor(index) {
            const colors = this.getUsedColors()

            colors.splice(index, 1)

            updatePersonalSettings([{ key: this.usedKey, value: colors }])
        },
        setColor(color) {
            this.$emit("set:color")
            this.$emit("update:modelValue", color)
        }
    },
    computed: {
        modalStyle() {
            const style = {}

            if (this.fixed && this.$refs.button) {
                const { top, left } = this.$refs.button.getBoundingClientRect()
                style.position = `fixed`
                //style.transform = "translateY(-100%)"
                style.top = `${top}px !important`
                style.left = `${left + 30}px !important`
                style.transform = `translateX(-100%) !important`
                style.bottom = "auto"
                style.zIndex = "99999"
            }
            return style
        }
    }
}
</script>
<style lang="scss" scoped>
.remove-color {
    background-color: #fff;
    right: -2px;
    top: -2px;
    z-index: 2;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25);
    svg {
        height: 8px;
        width: 8px;
    }
    display: none;
}

.input-circle-wrapper {
    &:hover {
        .remove-color {
            display: flex;
        }
    }
}
.type-color-wrapper {
    input[type="color"] {
        visibility: hidden;
    }
}

.add-button {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: #396fff;
    transition: 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #3566ec;
    }
}
.simple-color-custom {
    background-color: #fbfbfb;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.simple-color-add {
    font-size: 14px;
    font-weight: 500;
    color: #396fff;
    span {
        text-underline-offset: 5px;
    }
    &:hover {
        span {
            text-decoration: underline;
        }
    }
}
.input-circle-grid {
    display: grid;
    grid-gap: 7px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.simple-color-input {
    height: 30px;
    width: max-content;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #323030;
}
.simple-color-modal {
    border-radius: 10px;
    position: absolute;
    box-shadow: 0 4px 10px rgba(106, 110, 119, 0.35);
    z-index: 10;
    top: 0;
    background-color: #fff;
}
.input-circle {
    width: 28px;
    border-radius: 50%;
    height: 28px;
    border: 1px solid transparent;
    cursor: pointer;
    outline-offset: 2px;
    outline: transparent none 2px;
    transition: outline 0.1s !important;
    position: relative;
    &::before {
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: -1;
        border-radius: 50%;
        content: "";
        position: absolute;
        background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%),
            linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%);
        background-size: 8px 8px;
        background-position: 0 0, 4px 4px;
    }
    &.active {
        outline-style: dashed;
    }
    &.white {
        border: 1px solid #d8d8d8;
        outline-color: #d3d3d3 !important;
    }
    &.cursor-pointer {
        cursor: pointer;
    }
    &:active {
        &::before {
            content: none;
        }
    }
}
</style>
