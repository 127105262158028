import { Extension } from "@tiptap/core"

export const LineHeight = Extension.create({
    name: "lineHeight",

    addOptions() {
        return {
            types: ["heading", "paragraph"],

            defaultHeight: 1.5,
            sizes: new Array(13).fill(7).map((number, index) => `${(number + index + 1) / 10}`)
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        default: this.options.defaultHeight,
                        parseHTML: element => element.style.lineHeight || this.options.defaultHeight,
                        renderHTML: attributes => {
                            if (attributes.lineHeight === this.options.defaultHeight) {
                                return {}
                            }

                            return { style: `line-height: ${attributes.lineHeight}` }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setLineHeight:
                height =>
                ({ commands }) => {
                    if (!this.options.sizes.includes(height)) {
                        return false
                    }

                    return this.options.types.every(type => commands.updateAttributes(type, { lineHeight: height }))
                }
        }
    }
})
