import { Extension } from "@tiptap/core"

/*<var>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</var>*/

export const CustomTabExtension = Extension.create({
    name: "literalTab",

    addKeyboardShortcuts() {
        return {
            Tab: ({ editor }) => {
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        "<span style='background-color: transparent'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>"
                    )
                    .run()
                return true
            }
        }
    }
})
