<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.33333 2.5H11.6667M10 8.33333V11.25L11.6667 12.9167M16.25 11.25C16.25 14.7018 13.4518 17.5 10 17.5C6.54822 17.5 3.75 14.7018 3.75 11.25C3.75 7.79822 6.54822 5 10 5C13.4518 5 16.25 7.79822 16.25 11.25Z"
            stroke="#3965FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "PracticeStopwatchIcon"
}
</script>
