
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.14167 8.79167C9.14167 8.311 9.396 7.81983 9.92917 7.42083C10.4635 7.0195 11.2382 6.75 12.1167 6.75C13.4187 6.75 14.492 7.36017 14.8747 7.9715C14.9977 8.16829 15.1938 8.30816 15.4199 8.36034C15.646 8.41253 15.8836 8.37274 16.0804 8.24975C16.2772 8.12676 16.4171 7.93062 16.4693 7.7045C16.5214 7.47838 16.4817 7.24079 16.3587 7.044C15.5735 5.78983 13.848 5 12.1167 5C10.8952 5 9.74367 5.37217 8.87917 6.02083C8.01233 6.67067 7.39167 7.639 7.39167 8.79167C7.39167 9.41233 7.5725 9.984 7.88517 10.4833H10.4717C9.56867 10.0587 9.14167 9.37383 9.14167 8.79167ZM5 12.8167C5 12.5846 5.09219 12.362 5.25628 12.1979C5.42038 12.0339 5.64294 11.9417 5.875 11.9417H18.125C18.3571 11.9417 18.5796 12.0339 18.7437 12.1979C18.9078 12.362 19 12.5846 19 12.8167C19 13.0487 18.9078 13.2713 18.7437 13.4354C18.5796 13.5995 18.3571 13.6917 18.125 13.6917H16.2175C16.466 14.1478 16.6083 14.6577 16.6083 15.2083C16.6083 16.361 15.9877 17.3282 15.1208 17.9792C14.2552 18.6278 13.1048 19 11.8833 19C10.1462 19 8.3985 18.2032 7.618 16.7997C7.55893 16.6991 7.52057 16.5876 7.50519 16.472C7.48981 16.3563 7.49772 16.2388 7.52845 16.1262C7.55917 16.0137 7.6121 15.9084 7.68411 15.8166C7.75612 15.7248 7.84577 15.6483 7.94777 15.5917C8.04977 15.535 8.16207 15.4994 8.27806 15.4868C8.39405 15.4742 8.51139 15.4849 8.62318 15.5183C8.73496 15.5517 8.83894 15.6072 8.92898 15.6814C9.01902 15.7555 9.09332 15.847 9.1475 15.9503C9.53483 16.6457 10.5872 17.25 11.8833 17.25C12.7618 17.25 13.5353 16.9805 14.0708 16.5792C14.604 16.179 14.8583 15.689 14.8583 15.2083C14.8583 14.7008 14.5328 14.1152 13.8515 13.6917H5.875C5.76009 13.6917 5.64631 13.669 5.54015 13.6251C5.43399 13.5811 5.33753 13.5166 5.25628 13.4354C5.17503 13.3541 5.11058 13.2577 5.06661 13.1515C5.02263 13.0454 5 12.9316 5 12.8167Z"
        />
    </svg>
</template>
<script>
export default {
    name: "StrikethroughIcon"
}
</script>
