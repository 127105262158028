<template>
    <div
        :key="keyUpdate"
        class="practice-comments"
        :class="{ 'practice-comments_hidden': !displayComments }"
        v-if="comments.length"
    >
        <button class="practice-comments-btn" @click="toggleComments">
            <flex-container>
                <default-title :font-weight="400" large>
                    {{ t("course.course_card.comments") }} ({{ comments.length }})
                </default-title>
                <ArrowDownIcon :class="{ 'rotate-180': displayComments }" class="ml-5 mt-3" />
            </flex-container>
        </button>
        <div class="practice-comments-container" v-if="displayComments">
            <div class="mt-10"></div>
            <template v-if="displayComments">
                <div ref="wrapper" class="comments-wrapper custom-scroll -mr-5">
                    <div
                        class="practice-comment"
                        :class="{ active: id && comment.id === id }"
                        :ref="'comment_' + comment.id"
                        :key="comment.id"
                        v-for="comment in comments"
                    >
                        <flex-container class="practice-comment-head" justify="space-between" align="center">
                            <FlatUser :avatar-size="24" :user="comment.user" />
                            <div class="comment-date">
                                <flex-container justify="flex-end" align="center">
                                    <div v-if="comment.commentable.finished_at" class="mb-1">
                                        {{ t("quiz.version_attempt_at") }}
                                        <em> {{ comment.commentable.finished_at | formatDateWithTimeNoSeconds2 }}</em>
                                    </div>

                                    <flex-container align="center" v-if="!isPublic" class="ml-10">
                                        <template v-if="userId == comment.user_id">
                                            <button
                                                class="h-20 w-20 mr-8"
                                                v-if="!(id && comment.id === id)"
                                                @click="editComment(comment)"
                                            >
                                                <EditIcon height="18" width="18" hoverable />
                                            </button>

                                            <CloseButtonSm class="mr-8" @close="clear" v-else />
                                        </template>

                                        <button @click="deletableComment = comment" class="h-20 w-20">
                                            <DeleteIcon class="hovered-red" height="18" width="18" hoverable />
                                        </button>
                                    </flex-container>
                                </flex-container>

                                <span class="mt-5 block">
                                    <span class="show-tooltip-on-hover">
                                        <TooltipOnHover :to-top="-20">
                                            {{ comment.created_at | formatDateWithTimeNoSeconds2 }}
                                        </TooltipOnHover>
                                        {{ comment.created_at_human }}
                                    </span>
                                    <template v-if="comment.updated_at !== comment.created_at"
                                        >({{ t("quiz.edited_comment") }}
                                        {{ comment.updated_at | formatDateWithTimeNoSeconds2 }})</template
                                    >
                                </span>
                            </div>
                        </flex-container>

                        <div class="text-view" v-html="comment.text_formatted.clean"></div>

                        <FilesNewView
                            class="mt-15"
                            v-if="comment.files && comment.files.length"
                            :files="comment.files"
                        />
                    </div>
                </div>
            </template>

            <template
                v-if="attempt && quiz.attempt_comment_type && quiz.attempt_comment_type.id === 1 && isDisplayComments"
            >
                <Editor
                    hide-floating-menu
                    class="mt-15 bg-white"
                    v-model="text"
                    :key="editorUpdate"
                    :class="{ 'pointer-events-none': loading }"
                    :placeholder="t('quiz.answer')"
                />
                <FilesNewView
                    @openRemoveModal="removeFile($event)"
                    parent="creator"
                    update-preview
                    list-file
                    class="mt-10 relative z-10"
                    v-if="files && files.length"
                    :files="files"
                />

                <flex-container v-if="isDisplayComments" class="mt-15">
                    <UploadFile buttonView class="max-w-[200px]" @save="files.push($event)" />
                    <button
                        @click="handleClick"
                        v-if="isDisplayComments"
                        class="comment-answer-btn"
                        :class="{
                            disabled: (!text && !files.length) || fileLoading,
                            'pointer-events-none': loading
                        }"
                    >
                        <SpinLoader :width="24" color="#fff" v-if="loading" />
                        <template v-else>
                            {{ t(id ? "contacts.change" : "common.answer") }}
                        </template>
                    </button>
                </flex-container>
            </template>
        </div>
        <modal-container v-if="deletableComment" flat full-width>
            <DeleteModal
                :loading="deleteLoader"
                @cancel="deletableComment = null"
                @delete="deleteComment"
                :title="t('quiz.delete_comment')"
            />
        </modal-container>
    </div>
</template>

<script>
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import CloseButtonSm from "@components/Buttons/CloseButtonSm.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import Editor from "@components/Editor/Editor.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import UploadFile from "@components/Recording/UploadFile.vue"
import TooltipOnHover from "@components/Tooltips/TooltipOnHover.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import FlatUser from "@components/User/FlatUser.vue"
import DeleteModal from "@expert-components/Modals/DeleteModal.vue"
import ModalContainer from "@expert-components/Modals/ModalContainer.vue"
import { handleError } from "@helpers"
import ArrowDownIcon from "@icons/ArrowDownIcon.vue"
import DeleteIcon from "@icons/DeleteIcon.vue"
import EditIcon from "@icons/EditIcon.vue"
import cloneDeep from "lodash.clonedeep"
import moment from "moment"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"
import "moment/dist/locale/fr"
import "moment/dist/locale/lv"
import "moment/dist/locale/pt"
import "moment/dist/locale/cs"
import "moment/dist/locale/ka"
import "moment/dist/locale/el"
import "moment/dist/locale/ro"
import "moment/dist/locale/zh-cn"

import FilesNewView from "@components/Files/FilesNewView.vue"
import axios from "~axios"
import { EventBus } from "~events"

moment.locale(window.app_current_locale)

export default {
    components: {
        Editor,
        TooltipOnHover,
        DeleteModal,
        ModalContainer,
        CloseButtonSm,
        UploadFile,
        DeleteIcon,
        EditIcon,
        ArrowDownIcon,
        DefaultTitle,
        FlatUser,
        FlexContainer,
        SpinLoader,
        FilesNewView
    },
    props: {
        quiz: {
            type: Object,
            default: () => {}
        },
        attempt: {
            type: Object,
            default: () => {}
        },
        isPublic: {
            default: true
        },
        isDisplayComments: {
            default: true
        }
    },
    filters: {
        formatDateWithTimeNoSeconds2(value) {
            return moment(value).format("DD.MM.YY, HH:mm")
        }
    },
    name: "PracticeComment",

    data() {
        return {
            text: "",
            id: null,
            deletableComment: null,
            files: [],
            loading: false,
            deleteLoader: false,
            displayComments: this.isDisplayComments,
            keyUpdate: 0,
            editorUpdate: 0,
            fileLoading: false
        }
    },
    mounted() {
        EventBus.$on("fileLoading", val => {
            this.fileLoading = val
        })

        if (this.isDisplayComments) {
            this.displayComments = getPersonalSettings("display_quiz_comments")
        }

        let uri = window.location.search
        let params = new URLSearchParams(uri)

        if (
            params.get("commentable_type") === "quiz-attempt" &&
            this.attempt &&
            params.get("commentable_id") == this.attempt.id
        ) {
            if (this.$route) {
                let query = Object.assign({}, this.$route.query)
                delete query.commentable_type
                delete query.commentable_id

                this.$router.replace({ query })
            } else {
                const url = new URL(window.location)

                url.searchParams.delete("commentable_type")
                url.searchParams.delete("commentable_id")
                window.history.replaceState({}, "", url)
            }

            if (!this.displayComments) {
                this.toggleComments()
            }

            this.$nextTick(() => {
                let params = new URLSearchParams(window.location.search)

                let commentId = params.get("comment_id")

                if (commentId && this.$refs[`comment_${commentId}`].length) {
                    this.$refs[`comment_${commentId}`][0].scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                    })

                    if (this.$route) {
                        let query = Object.assign({}, this.$route.query)
                        delete query.comment_id

                        this.$router.replace({ query })
                    } else {
                        const url = new URL(window.location)

                        url.searchParams.delete("comment_id")

                        window.history.replaceState({}, "", url)
                    }
                }
            })
        } else {
            if (this.$refs.wrapper) {
                this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
            }
        }
    },
    methods: {
        clear() {
            this.id = null
            this.text = ""
            this.files = []
            this.editorUpdate++
        },
        editComment(comment) {
            const { text_formatted, files, id } = comment

            this.text = text_formatted?.clean
            this.files = cloneDeep(files)
            this.id = id
            this.editorUpdate++
        },
        removeFile(file) {
            const index = this.files.indexOf(file)
            this.files.splice(index, 1)
        },
        toggleComments() {
            this.displayComments = !this.displayComments
            updatePersonalSettings("display_quiz_comments", this.displayComments)
        },
        handleClick() {
            this.answerComment()
        },
        async deleteComment() {
            this.deleteLoader = true

            const id = this.deletableComment.id

            try {
                await axios.delete(`comments/${id}`)
                this.quiz = {
                    ...this.quiz,
                    attempt_comments: this.quiz.attempt_comments.filter(c => c.id !== id)
                }
            } catch (e) {
                handleError(e)
            } finally {
                this.$forceUpdate()
                this.deletableComment = null
                this.keyUpdate++
                this.deleteLoader = false
            }
        },
        async answerComment() {
            this.loading = true

            try {
                const payload = {
                    commentable_type: "quiz-attempt",
                    commentable_id: this?.attempt?.id,
                    text: this.text,
                    files: this.files.map && this.files.map(f => f.id)
                }

                let url = this.isPublic ? `/public/comments` : "/comments"

                if (this.id) {
                    url = `/comments/${this.id}`
                }

                const method = this.id ? "put" : "post"

                const result = await axios[method](url, payload)

                if (!this.id) {
                    this.quiz.attempt_comments.push(result.data.data)
                } else {
                    const item = this.quiz.attempt_comments.find(c => c.id === this.id)

                    if (item) {
                        const index = this.quiz.attempt_comments.indexOf(item)
                        this.quiz.attempt_comments[index] = result.data.data
                    }
                }

                this.clear()

                this.$nextTick(() => {
                    if (this.$refs.wrapper) {
                        this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
                    }
                })
            } catch (e) {
                handleError(e)
            } finally {
                this.loading = false
            }
        }
    },
    computed: {
        userId() {
            return window.user_id
        },
        comments() {
            if (this.quiz.comment_versioning_type && this.quiz.comment_versioning_type.slug === "Disabled") {
                return this.quiz.attempt_comments.filter(comment => comment.commentable.id === this.attempt.id)
            }

            return this.quiz.attempt_comments
        }
    }
}
</script>
<style lang="sass" scoped>
.flat-user
    &::v-deep
        .default-title
            color: #323030 !important
            font-size: 16px !important
            font-weight: 500 !important
.practice-comment-head
    grid-gap: 10px
    @media (max-width: 640px)
        flex-direction: column !important
        align-items: flex-start !important
        .comment-date
            text-align: left
.practice-comments-btn
    margin: 0
    transition: .2s
    display: block
    padding: 8px 15px
    border-radius: 7px
    &:hover
        background-color: #ececec

.comment-date
    font-size: 12px
    text-align: right
    font-weight: 400
    color: #98A2B0
    em
        font-style: normal
        font-weight: 400
        color: #52565C
.comment-answer-btn
    padding: 10px 20px
    border-radius: 7px
    font-size: 15px
    line-height: 160%
    background-color: #5E7BE0
    color: #fff
    margin-left: auto
    display: block
    &:hover, &:active
        background-color: #4261CF
.practice-comment
    font-size: 13px !important
    font-family: Inter, sans-serif
    line-height: 15px
    margin-bottom: 10px
    margin-right: 5px
    background-color: #fff
    border-radius: 10px
    padding: 20px
    border: 1px solid #ECF1F5
    word-break: break-word

    &.active
        background-color: #ecf3ff

    .text-view
        margin-top: 10px
        color: #323030 !important
        font-weight: 400 !important
        font-size: 14px !important
    b
        font-weight: 500
    i
        color: #52565C
    &:last-child
        margin-bottom: 0
.practice-comments
    background-color: #FAFAFA
    border-radius: 10px
    margin-top: 15px
    padding: 10px 14px
    color: #151515
    transition: .2s
    @media (max-width: 640px)
        padding: 10px 5px
    .practice-comments-container
        max-width: 700px
    &_hidden
        width: max-content
        padding: 0
        .practice-comments-btn
            margin: 0
            transition: .2s

.comments-wrapper
    max-height: 500px
    overflow-y: auto
</style>
