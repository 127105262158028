import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import HtmlTemplate from "./HtmlTemplate.vue"

export default Node.create({
    name: "HtmlTemplate",

    group: "block",

    atom: true,

    addAttributes() {
        return {
            open: {
                default: "true"
            },
            value: {
                default: ""
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: "html-component"
            }
        ]
    },

    renderHTML(value) {
        const { HTMLAttributes } = value
        return ["html-component", mergeAttributes(HTMLAttributes)]
    },

    addCommands() {
        return {
            setCustomHtml:
                () =>
                ({ chain }) => {
                    return chain()
                        .insertContent(
                            `<html-component open="true" value="<h3 style='font-family: Ubuntu'>
    Test HTML markup
</h3>"></html-component>`
                        )
                        .run()
                }
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(HtmlTemplate)
    }
})
