<template>
    <div class="course-access-types">
        <!-- Message -->
        <div class="message relative">
            <transition name="fade">
                <div v-if="!langsKeys" class="message__loader flex-center absolute">
                    <LoaderBlock green-color />
                </div>
            </transition>

            <p class="attention text-red">{{ t("course.access_types_modal.attention") }}!</p>
            <p>
                <span class="no-accent mr-5">{{ t(`course.access_types_modal.${productType}_title`) }}:</span>
                <span class="accent mr-5">{{ t(`course.access_types_modal.${productType}_access`) }}:</span>
                <span class="message__button pointer" @click="handleModal">{{ buttonText }}</span>
            </p>
        </div>
        <!-- List modal -->
        <div
            v-if="isOpen"
            class="types-modal absolute z10"
            :class="{ 'pb-20': showSelection }"
            v-click-outside="handleModal"
        >
            <!-- Options list -->
            <ul class="types-modal__list">
                <li
                    v-for="(option, index) in options"
                    :key="`${index}-${option.id}`"
                    class="types-modal__list-item pointer"
                    :class="{
                        selected: selectedOpt && option.id === selectedOpt.id,
                        'mb-5': option.description,
                        'mb-2': index < options.length
                    }"
                    @click="selectType(option)"
                >
                    <p class="title no-accent" :class="{ 'mb-5': option.description }">{{ option.name }}</p>
                    <p class="description light-accent" v-if="option.description">{{ option.description }}</p>
                </li>
            </ul>
            <!-- Items selection block -->
            <transition name="fade">
                <div v-if="showSelection" class="items-selection mt-20" :class="{ 'block-loader': loading }">
                    <!-- Header -->
                    <flex-container align="center" class="mb-10">
                        <floating-button
                            style="min-width: 45px"
                            :radius="7"
                            :size="45"
                            need-box-shadow
                            needs-border-hover
                            @click="
                                resetAllSelectionItemsData()
                                updateData()
                            "
                        >
                            <UpdateIcon />
                        </floating-button>
                        <SearchInput
                            v-model="searchQuery"
                            :error-text="searchQueryErrorText"
                            :placeholder="t('expert.search')"
                            :min-search-length="minSearchLength"
                            class="ml-25"
                            @searchQuery:clear="
                                resetAllSelectionItemsData()
                                updateData()
                            "
                            @data:update="updateData"
                        />
                    </flex-container>
                    <!-- Content -->
                    <div class="items-selection__content mb-20 relative">
                        <!-- Loader -->
                        <transition name="fade">
                            <CreationBlockLoader v-if="loading" />
                        </transition>
                        <!-- Items list -->
                        <vue-simplebar
                            id="ItemsList"
                            ref="ItemsList"
                            v-if="items.length"
                            :key="`length-${items.length}`"
                            class="items-selection__list"
                        >
                            <div
                                v-for="(item, i) in items"
                                :key="`${i}-${item.id}`"
                                class="items-selection__list-item pointer"
                                :class="{ checked: selectedItem && item.id === selectedItem.id }"
                                @click="selectItem(item)"
                            >
                                <single-switcher class="mr-10" :active="selectedItem && item.id === selectedItem.id">
                                    <marketing-data-widget-item-title
                                        :title="showItemName(item)"
                                        :id="item.id"
                                        :link="showOffers ? setOfferUrl(item) : null"
                                        :to-link-title="t('course.access_types_modal._to_offer')"
                                        class="ml-10"
                                    />
                                </single-switcher>
                                <p v-if="showParticipants" class="email mt-5 ml-25">{{ item.email }}</p>
                            </div>
                            <!-- Pagination button -->
                            <DefaultButton
                                v-if="hasNextPage"
                                key="pagination"
                                :text="t('course.course_card.load_more')"
                                :loading="loading"
                                :background="'white'"
                                size="small"
                                class="load-move-button"
                                @click="loadMore"
                            />
                        </vue-simplebar>
                        <!-- No content -->
                        <flex-container
                            v-show="!items.length"
                            justify="center"
                            align="center"
                            class="items-selection__no-content"
                        >
                            <p>
                                <span class="light-accent">
                                    {{ t("course.access_types_modal.list_is_empty") }}<span v-if="showOffers">,</span>
                                </span>
                                <a
                                    class="link inline-block"
                                    v-if="showOffers"
                                    :href="offersInsidePlatformUrl"
                                    target="_blank"
                                >
                                    {{ t("course.access_types_modal._add_offer") }}
                                </a>
                            </p>
                        </flex-container>
                    </div>

                    <div class="error mb-20" v-if="error">
                        {{ error }}
                    </div>

                    <ModalButtons
                        :loader="loading"
                        :cancel-text="t('course.access_types_modal.cancel')"
                        :confirm-text="t('course.access_types_modal.select')"
                        :disabled="!selectedItem"
                        @cancel="handleModal(true)"
                        @confirm="confirm"
                    />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import KeyTranslator from "@mixins/keyTranslator"
import LoaderBlock from "@components/Loaders/LoaderBlock.vue"
import ClickOutside from "vue-click-outside"
import ModalButtons from "@components/ModalWindow/ModalButtons.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import FloatingButton from "@components/Buttons/FloatingButton.vue"
import UpdateIcon from "@icons/UpdateIcon.vue"
import SearchInput from "@components/Forms/SearchInput.vue"
import axios from "~axios"
import { VueSimplebar } from "vue-simplebar"
import SingleSwitcher from "@components/Forms/SingleSwitcher.vue"
import MarketingDataWidgetItemTitle from "@components/Widgets/MarketingDataWidgetItemTitle.vue"
import CreationBlockLoader from "@expert-components/CreateEntity/components/CreationBlockLoader.vue"
import DefaultButton from "@components/Buttons/DefaultButton.vue"

const type_ids = {
    full_access: "full_access",
    course_setting: "course_setting",
    offer: "offer",
    user: "user"
}

export default {
    name: "CourseAccessTypes",
    components: {
        DefaultButton,
        CreationBlockLoader,
        MarketingDataWidgetItemTitle,
        SingleSwitcher,
        SearchInput,
        UpdateIcon,
        FloatingButton,
        FlexContainer,
        ModalButtons,
        LoaderBlock,
        VueSimplebar
    },
    mixins: [KeyTranslator],
    props: {
        type: {
            type: String,
            default: "course"
        },
        itemId: {
            type: Number,
            required: true
        },
        productId: {
            type: Number,
            required: true
        },
        isMarathon: {
            type: Boolean,
            default: false
        },
        lang: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            langsKeys: this.lang,
            app_subdomain_url_no_locale: null,
            isOpen: false,
            options: [],
            serverData: null,
            selectedOpt: null,
            selectedItem: null,
            loading: false,
            items: [],
            minSearchLength: 3,
            searchQueryErrorText: null,
            searchQuery: null,
            page: 1,
            perPage: 5,
            hasNextPage: false,
            error: ""
        }
    },
    directives: { ClickOutside },
    computed: {
        buttonText() {
            const defaultText = this.t("course.access_types_modal.select")
            const typeName = this.serverData?.type?.name || null
            let typeItem = null
            if (this.showOffers && this.serverData?.offer) typeItem = this.showItemName(this.serverData.offer)
            if (this.showParticipants && this.serverData?.user) typeItem = this.showItemName(this.serverData.user)
            return typeName ? (typeItem ? `${typeName}: ${typeItem}` : typeName) : defaultText
        },
        productType() {
            return this.isMarathon ? "marathon" : "course"
        },
        showSelection() {
            return this.selectedOpt && (this.selectedOpt.id === type_ids.offer || this.selectedOpt.id === type_ids.user)
        },
        showOffers() {
            return this.selectedOpt?.id === type_ids.offer
        },
        showParticipants() {
            return this.selectedOpt?.id === type_ids.user
        },
        offersInsidePlatformUrl() {
            return `${this.app_subdomain_url_no_locale}expert/payments/offers/create`
        }
    },

    watch: {
        langsKeys: {
            handler() {
                this.addDefaultTexts()
            },
            deep: true
        },
        isOpen(val) {
            if (val && this.showSelection) this.updateData()
        },
        searchQuery(val) {
            if (!val || val.length < this.minSearchLength) return
            this.resetDefaultSelectionItemsData()
            return this.updateData()
        }
    },
    created() {
        this.getData()

        if (this.lang) {
            this.addDefaultTexts()
        }
    },
    mounted() {
        document.body.classList.add("body-access-types")
        this.app_subdomain_url_no_locale = window.app_subdomain_url_no_locale
        this.getLangsKeys()

        const el = document.querySelector(`.course-access-types`)

        const setHeight = () => {
            document.body.style.transition = ".3s"
            document.body.style.paddingTop = `${el.getBoundingClientRect().height + 15}px`
        }

        if (el) {
            setHeight()

            const mutation = new MutationObserver(() => {
                setHeight()
            })

            const resize = new ResizeObserver(() => {
                setHeight()
            })

            resize.observe(el)
            mutation.observe(el, { attributes: true, childList: true, subtree: true })
        }
    },
    beforeDestroy() {
        document.body.style.transition = "0s"
        document.body.style.paddingTop = 0
    },
    methods: {
        getLangsKeys() {
            let timer = null
            timer = setInterval(() => {
                if (window.langs) {
                    this.langsKeys = window.langs
                    clearInterval(timer)
                }
            }, 1000)
        },
        addDefaultTexts() {
            this.options = [
                {
                    id: type_ids.full_access,
                    name: this.t("course.access_types_modal.without_restrictions"),
                    description: this.t("course.access_types_modal.without_restrictions_text")
                },
                {
                    id: type_ids.course_setting,
                    name: this.t(`course.access_types_modal.${this.productType}_settings`),
                    description: this.t("course.access_types_modal.settings_text")
                },
                {
                    id: type_ids.offer,
                    name: this.t("course.access_types_modal.by_offer"),
                    description: null
                },
                {
                    id: type_ids.user,
                    name: this.t(`course.access_types_modal.${this.productType}_as_participant`),
                    description: null
                }
            ]
            this.searchQueryErrorText = this.t("expert.not_less_than_error")
                .replace(":field", this.t("expert.search"))
                .replace(":qty", this.minSearchLength)
        },
        async getData() {
            this.error = ""
            this.loading = true
            try {
                const res = await axios.get(`/courses/${this.itemId}/preview-settings`)
                this.serverData = res.data.data
                this.parseServerData()
            } catch (error) {
                this.error = error?.response?.data?.message || error?.response?.data?.error || error
            } finally {
                this.loading = false
            }
        },
        parseServerData() {
            this.selectedOpt = this.serverData.type
            switch (this.serverData.type.id) {
                case type_ids.offer:
                    this.selectedItem = this.serverData.offer
                    break
                case type_ids.user:
                    this.selectedItem = this.serverData.user
                    break
                default:
                    this.selectedItem = null
                    break
            }
        },
        handleModal(reset = false) {
            if (reset) this.parseServerData()
            setTimeout(() => (this.isOpen = !this.isOpen), 0)
        },
        selectType(option) {
            this.selectedOpt = option
            if (this.showSelection) {
                this.resetAllSelectionItemsData()
                this.updateData()
            } else {
                this.updateTypeFromServer()
                this.handleModal()
                this.confirm()
            }
        },
        updateTypeFromServer() {
            this.serverData.type = this.selectedOpt
        },
        async confirm() {
            this.loading = true
            this.error = ""

            const formData = {
                type: this.selectedOpt.id,
                offer_id: this.showOffers ? this.selectedItem.id : null,
                user_id: this.showParticipants ? this.selectedItem.id : null
            }
            try {
                await axios.post(`/courses/${this.itemId}/preview-settings`, formData)
                location.reload()
            } catch (error) {
                this.error = error?.response?.data?.message || error?.response?.data?.error || error
            } finally {
                this.loading = false
            }
        },
        async updateData(pagination = false) {
            if (this.loading) return
            const url = this.showOffers ? "/offers" : `/courses/${this.itemId}/users`
            // Default params
            let params = { page: this.page }
            // Specific offer params
            if (this.showOffers) {
                params = {
                    ...params,
                    products: [this.productId],
                    with_counts: 1,
                    with_draft: 1,
                    display_all: 1,
                    is_full: 1,
                    query: this.searchQuery,
                    per_page: this.perPage
                }
            }
            if (this.showParticipants) {
                params = {
                    ...params,
                    user: this.searchQuery,
                    limit: this.perPage
                }
            }

            this.loading = true
            try {
                const res = await axios.get(url, { params })
                this.items = pagination ? [...this.items, ...res.data.data] : res.data.data
                this.hasNextPage = res.data.links && res.data.links.next
            } catch (e) {
                console.log("error:", e)
            } finally {
                this.loading = false
            }
        },
        loadMore() {
            this.page += 1
            this.updateData(true)
        },
        resetDefaultSelectionItemsData() {
            this.items = []
            this.page = 1
        },
        resetExtraSelectionItemsData() {
            this.selectedItem = null
            this.searchQuery = null
        },
        resetAllSelectionItemsData() {
            this.resetDefaultSelectionItemsData()
            this.resetExtraSelectionItemsData()
        },
        showItemName(item = this.selectedItem) {
            return this.showOffers ? item.title || item.title_alternative : item.name
        },
        selectItem(item) {
            this.selectedItem = item
        },
        setOfferUrl(offer) {
            return `${this.app_subdomain_url_no_locale}expert/payments/offers/edit/${offer.id}`
        }
    }
}
</script>

<style lang="sass" scoped>
@import 'resources/sass/vars'
$defaultBorderRadius: 7px
$defaultMessageBg: $body-color

.default-wrapper
    background: #fbe08c
    border-radius: 0

.course-access-types
    // Service classes
    position: absolute
    top: 0
    left: 0
    right: 0
    margin-bottom: 20px
    @keyframes modalFadeUp
        0%
            opacity: 0
            transform: translateY(-5px)
        100%
            opacity: 1
            transform: translateY(0px)
    .attention
        margin-bottom: 5px
        font-weight: 600
    .no-accent
        color: $text-accent-color
    .accent
        color: $text-color
    .light-accent
        color: $text-color2
    .link
        color: $accent-color7
        text-decoration: none
        border-bottom: 1px solid $accent-color7
        transform: translateY(-1px)
    .mr-5
        margin-right: 5px
    .mb-5
        margin-bottom: 5px
    .mb-2
        margin-bottom: 2px
    .mb-10
        margin-bottom: 10px
    .mb-20
        margin-bottom: 20px
    .ml-10
        margin-left: 10px
    .ml-25
        margin-left: 25px
    .mt-5
        margin-top: 5px
    .pb-20
        padding-bottom: 20px !important
    .inline-block
        display: inline-block
    // Message block
    .message
        padding: 10px
        font-size: 14px
        line-height: 130%
        border: 1px solid $danger-color
        @extend .default-wrapper
        &__loader
            top: 0
            left: 0
            width: 100%
            height: 100%
            @extend .default-wrapper
        &__button
            color: $accent-color5
            border-bottom: 1px solid transparent
            &:hover
                border-bottom: 1px solid $accent-color5
    // Modal
    .types-modal
        width: 600px
        padding: 10px
        background: #fff
        border-radius: 10px
        animation: modalFadeUp 0.5s
        box-shadow: $box-shadow
        z-index: 999999
        @media (max-width: 800px)
            width: 440px
        @media (min-width: 571px)
            left: 20%
        @media (max-width: 570px)
            width: 100%
        &__list-item
            display: flex
            justify-content: center
            flex-direction: column
            min-height: 40px
            padding: 10px 15px
            border-radius: 5px
            transition: $transitionTime
            .title
                font-size: 13px
            .description
                font-size: 11px
            &:hover
                background: rgba($hover-color, 0.8)
            &.selected
                background: $hover-color
                &:hover
                    background: $hover-color
    // Selection block
    .items-selection
        &__no-content
            margin: 30px 0 40px
            font-size: 15px
        &__list
            height: fit-content
            max-height: 240px
        &__list-item
            height: fit-content
            min-height: 35px
            padding: 7px 10px
            border-radius: 5px
            margin-right: 10px
            margin-bottom: 2px
            font-size: 13px
            line-height: 130%
            color: $text-color
            &.checked
                background-color: $hover-color
                &:hover
                    background-color: $hover-color
            &:last-child
                margin-bottom: 0
            &:hover
                background-color: $hover-color2
            .email
                font-size: 11px
                color: $text-accent-color
        .block-loader
            min-height: 50px
        &::v-deep
            .simplebar-track.simplebar-vertical
                max-width: 4px !important
</style>
<style lang="sass">
.course-access-types
    .load-move-button
        margin: 10px 0 10px 35px
        width: fit-content
</style>
