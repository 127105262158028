<template>
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.875 3C14.875 2.51675 14.4832 2.125 14 2.125H6C5.51675 2.125 5.125 2.51675 5.125 3C5.125 3.48325 5.51675 3.875 6 3.875H14C14.4832 3.875 14.875 3.48325 14.875 3ZM14.875 8C14.875 7.51675 14.4832 7.125 14 7.125H2C1.51675 7.125 1.125 7.51675 1.125 8C1.125 8.48325 1.51675 8.875 2 8.875H14C14.4832 8.875 14.875 8.48325 14.875 8ZM14.875 13C14.875 12.5168 14.4832 12.125 14 12.125H6C5.51675 12.125 5.125 12.5168 5.125 13C5.125 13.4832 5.51675 13.875 6 13.875H14C14.4832 13.875 14.875 13.4832 14.875 13Z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "AlignRightIcon"
}
</script>

<style lang="sass" scoped>

</style>
