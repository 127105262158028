<template>
    <flex-container
        key="stopwatch"
        align="center"
        v-if="quiz.attempt && quiz.attempt.passed_time_in_seconds && displayStopWatch()"
        class="mb-10 timer-alert"
    >
        <PracticeStopwatchIcon />

        <PracticeTimer class="ml-14" increase-time track-by="passed_time_in_seconds" :attempt="quiz.attempt" />
    </flex-container>

    <flex-container key="fake-stopwatch" align="center" v-else-if="displayStopWatch()" class="mb-10 timer-alert">
        <PracticeStopwatchIcon />

        <PracticeTimer
            class="ml-14"
            only-display
            track-by="passed_time_in_seconds"
            :attempt="{ passed_time_in_seconds: 0 }"
        />
    </flex-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import PracticeStopwatchIcon from "@components/Practice/common/timer/PracticeStopwatchIcon.vue"
import PracticeTimer from "@components/Practice/common/timer/PracticeTimer.vue"

export default {
    props: {
        quiz: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuizStopwatch",
    components: { PracticeStopwatchIcon, FlexContainer, PracticeTimer },
    methods: {
        displayStopWatch() {
            return this.quiz?.options?.timer_type?.id === 2
        }
    }
}
</script>

<style scoped lang="scss">
.timer-alert {
    background-color: #f1f8fc;
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 160%;
    color: #323030;
    font-weight: 500;
    &::v-deep {
        .practice-timer {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
        }
    }
}
</style>
