<template>
    <div class="practice-text-media-input">
        <DefaultDropdown
            style="max-width: 380px"
            v-if="typeId === TEXT_ANSWER_TYPES.DATE"
            ref="calendar"
            :label-styles="{ left: 25 }"
            :label="t('expert.date')"
            class="full-width"
            placeholder="dd.mm.yyyy"
            :active-case="value ? formatDateDDMMYYYY(value) : ''"
            :cases="[]"
        >
            <template #icon>
                <CalendarIcon width="20" />
            </template>
            <template #modal>
                <CustomCalendar
                    @pick="hideCalendar()"
                    @get="$emit('input', $event)"
                    :need-time="false"
                    :default-emit="true"
                    :allow-year-select="true"
                    :select-date="value"
                    :allow-double-click="false"
                    :allow-old-dates="true"
                />
            </template>
        </DefaultDropdown>
        <div v-if="typeId === TEXT_ANSWER_TYPES.NONE">
            <flex-container class="buttons-container">
                <RecordingVideo :button-view="true" v-if="question.is_video_comment_enabled" @save="addFile($event)" />
                <RecordingAudio :button-view="true" v-if="question.is_audio_comment_enabled" @save="addFile($event)" />
                <UploadFile :button-view="true" v-if="question.can_upload" @save="addFile($event)" />
            </flex-container>
        </div>

        <div
            v-if="[TEXT_ANSWER_TYPES.AREA, TEXT_ANSWER_TYPES.INPUT, TEXT_ANSWER_TYPES.EMAIL].includes(typeId)"
            class="practice-text-media-input-container"
        >
            <FlatLoader v-if="progress" :percent="progress" />

            <flex-container
                v-if="typeId !== TEXT_ANSWER_TYPES.EMAIL"
                :class="{ 'pointer-events-none': readonly }"
                class="practice-text-media-actions"
            >
                <RecordingVideo class="mt-4" v-if="question.is_video_comment_enabled" @save="addFile($event)" />
                <RecordingAudio class="mt-4 ml-8" v-if="question.is_audio_comment_enabled" @save="addFile($event)" />
                <UploadFile
                    @progress="progress = $event"
                    @loadedFile="loadedFile = $event"
                    class="mt-4 ml-8"
                    v-if="question.can_upload"
                    @save="addFile($event)"
                />
            </flex-container>

            <DefaultInput
                @input="$emit('input', $event)"
                @blur="$emit('blur')"
                :value="value"
                :min-height="90"
                :errors="errors"
                :readonly="readonly"
                :is-area="typeId === TEXT_ANSWER_TYPES.AREA"
                :name="typeId === TEXT_ANSWER_TYPES.EMAIL ? 'email' : ''"
                :type="typeId === TEXT_ANSWER_TYPES.EMAIL ? 'email' : 'text'"
                :placeholder="
                    t(typeId === TEXT_ANSWER_TYPES.EMAIL ? 'create-course.enter_email' : 'course.what_you_want_write')
                "
            />

            <default-title v-if="loadedFile" class="mt-10 mb-20" descr :font-size="13">
                {{ loadedFile }} - <b>{{ progress }}%</b>
            </default-title>
        </div>

        <div class="mt-10 mb-10">
            <FilesNewView
                v-if="!didUpdate"
                :short-file="false"
                :files="question.upload_files"
                parent="creator"
                update-preview
                @openRemoveModal="removeFile($event)"
            />
        </div>
    </div>
</template>

<script>
import FilesNewView from "@components/Files/FilesNewView.vue"
import FlatLoader from "@components/Loaders/FlatLoader.vue"
import RecordingAudio from "@components/Recording/RecordingAudio.vue"
import RecordingVideo from "@components/Recording/RecordingVideo.vue"
import UploadFile from "@components/Recording/UploadFile.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import { v1 as uuid_v1 } from "uuid"

import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"

import { TEXT_ANSWER_TYPES } from "@components/Practice/PracticeQuestions/_types"
import DefaultDropdown from "../../../Forms/DefaultDropdown.vue"
import CalendarIcon from "../../../../icons/CalendarIcon.vue"
import CustomCalendar from "../../../CustomCalendar/CustomCalendar.vue"
import moment from "moment"

export default {
    components: {
        DefaultTitle,
        FlatLoader,
        FilesNewView,
        UploadFile,
        RecordingAudio,
        RecordingVideo,
        CustomCalendar,
        CalendarIcon,
        DefaultDropdown,
        DefaultInput,
        FlexContainer
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        },
        question: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean,
            default: true
        },
        value: {
            type: String,
            default: ""
        }
    },
    model: {
        prop: "value",
        event: "input"
    },
    name: "PracticeTextMediaInput",
    data() {
        return {
            uploadInputId: uuid_v1(),
            displayUploadFile: false,
            voiceLoading: false,
            loading: false,
            TEXT_ANSWER_TYPES,
            didUpdate: false,
            progress: 0,
            loadedFile: ""
        }
    },
    created() {
        if (!this.question.upload_files) {
            this.question.upload_files = []
        }
    },
    methods: {
        hideCalendar() {
            const calendar = this.$refs.calendar

            if (calendar) {
                calendar.hide()
            }
        },
        formatDateDDMMYYYY(value) {
            const date = moment(value)
            if (!date.isValid()) {
                return ""
            }
            return date.format("DD MMMM YYYY")
        },
        removeFile(event) {
            if (!this.question.upload_files) {
                return
            }

            const index = this.question.upload_files.indexOf(event)

            if (index !== -1) {
                this.question.upload_files.splice(index, 1)

                this.didUpdate = true

                this.$nextTick(() => {
                    this.didUpdate = false
                    this.$emit("update")
                })
            }
        },
        addFile(item) {
            if (!this.question.upload_files) {
                this.question.upload_files = []
            }

            this.question.upload_files.push(item)
            this.didUpdate = true

            this.$nextTick(() => {
                this.didUpdate = false
                this.$emit("update")
                this.$emit("blur")
            })
        }
    },
    computed: {
        typeId() {
            return this.question.text_answer_type_id || this.question.text_answer_type?.id
        }
    }
}
</script>

<style scoped lang="sass">
.comments-create-btn
    width: 30px
    height: 30px
    border-radius: 4px
    margin-left: 8px
    cursor: pointer
    margin-top: 4px
    transition: 0.2s all
    background: #fff
    &:hover
        background-color: #F2F7FF
    @media screen and (max-width: 640px)
        width: 24px
        height: 24px
.buttons-container
    display: grid !important
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 20px
    .comments-create-btn
        margin: 0
    @media (max-width: 980px)
        grid-template-columns: 1fr
        width: 100%
.practice-text-media-input-container
    position: relative
    border-radius: 8px
    .practice-text-media-actions
        position: absolute
        right: 15px
        top: 5px
        z-index: 5
        @media (max-width: 650px)
            top: -15px
    &::v-deep
        .recorder__inner
            transform: translateX(-100%) !important
        .default-input__input
            text-indent: 15px
            padding-right: 120px
            @media (max-width: 650px)
                padding-right: 15px
        .ProseMirror
            padding: 15px 87px 15px 15px
            font-size: 13px
            min-height: 100px
</style>
<style scoped lang="sass"></style>
