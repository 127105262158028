<template>
    <div ref="lesson" v-if="langs && !Array.isArray(langs)" :style="{ opacity: mount ? 1 : 0 }" class="lesson">
        <CourseAccessTypes
            v-if="lessonDto.course_access.is_user_expert"
            :lang="langs"
            :item-id="lessonDto.course.id"
            :product-id="lessonDto.course.product_id"
            :is-marathon="lessonDto.course.type_id === 2"
        />

        <FileTasks />

        <LessonHeader :edit-link="editLink" />

        <LessonUnavailable :lesson-dto="lessonDto" v-if="isNotAvailable" />

        <LessonGetAccess v-if="isUnaccessible" />

        <CoursePublicUnitsWrapper
            v-else-if="!isNotAvailable"
            :units="getUnits()"
            :lesson="lesson"
            :product-id="course.product_id"
            :is-auth="true"
            :course-access="lessonDto.course_access"
            order-url=""
            csrf-token=""
            parent="lesson"
        />

        <LessonNavButtons
            class="mt-20"
            v-if="course.navigation_type_id && course.navigation_type_id === 2 && hasOverflow()"
            :l="langs"
            :type="course.navigation_type_id"
            :lesson="lesson"
            :paginate-info="lessonDto.paginate_info"
        />
        <div v-else-if="$screenWidth < 1300" class="mt-20"></div>

        <LessonProgramHandler is-sticky v-if="$screenWidth < 1300 && hasOverflow()" />
    </div>
</template>

<script>
import updateGlobalFont from "@components/Editor/update-global-font"
import FileTasks from "@components/Files/FileTasks.vue"
import LessonNavButtons from "@lesson/components/LessonNavButtons.vue"
import LessonProgramHandler from "@lesson/components/LessonProgramHandler.vue"
import { SET_COURSE, SET_LESSON, SET_LESSON_DTO, SET_SECTION } from "@lesson/shared/state/lesson-mutations"
import LessonHeader from "@lesson/components/LessonHeader.vue"
import LessonUnavailable from "@lesson/components/LessonUnavailable.vue"
import CoursePublicUnitsWrapper from "@components/Course/units/CoursePublicUnitsWrapper.vue"
import LessonGetAccess from "@lesson/components/LessonGetAccess.vue"
import { EventBus } from "~events"
import CourseAccessTypes from "@components/Course/CourseAccessTypes"

export default {
    components: {
        FileTasks,
        LessonProgramHandler,
        LessonNavButtons,
        LessonGetAccess,
        CoursePublicUnitsWrapper,
        LessonUnavailable,
        LessonHeader,
        CourseAccessTypes
    },
    props: {
        lesson: {
            type: Object,
            default: null
        },
        lessonDto: {
            type: Object,
            default: null
        },
        section: {
            type: Object,
            default: null
        },
        course: {
            type: Object,
            default: null
        }
    },
    name: "Lesson",
    data() {
        return {
            mount: false
        }
    },
    created() {
        this.$store.commit(SET_LESSON, this.lesson)
        this.$store.commit(SET_SECTION, this.section)
        this.$store.commit(SET_COURSE, this.course)
        this.$store.commit(SET_LESSON_DTO, this.lessonDto)

        setTimeout(() => {
            EventBus.$emit("lesson-loaded")
        }, 100)

        if (!window.langs && this.langs) {
            window.langs = this.langs
        }
        //this.registerServiceWorker()
    },
    mounted() {
        setTimeout(() => {
            this.mount = true
        }, 200)

        const units = this?.$el?.querySelector(".course-public-units-wrapper")

        updateGlobalFont()

        if (!units) {
            return
        }

        const resizeObserver = new ResizeObserver(() => {
            this.$forceUpdate()
        })

        resizeObserver.observe(units)
    },
    methods: {
        async registerServiceWorker() {
            try {
                await navigator.serviceWorker.register(new URL("./sw/sw.js", import.meta.url), {
                    type: "module"
                })
            } catch (error) {
                console.log(error)
            }
        },
        hasOverflow() {
            const el = this.$refs.lesson

            if (!el) {
                return false
            } else {
                return el.getBoundingClientRect().height + 200 > window.innerHeight
            }
        },
        getUnits() {
            return this.lessonDto.course_access.is_practice_available
                ? this.section.info_units
                : this.section.info_units.filter(unit => unit.type_id !== 5)
        }
    },
    watch: {
        langs(langs) {
            if (!window.langs) {
                window.langs = langs
            }
        }
    },
    computed: {
        editLink() {
            let sectionOrder = this.lessonDto.section.order - 1

            return this.lessonDto.course_access.is_user_expert
                ? `/expert/course/${this.lesson.course_id}/lesson/${this.lesson.id}/constructor?section=${sectionOrder}`
                : ""
        },
        langs() {
            return this.$store.getters["langs/getLangs"]
        },
        isUnaccessible() {
            //not active
            if (this.lessonDto.lesson.status_id !== 4) {
                return false
            }

            return (
                (this.lessonDto.course_access.is_product_on_sale &&
                    !this.lessonDto.course_access.is_course_subscribed &&
                    !this.lessonDto.is_lesson_available) ||
                (this.lessonDto.blocked_info &&
                    !this.lessonDto.blocked_info.is_accessible &&
                    this.lessonDto.blocked_info.type === "offer")
            )
        },
        isNotAvailable() {
            return (
                !this.lesson.is_public &&
                (!this.lessonDto.course_access.is_course_subscribed || !this.lessonDto.is_lesson_available)
            )
        }
    }
}
</script>
