import { getPersonalSettings } from "@common-store/personal-settings"
import Image from "@tiptap/extension-image"
import { VueNodeViewRenderer } from "@tiptap/vue-2"
import ResizableImageTemplate from "./ResizableImageTemplate.vue"

export default Image.extend({
    name: "ResizableImage",
    draggable: true,
    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: "",
                renderHTML: attributes => {
                    return {
                        width: attributes.width || "100%"
                    }
                }
            },
            fullwidth: {
                default: false,
                renderHTML: attributes => {
                    return {
                        fullwidth: attributes.width === "100%"
                    }
                }
            },
            height: {
                default: "auto",
                renderHTML: attributes => {
                    return {
                        height: attributes.height
                    }
                }
            },
            display: {
                default: "block",
                renderHTML: attributes => {
                    return {
                        display: attributes.display
                    }
                }
            },
            "data-file-id": {
                default: "0",
                renderHTML: attributes => {
                    return {
                        "data-file-id": attributes["data-file-id"]
                    }
                }
            },
            justify: {
                default: "flex-start",
                renderHTML: attributes => {
                    return {
                        justify: attributes.justify
                    }
                }
            },
            hidemobile: {
                default: false,
                renderHTML: attributes => {
                    return {
                        hidemobile: attributes.hidemobile
                    }
                }
            },
            allowzoom: {
                default: () => {
                    return getPersonalSettings("allowzoom") || false
                },
                renderHTML: attributes => {
                    return {
                        allowzoom: attributes.allowzoom
                    }
                }
            },
            hidetablet: {
                default: false,
                renderHTML: attributes => {
                    return {
                        hidetablet: attributes.hidetablet
                    }
                }
            },
            hidedesktop: {
                default: false,
                renderHTML: attributes => {
                    return {
                        hidedesktop: attributes.hidedesktop
                    }
                }
            }
        }
    },

    addCommands() {
        return {
            ...this.parent?.(),
            toggleResizable:
                () =>
                ({ tr }) => {
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    const { node } = tr?.selection

                    if (node?.type?.name === "ResizableImage") {
                        node.attrs.isDraggable = !node.attrs.isDraggable
                    }
                }
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableImageTemplate)
    }
})
