<template>
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.125 3C3.125 2.51675 3.51675 2.125 4 2.125H12C12.4832 2.125 12.875 2.51675 12.875 3C12.875 3.48325 12.4832 3.875 12 3.875H4C3.51675 3.875 3.125 3.48325 3.125 3ZM1.125 8C1.125 7.51675 1.51675 7.125 2 7.125H14C14.4832 7.125 14.875 7.51675 14.875 8C14.875 8.48325 14.4832 8.875 14 8.875H2C1.51675 8.875 1.125 8.48325 1.125 8ZM3.125 13C3.125 12.5168 3.51675 12.125 4 12.125H12C12.4832 12.125 12.875 12.5168 12.875 13C12.875 13.4832 12.4832 13.875 12 13.875H4C3.51675 13.875 3.125 13.4832 3.125 13Z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "AlignCenterIcon"
}
</script>

<style lang="sass" scoped>

</style>
