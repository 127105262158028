
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 15.5C13.3261 15.5 14.5979 14.9732 15.5355 14.0355C16.4732 13.0979 17 11.8261 17 10.5V5.5C17 5.23478 16.8946 4.98043 16.7071 4.79289C16.5196 4.60536 16.2652 4.5 16 4.5C15.7348 4.5 15.4804 4.60536 15.2929 4.79289C15.1054 4.98043 15 5.23478 15 5.5V10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5V5.5C9 5.23478 8.89464 4.98043 8.70711 4.79289C8.51957 4.60536 8.26522 4.5 8 4.5C7.73478 4.5 7.48043 4.60536 7.29289 4.79289C7.10536 4.98043 7 5.23478 7 5.5V10.5C7 11.8261 7.52678 13.0979 8.46447 14.0355C9.40215 14.9732 10.6739 15.5 12 15.5ZM17 17.5H7C6.73478 17.5 6.48043 17.6054 6.29289 17.7929C6.10536 17.9804 6 18.2348 6 18.5C6 18.7652 6.10536 19.0196 6.29289 19.2071C6.48043 19.3946 6.73478 19.5 7 19.5H17C17.2652 19.5 17.5196 19.3946 17.7071 19.2071C17.8946 19.0196 18 18.7652 18 18.5C18 18.2348 17.8946 17.9804 17.7071 17.7929C17.5196 17.6054 17.2652 17.5 17 17.5Z"
        />
    </svg>
</template>
<script>
export default {
    name: "UnderlineIcon"
}
</script>
