<template>
    <AlignLeftIcon v-if="align === 'left'" />
    <AlignCenterIcon v-else-if="align === 'center'" />
    <AlignRightIcon v-else-if="align === 'right'" />
    <AlignLeftIcon v-else />
</template>

<script>
import AlignLeftIcon from "@components/Editor/icons/AlignLeftIcon.vue"
import AlignCenterIcon from "@components/Editor/icons/AlignCenterIcon.vue"
import AlignRightIcon from "@components/Editor/icons/AlignRightIcon.vue"

export default {
    components: { AlignCenterIcon, AlignLeftIcon, AlignRightIcon },
    props: {
        align: {
            type: String,
            default: "left"
        }
    },
    name: "ResolveAlignIcon"
}
</script>

<style lang="sass" scoped>

</style>
