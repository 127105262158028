<template>
    <button
        @click="$emit('update', !checked)"
        class="default-check"
        :class="{ 'default-check_checked': checked, 'default-check_highlight': highlightBorder && !checked }"
    >
        <svg style="margin-left: 0" width="11" height="9" viewBox="0 0 11 9" fill="none">
            <path
                d="M3.57143 8L3.01903 8.5073C3.16107 8.66197 3.36144 8.75 3.57143 8.75C3.78142 8.75 3.98179 8.66197 4.12382 8.5073L3.57143 8ZM10.5524 1.5073C10.8326 1.20222 10.8124 0.727779 10.5073 0.447603C10.2022 0.167426 9.72778 0.187616 9.4476 0.492696L10.5524 1.5073ZM1.5524 4.6927C1.27222 4.38762 0.797777 4.36743 0.492696 4.6476C0.187616 4.92778 0.167426 5.40222 0.447603 5.7073L1.5524 4.6927ZM4.12382 8.5073L10.5524 1.5073L9.4476 0.492696L3.01903 7.4927L4.12382 8.5073ZM4.12382 7.4927L1.5524 4.6927L0.447603 5.7073L3.01903 8.5073L4.12382 7.4927Z"
                fill="white"
            />
        </svg>
    </button>
</template>

<script>
export default {
    props: {
        checked: {
            type: [Boolean, Number, Object],
            default: false
        },
        highlightBorder: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: "checked",
        event: "update"
    },
    name: "DefaultCheck"
}
</script>

<style scoped lang="sass">
.check-container
    font-size: 13px
    font-family: Inter, sans-serif
    color: #151515
.default-check
    cursor: pointer
    width: 20px
    min-width: 20px
    height: 20px
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    background: #fff
    border: 1px solid #DDDDDD
    transition: .2s

    svg
        margin-bottom: 1px
    &:hover
        background: rgba(21, 160, 110, 0.52)
        border-color: rgba(21, 160, 110, 0.52)
    &_checked
        background: #15A06E !important
        border-color: #15A06E !important
    &_highlight
        border: 2px solid #15A06E !important
</style>
