<template>
    <svg width="34" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="9.5" y="14.001" width="14" height="1.5" rx="0.75" fill="white" />
        <rect x="9.5" y="18.499" width="14" height="1.5" rx="0.75" fill="white" />
        <path d="M16.5 12L16.5 8M16.5 8L18 9M16.5 8L15 9" stroke="white" stroke-linecap="round" />
        <path d="M16.5 22L16.5 26M16.5 26L15 25M16.5 26L18 25" stroke="white" stroke-linecap="round" />
    </svg>
</template>

<script>
export default {
    name: "ResizerIcon"
}
</script>

<style lang="sass" scoped>

</style>
