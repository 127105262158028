<template>
    <div class="order-statement">
        <default-description
            v-if="statement.text"
            class="mb-5"
            :weight="600"
            :line-height="18"
            :size="14"
            text-color="#323030"
        >
            <span v-if="question.statements && question.statements.length > 1" class="number"> {{ number }}. </span>
            <PracticeQuestionDescription :description="statement.text" />
        </default-description>
        <span
            v-if="question.statements && !statement.text && question.statements && question.statements.length > 1"
            class="number mt-10 mr-14"
        >
            {{ number }}.
        </span>
        <slot></slot>

        <div :class="{ 'order-statement-container': isFlexRow }" v-if="isDragView && interactive">
            <custom-collapse
                horizontal
                :is-open="(statement.correct_answers && statement.correct_answers.length > 0) || drag || !isFlexRow"
            >
                <div class="drag-area">
                    <draggable
                        :class="{
                            'pointer-events-none': readonly || !interactive,
                            'add-padding': !statement.correct_answers.length
                        }"
                        :handle="isAndroid() ? '.move-handler' : null"
                        @end="onEnd"
                        group="answers"
                        :list="statement.correct_answers"
                        class="drag-area-draggable"
                        v-bind="dragOptions"
                        style="display: flex; flex-wrap: wrap"
                    >
                        <div
                            v-for="answer in statement.correct_answers"
                            :key="answer.uuid"
                            @click="spliceAnswer(answer)"
                            :class="{
                                correct: isAnswerCorrect(answer),
                                incorrect: !isAnswerCorrect(answer),
                                moved: true,
                                android: isAndroid(),
                                rest: answer.isRest,
                                colored:
                                    isAnswerCorrect(answer) !== undefined &&
                                    !statement.is_force &&
                                    !interactive &&
                                    typeShow !== 'simple_answer' &&
                                    typeShow !== 'only_correct_or_not_question'
                            }"
                            class="order-answer order-answer_active"
                        >
                            <transition name="fade-up">
                                <button v-if="isAndroid()" class="move-handler">
                                    <Move1Icon />
                                </button>
                            </transition>
                            <span>
                                {{ answer.text }}
                            </span>
                        </div>
                    </draggable>
                    <p
                        v-if="
                            statement[answersKey] &&
                            statement[answersKey].length > 0 &&
                            !statement.correct_answers.length
                        "
                        :class="{ 'ml-10': !statement.correct_answers.length && isFlexRow, v2: !isFlexRow }"
                        class="drag-area-placeholder"
                    >
                        <template v-if="$screenWidth > 640"> drop here</template>
                    </p>
                </div>
            </custom-collapse>

            <draggable
                style="display: flex; flex-wrap: wrap; grid-gap: 4px"
                :class="[
                    { 'pointer-events-none': readonly || !interactive, 'mt-10': !isFlexRow },
                    $screenWidth > 640 ? 'ml-10' : 'mt-10'
                ]"
                group="answers"
                :handle="isAndroid() ? '.move-handler' : null"
                @end="onEnd"
                @start="drag = true"
                :list="statement[answersKey]"
                v-bind="dragOptions"
            >
                <div
                    v-for="(answer, index) in statement[answersKey]"
                    :key="index"
                    :class="{
                        correct: isAnswerCorrect(answer),
                        incorrect: !isAnswerCorrect(answer),
                        android: isAndroid(),
                        rest: answer.isRest,
                        colored:
                            isAnswerCorrect(answer) !== undefined &&
                            !statement.is_force &&
                            !interactive &&
                            typeShow !== 'simple_answer' &&
                            typeShow !== 'only_correct_or_not_question'
                    }"
                    @click="pushAnswer(answer)"
                    class="order-answer order-answer_active"
                >
                    <transition name="fade-up">
                        <button v-if="isAndroid()" class="move-handler">
                            <Move1Icon />
                        </button>
                    </transition>
                    <span>
                        {{ answer.text }}
                    </span>
                </div>
            </draggable>
        </div>
        <template v-else>
            <flex-container class="answers-flex-container" style="gap: 4px">
                <draggable
                    :class="{ 'pointer-events-none': readonly || !interactive }"
                    v-model="answersModel"
                    :move="handleMove"
                    :handle="isAndroid() ? '.move-handler' : null"
                    @end="onEnd"
                    @start="drag = true"
                    v-bind="dragOptions"
                >
                    <transition-group
                        tag="div"
                        class="answers-container"
                        type="transition"
                        :name="drag ? 'move-list' : null"
                    >
                        <div
                            v-for="answer in statement[answersKey]"
                            @click="handleClick(answer, $event)"
                            @contextmenu.prevent="handleClick(answer, $event)"
                            :key="answer.uuid || answer.id"
                            :class="{
                                correct: isAnswerCorrect(answer),
                                incorrect: !isAnswerCorrect(answer),
                                moved: answer.moved,
                                android: isAndroid(),
                                rest: answer.isRest,
                                colored:
                                    isAnswerCorrect(answer) !== undefined &&
                                    !statement.is_force &&
                                    !interactive &&
                                    typeShow !== 'simple_answer' &&
                                    typeShow !== 'only_correct_or_not_question'
                            }"
                            class="order-answer"
                        >
                            <transition name="fade-up">
                                <button v-if="answer.moved && isAndroid()" class="move-handler">
                                    <Move1Icon />
                                </button>
                            </transition>
                            <span>
                                {{ answer.text }}
                            </span>
                        </div>
                    </transition-group>
                </draggable>
            </flex-container>
        </template>
    </div>
</template>

<script>
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import PracticeQuestionDescription from "@components/Practice/PracticeQuestions/components/PracticeQuestionDescription.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import Move1Icon from "@icons/Move1Icon.vue"
import draggable from "vuedraggable"

export default {
    components: {
        PracticeQuestionDescription,
        CustomCollapse,
        Move1Icon,
        FlexContainer,
        draggable,
        DefaultDescription
    },
    props: {
        statement: {
            type: Object,
            default: () => {}
        },
        results: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        interactive: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            default: () => {}
        },
        typeShow: {
            type: String,
            default: ""
        },
        answersKey: {
            type: String,
            default: "shuffled_answers"
        },
        expertView: {
            type: Boolean,
            default: false
        },
        isFlexRow: {
            type: Boolean,
            default: true
        },
        isRTL: {
            type: Boolean,
            default: false
        },
        number: {
            type: Number,
            default: 0
        },
        attempt: {
            type: Object,
            default: () => {}
        },
        lesson: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeWordOrderStatement",
    data() {
        return {
            drag: false,
            dragOptions: {
                animation: 200,
                group: "description",
                handle: ".move-handler",
                disabled: false,
                ghostClass: "ghost",
                forceFallback: navigator.userAgent.toLowerCase().indexOf("firefox") > -1
            },
            isDragView: true
        }
    },
    created() {
        if (this.isDragView && !this.statement.correct_answers) {
            this.$set(this.statement, "correct_answers", [])
        }
    },
    methods: {
        pushAnswer(answer) {
            const index = this.statement[this.answersKey].indexOf(answer)
            answer.moved = true

            if (this.isRTL) {
                this.statement.correct_answers.unshift(answer)
            } else {
                this.statement.correct_answers.push(answer)
            }
            this.statement[this.answersKey].splice(index, 1)

            this.$forceUpdate()
            this.$emit("callout")
        },
        spliceAnswer(answer) {
            const index = this.statement.correct_answers.indexOf(answer)
            answer.moved = true

            if (this.isRTL) {
                this.statement[this.answersKey].unshift(answer)
            } else {
                this.statement[this.answersKey].push(answer)
            }

            this.statement.correct_answers.splice(index, 1)
            this.$forceUpdate()
            this.$emit("callout")
        },
        onEnd() {
            this.$emit("callout")
            this.drag = false
            this.$forceUpdate()
        },
        isAndroid() {
            return /(android)/i.test(navigator.userAgent)
        },
        handleMove(evt) {
            return !!this.statement[this.answersKey][evt.draggedContext.futureIndex].moved
        },
        handleClick(answer) {
            const answers = this.statement[this.answersKey]
            this.drag = true

            if (answer.moved) {
                answer.moved = false
            } else {
                answer.moved = true
            }

            answers.sort((x, y) => {
                return x.moved === y.moved ? 0 : x.moved ? -1 : 1
            })

            this.$emit("callout")

            this.$forceUpdate()

            setTimeout(() => {
                this.drag = true
            }, 200)
        },

        isAnswerCorrect(answer) {
            if (this.interactive) {
                return false
            }

            if (this.answersKey === "answers") {
                return true
            }

            const result = this.results.find(result => result.quiz_statement_id === this.statement.id)

            if (result) {
                let index = answer.index

                if (this.isRTL) {
                    let countChosenAnswers = this.statement["shuffled_answers"].filter(
                        shuffledAnswer => !shuffledAnswer.isRest
                    ).length

                    index = countChosenAnswers - 1 - answer.index
                }

                return !result?.additional?.sentence_diff[index]
            }
            return true
        },
        isAnswered(answer) {
            const result = this.results && this.results.find(r => r.quiz_answer_id === answer.id)

            if (this.interactive || !this.results) {
                return true
            }

            return result
        }
    },
    computed: {
        answersModel: {
            get() {
                return this.statement[this.answersKey]
            },
            set(val) {
                this.statement[this.answersKey] = val
                this.onEnd()
            }
        }
    }
}
</script>

<style scoped lang="sass">
.add-padding
    padding-right: 70px !important
.drag-area-placeholder
    text-align: center
    white-space: nowrap
    display: flex
    align-items: center
    margin-right: 10px
    font-family: Inter, sans-serif
    pointer-events: none
    color: #808080
    pointer-events: none
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: 0px
    font-size: 12px
    &.v2
        text-transform: capitalize
        left: 50%
        transform: translate(-50%, -50%)
        width: max-content

.drag-area-draggable
    width: 100%
    display: flex
    transition: .2s
    min-width: 75px
    padding: 8px
    grid-gap: 4px 4px
    .answers-container
        width: 100%
.drag-area
    display: flex
    position: relative
    max-width: 700px
    min-height: 34px
    font-weight: 300
    overflow: hidden
    background: #f1f7ff
    //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%238499E5FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")
    border-radius: 5px
    border: 1px dashed #3965FF
.order-answer
    margin: 0
    white-space: nowrap
    padding: 10px
    font-size: 13px
    font-weight: 400
    color: #52565C
    border: 1px solid #E0E0EC
    background-color: #fff
    cursor: pointer
    border-radius: 7px
    position: relative
    z-index: 2
    display: flex
    align-items: center
    -webkit-user-drag: none
    -khtml-user-drag: none
    -moz-user-drag: none
    -o-user-drag: none
    user-drag: none
    &_active
        -webkit-user-drag: element
        -khtml-user-drag: element
        -moz-user-drag: element
        -o-user-drag: element
        user-drag: element
    span
        user-select: none
        pointer-events: none
    .move-handler
        position: absolute
        left: 2px
        top: 4px
    &.moved
        color: #3965FF
        border-color: #3965FF
        -webkit-user-drag: element
        -khtml-user-drag: element
        -moz-user-drag: element
        -o-user-drag: element
        user-drag: element
    &.android
        padding-left: 25px
    &.colored
        &.correct
            color: #15A06E
            border-color: #15A06E
        &.incorrect
            color: #DD4141
            border-color: #DD4141
        &.rest
            color: #52565C
            border-color: #E0E0EC
.answers-container
    display: flex
    align-items: center
    flex-wrap: wrap
    margin-left: -8px
    gap: 4px

.move-list-move
    transition: transform 0.5s

.no-move
    transition: transform 0s
.number
    margin-right: 4px
    font-size: 14px
    line-height: 1.5
    font-weight: 400
    color: #B5C1D2
    white-space: nowrap
    &.mr-14
        margin-right: 14px
.ghost
    opacity: 0.5
    background-color: rgba(#E0E0EC, .5)

.order-statement
    &:not(&:last-child)
        margin-bottom: 20px

.order-statement-container
    display: flex
    align-items: center
    @media (max-width: 640px)
        flex-direction: column
        align-items: flex-start
</style>
