<template>
    <node-view-wrapper class="tooltip-component">
        <label contenteditable="false">
            <alert-with-question without-close>
                <div>
                    <DefaultInput
                        @input="
                            updateAttributes({
                                description: node.attrs.description,
                                title: $event
                            })
                        "
                        :value="node.attrs.title"
                        :placeholder="t('create-course.title')"
                    />
                    <DefaultInput
                        @input="
                            updateAttributes({
                                description: $event,
                                title: node.attrs.title
                            })
                        "
                        :value="node.attrs.description"
                        :placeholder="t('course.description')"
                        class="mt-10"
                        is-area
                    />
                </div>
            </alert-with-question>
        </label>
        <node-view-content />
    </node-view-wrapper>
</template>

<script>
import AlertWithQuestion from "@components/Alerts/AlertWithQuestion.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"

export default {
    components: {
        DefaultInput,
        AlertWithQuestion,
        NodeViewWrapper,
        NodeViewContent
    },
    props: nodeViewProps,
    name: "TooltipTemplate"
}
</script>

<style scoped lang="sass">
.pr-10
    padding-right: 10px
.tooltip-component
    display: flex
    align-items: center
    margin-top: 5px
    margin-bottom: 5px
    .alert-with-question
        margin-right: 8px
</style>
