import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import CodeTemplate from "./CodeTemplate.vue"

export default Node.create({
    name: "CodeTemplate",

    group: "block",

    atom: true,

    addAttributes() {
        return {
            theme: {
                default: "vs-dark"
            },
            language: {
                default: "html"
            },
            value: {
                default: ""
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: "code-component"
            }
        ]
    },

    renderHTML(value) {
        const { HTMLAttributes } = value
        return ["code-component", mergeAttributes(HTMLAttributes)]
    },

    addCommands() {
        return {
            setCustomCode:
                ({ theme, language, value = "" }) =>
                ({ chain }) => {
                    return chain()
                        .insertContent(
                            `<code-component value="${value}" theme="${theme}"  language="${language}"></code-component>`
                        )
                        .run()
                }
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(CodeTemplate)
    }
})
