<template>
    <div class="code-editor-container">
        <div v-if="showHeader" :style="{ width: `${layout.width}` }" class="code-editor__header">
            <flex-container align="center">
                <button
                    @mousedown.prevent="create(template.code)"
                    class="ready-template"
                    v-for="(template, index) in templates"
                    :key="index"
                >
                    {{ template.name }}
                </button>
            </flex-container>
            <div @mousedown="handleMouseDown" class="resize-handler">
                <ResizerIcon />
            </div>
            <div @mousedown.prevent="handleHide">
                <MinusIcon />
            </div>
        </div>

        <div ref="editor" :style="{ height: `${layout.height}` }" class="code-editor">
            <editor-content
                :style="{ height: `${layout.height}` }"
                class="custom-scroll"
                spellcheck="false"
                :editor="editor"
            />
        </div>
    </div>
</template>

<script>
import ResizerIcon from "@icons/ResizerIcon.vue"
import MinusIcon from "@icons/MinusIcon.vue"
import StarterKit from "@tiptap/starter-kit"
import { Editor, EditorContent } from "@tiptap/vue-2"
import { CodeBlock } from "@tiptap/extension-code-block"

import pretty from "pretty"
import { Debouncer } from "@helpers"
import FlexContainer from "@components/Containers/FlexContainer.vue"

const BUTTON = `<p style="text-align: center; "><a target="_blank" rel="noopener noreferrer nofollow" href="/"><span style="background: rgb(57, 101, 255); cursor: pointer; margin: 0px auto; text-align: center; width: 200px; padding: 10px 20px; border-radius: 20px; display: inline-block; color: rgb(255, 255, 255)">My Super Button!</span></a></p>`
const FRAME = `<iframe width="560" height="315" src="https://www.youtube.com/embed/7elZOs6jUIU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
const P = `<p></p>`
const UL = `<ul><li><p>First</p></li><li><p>Second</p></li></ul>`
const CTA = `<p style="display: block; background: #f5f5f5;  width: 100%; text-align:center; padding: 20px;  border-radius: 10px;">
<span style="font-size: 22px; margin: 10px 0 5px 0; display: inline-block">
   Some new text for the <strong>CTA<strong>!
</span>
<a href="/">
   <span style="display: block; margin: 10px auto 10px auto; background: #FF7439; color: #fff; cursor: pointer; border-radius: 25px; font-size: 16px; width: 200px; padding: 11px">
     JOIN COURSE
   </span>
</a>
</p>
`
const IMG = `<img width="468" height="auto" src='/build/img//home/cta.jpg' />`

export default {
    name: "CodeEditor",
    props: {
        html: {
            type: String,
            default: ""
        },
        collapseOnHide: {
            type: Boolean,
            default: false
        }
    },
    components: { FlexContainer, MinusIcon, ResizerIcon, EditorContent },
    data() {
        return {
            layout: {
                y: 0,
                width: `100vw`,
                height: `250px`
            },
            showHeader: true,
            editor: null,
            debouncer: new Debouncer(),
            codes: {
                BUTTON,
                FRAME,
                UL,
                CTA,
                IMG
            },
            templates: [
                {
                    name: "Button",
                    code: BUTTON
                },
                {
                    name: "Iframe",
                    code: FRAME
                },
                {
                    name: "P",
                    code: P
                },
                {
                    name: "Ul",
                    code: UL
                },
                {
                    name: "CTA",
                    code: CTA
                },
                {
                    name: "Img",
                    code: IMG
                }
            ]
        }
    },
    mounted() {
        this.editor = new Editor({
            extensions: [StarterKit.configure(), CodeBlock],
            content: this.formatHtml(this.html),
            onUpdate: () => {
                this.$emit("input", this.editor.getText())
            }
        })
        setTimeout(() => {
            this.editor.commands.focus()
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },
    methods: {
        shouldShow() {
            return this.editor.getText() === ""
        },
        create(template) {
            this.$emit("input", template)
            this.editor.commands.insertContent(this.formatHtml(template))
        },
        formatHtml(html) {
            return `<pre><code><textarea spellCheck="false">${pretty(html)}</textarea></code></pre>`
        },
        handleHide() {
            this.$emit("hide")
            if (this.collapseOnHide) {
                if (this.layout.height === "0") {
                    this.layout.height = "250px"
                } else {
                    this.layout.height = "0"
                }
            }
        },
        handleMouseDown(event) {
            this.layout.y = event.clientY
            if (document) {
                document.addEventListener("mousemove", this.handleMouseMove)
                document.addEventListener("mouseup", this.handleMouseUp)
                document.body.style.userSelect = "none"
            }
        },
        handleMouseMove(event) {
            const dy = event.clientY - this.layout.y

            const { height } = this.$refs.editor.getBoundingClientRect()

            if (height - dy < 540) {
                this.layout.height = `${height - dy}px`
                this.layout.y = event.clientY

                this.$forceUpdate()
            }
        },
        handleMouseUp() {
            document.removeEventListener("mousemove", this.handleMouseMove)
            document.removeEventListener("mouseup", this.handleMouseUp)
            document.body.style.userSelect = "auto"
        }
    },
    watch: {
        html(val) {
            this.debouncer.exec(() => {
                const isSame = this.editor.getHTML() === val

                if (isSame || this.collapseOnHide) {
                    return
                }
                this.editor.commands.setContent(this.formatHtml(this.html))
            }, 500)
        }
    }
}
</script>
<style lang="sass">
.custom-scroll
    overflow: auto
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap')

.code-editor-container
    position: fixed
    z-index: 99999
    left: 0
    bottom: 0
    width: 100%
    .ProseMirror
        outline: none
        border: none
        max-height: 100%
        height: 100%
        padding: 20px
        overflow: auto
    .markdown-hover, .status-bar
        display: none
    .code-editor
        background: #1F1F1F
        .hljs-comment,
        .hljs-quote
            color: #616161
        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class
            color: #527BD7
        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params
            color: #FBBC88
        .hljs-string,
        .hljs-symbol,
        .hljs-bullet
            color: #ffa779

        .hljs-title,
        .hljs-section
            color: #FAF594

        .hljs-keyword,
        .hljs-selector-tag,
        .hljs-attr, .hljs-attribute
            color: #70CFF8
        .hljs-emphasis
            font-style: italic

        .hljs-strong
            font-weight: 700

        pre
            font-family: 'JetBrains Mono', monospace
            & > *
                font-family: 'JetBrains Mono', monospace
        code
            padding: 0
            background: none
            font-size: 0.9rem
            color: #fff

        p, h1
            color: #fff
        &__header
            display: flex
            align-items: center
            justify-content: space-between
            height: 40px
            background-color: #768DB0
            padding: 0 20px
            & > div
                cursor: pointer
                width: 36px
                height: 36px
                display: flex
                align-items: center
                justify-content: center
            .resize-handler
                cursor: row-resize
</style>
