<template>
    <editor-dropdown :editor="editor" chevron class="l-18" ref="dropdown">
        <template #handler>
            <BulletListIcon :data-tippy-content="t('app-meta.title.list')" />
        </template>
        <template #content>
            <template v-for="(list, index) in lists">
                <div
                    :key="index"
                    class="editor-menu-item"
                    @click="execCommand(list.command)"
                    :class="{
                        active: editor.isActive(list.key)
                    }"
                >
                    <component class="mr-8" :is="list.icon"></component>

                    {{ list.tooltip }}
                </div>
            </template>
        </template>
    </editor-dropdown>
</template>

<script>
import EditorDropdown from "@components/Editor/components/EditorDropdown.vue"
import ResolveAlignIcon from "@components/Editor/icons/ResolveAlignIcon.vue"
import BulletListIcon from "@components/Editor/icons/BulletListIcon.vue"
import OrderedListIcon from "@components/Editor/icons/OrderedListIcon.vue"
import TaskListIcon from "@components/Editor/icons/TaskListIcon.vue"

export default {
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    name: "EditorLists",
    components: { BulletListIcon, ResolveAlignIcon, EditorDropdown },
    data() {
        return {
            lists: []
        }
    },
    created() {
        this.lists = [
            {
                icon: OrderedListIcon,
                tooltip: `${this.t("builder.numbered")} ^⇑7`,
                key: "orderedList",
                command: () => this.editor.chain().focus().toggleOrderedList().run()
            },
            {
                icon: BulletListIcon,
                tooltip: `${this.t("builder.bullet")} ^⇑8`,
                key: "bulletList",
                command: () => this.editor.chain().focus().toggleBulletList().run()
            },
            {
                icon: TaskListIcon,
                tooltip: `${this.t("app-meta.title.tasks")} ^⇑9`,
                key: "taskList",
                command: () => this.editor.chain().focus().toggleTaskList().run()
            }
        ]
    },
    methods: {
        execCommand(command) {
            command()
            this.$refs.dropdown.hide()
            this.$forceUpdate()
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
