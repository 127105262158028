
<template>
    <svg width="5" height="3" viewBox="0 0 5 3" f fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 -2.18557e-07L2.5 3L5 0L0 -2.18557e-07Z" />
    </svg>
</template>
<script>
export default {
    name: "DropdownChevronIcon"
}
</script>
