class CustomQualitySelectorMenuButton {
    constructor(player, hls, levels) {
        this.player = player
        this.hls = hls
        this.levels = levels || []
        this.$el = null
        this.$elLists = null
        this.$elButtonSpan = null
        this.qualityAutoItem = {
            height: "Auto",
            index: -1,
            selected: true
        }
        this.config = {
            qualityAttrName: "quality",
            selectedClassName: "vjs-selected"
        }
        this.timestamp = new Date().getTime()
    }
    el() {
        return this.$el
    }
    createButton() {
        const span = document.createElement("span")
        span.className = "vjs-icon-placeholder  events-none"
        if (this.qualityAutoItem.index === this.hls.currentLevel) {
            span.innerHTML = this.qualityAutoItem.height
        } else {
            const level = this.levels[this.hls.currentLevel]
            const text = level.height
                ? level.height
                : level.bitrate
                ? `${Math.floor(level.bitrate / 1e3)} kbps`
                : level.height
            span.innerHTML = text
        }
        this.$elButtonSpan = span

        const button = document.createElement("button")
        button.className = "vjs-menu-button vjs-menu-button-popup vjs-custom-quality-selector-icon vjs-button"
        button.type = "button"
        button.setAttribute("aria-disabled", "false")
        button.setAttribute("aria-haspopup", "true")
        button.setAttribute("aria-expanded", "false")
        button.appendChild(span)

        button.addEventListener("click", () => {
            button.parentElement.classList.toggle("vjs-custom-quality-selector_open")
        })

        return button
    }
    createMenuItem(level, quality) {
        const span = document.createElement("span")
        span.className = "vjs-menu-item-text events-none"

        if (level.height) {
            const defaultLevels = {
                360: "low",
                480: "medium",
                720: "high",
                1080: "highest",
                Auto: "Auto"
            }

            let sizeToCheck = level.height < level.width || level.height === "Auto" ? level.height : level.width

            if (defaultLevels[sizeToCheck]) {
                span.innerHTML = sizeToCheck
            } else {
                let highestKey = null

                for (let key in defaultLevels) {
                    if (sizeToCheck > key && key > highestKey) {
                        highestKey = key
                    }
                }

                if (highestKey) {
                    span.innerHTML = defaultLevels[highestKey]
                } else {
                    span.innerHTML = "lowest"
                }
            }
        } else if (level.bitrate) span.innerHTML = `${Math.floor(level.bitrate / 1e3)} kbps`

        const menuItem = document.createElement("li")
        menuItem.className = "vjs-menu-item"
        if (quality === this.hls.currentLevel) {
            menuItem.className += " " + this.config.selectedClassName
        }
        menuItem.setAttribute(this.config.qualityAttrName, quality)
        menuItem.onclick = this.handleClick.bind(this)
        menuItem.appendChild(span)
        return menuItem
    }
    createMenu() {
        const contentMenu = document.createElement("ul")
        contentMenu.className = "vjs-menu-content"
        contentMenu.setAttribute("role", "menu")
        contentMenu.appendChild(this.createMenuItem(this.qualityAutoItem, -1))
        let cloneLevels = [...this.levels]
        cloneLevels.sort((a, b) => a.height - b.height)

        for (let i = 0; i < cloneLevels.length; i++) {
            let index = 0
            const level = cloneLevels[i]

            for (index = 0; index < cloneLevels.length; index++) {
                if (level.height === this.levels[index].height) {
                    break
                }
            }

            contentMenu.appendChild(this.createMenuItem(level, index))
        }
        this.$elLists = contentMenu
        const menu = document.createElement("div")
        menu.className = "vjs-menu"
        menu.appendChild(contentMenu)
        return menu
    }
    createEl() {
        const wrapper = document.createElement("div")
        wrapper.className = "vjs-custom-quality-selector vjs-menu-button vjs-menu-button-popup vjs-control vjs-button"
        wrapper.appendChild(this.createButton())
        wrapper.appendChild(this.createMenu())
        this.$el = wrapper
    }
    handleClick(event) {
        const selectedQuality = event.target.getAttribute(this.config.qualityAttrName)
        const items = [...this.$elLists.children]
        items.forEach(item => {
            item.classList.remove(this.config.selectedClassName)
            const quality = item.getAttribute(this.config.qualityAttrName)
            if (quality === selectedQuality) {
                item.classList.add(this.config.selectedClassName)
                this.$elButtonSpan.innerHTML = item.innerText
                this.setQuality(+quality)
            }
        })

        const qualitySelector = document.querySelector(".vjs-custom-quality-selector")

        if (qualitySelector) {
            qualitySelector.classList.toggle("vjs-custom-quality-selector_open")
        }
    }
    setQuality(quality) {
        this.timestamp = new Date().getTime()
        this.hls.nextLevel = quality
        window.qualityCacheId = this.timestamp
    }
}

export default CustomQualitySelectorMenuButton
