<template>
    <editor-dropdown :editor="editor" chevron class="l-18" ref="dropdown">
        <template #handler>
            <ResolveAlignIcon :data-tippy-content="t('builder.align')" :align="align" />
        </template>
        <template #content>
            <template v-for="(a, index) in aligns">
                <div
                    :key="index"
                    class="editor-menu-item"
                    @click="setTextAlign(a)"
                    :class="{
                        active: align === a
                    }"
                >
                    <ResolveAlignIcon class="mr-8" :align="a" />

                    {{ t(`builder.${a}`) }}
                </div>
            </template>
        </template>
    </editor-dropdown>
</template>

<script>
import EditorDropdown from "@components/Editor/components/EditorDropdown.vue"
import ResolveAlignIcon from "@components/Editor/icons/ResolveAlignIcon.vue"

export default {
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    name: "EditorTextAlign",
    components: { ResolveAlignIcon, EditorDropdown },
    data() {
        return {
            aligns: ["left", "center", "right"],
            align: "left"
        }
    },
    mounted() {
        document.addEventListener("selectionchange", () => {
            this.align = this.getCurrentTextAlign()
            this.$forceUpdate()
        })
        /* tippy(this.$el.querySelectorAll("[data-tippy-content]"), {
            appendTo: () => this.$el,
            arrow: false
        })*/
    },
    methods: {
        setTextAlign(align) {
            this.editor.chain().focus().setTextAlign(align).run()
            this.$refs.dropdown.hide()

            setTimeout(() => {
                this.align = align
                this.$forceUpdate()
            }, 50)
        },

        getCurrentTextAlign() {
            const selection = window.getSelection()
            if (selection && selection.anchorNode) {
                const anchor = window.getComputedStyle(selection.anchorNode.parentElement, null)
                let align = anchor.getPropertyValue("text-align")

                if (align === "start") {
                    return "left"
                }

                return align
            } else {
                return "left"
            }
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
