<template>
    <node-view-wrapper as="span" class="file-component">
        <FilesNewView
            @openRemoveModal="removeFile($event)"
            @save="save()"
            :files="files"
            update-preview
            parent="expert"
        />
    </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"
import FilesNewView from "@components/Files/FilesNewView.vue"
import { base64ToBytes, bytesToBase64 } from "@helpers"

export default {
    components: {
        FilesNewView,
        NodeViewWrapper
    },
    props: nodeViewProps,
    name: "FileTemplate",
    data() {
        return {
            files: []
        }
    },
    created() {
        this.files = this.getFiles()
    },
    methods: {
        removeFile(file) {
            if (this.files.length === 1) {
                this.deleteNode()
                return
            }
            const index = this.files.indexOf(file)

            this.files.splice(index, 1)
            this.save()
        },
        save() {
            this.updateAttributes({
                files: bytesToBase64(new TextEncoder().encode(JSON.stringify(this.files))),
                fileIds: JSON.stringify(this.files.map(file => file.id))
            })
        },
        getFiles() {
            const result = new TextDecoder().decode(base64ToBytes(this.node.attrs.files))

            if (result) {
                return JSON.parse(result)
            }

            return []
        }
    }
}
</script>

<style scoped lang="sass">

</style>
