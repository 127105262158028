import { Extension } from "@tiptap/core"

export const Button = Extension.create({
    name: "button",
    addOptions() {
        return {
            types: ["textStyle", "block*", "paragraph"],
            defaultMargin: "0",
            defaultAlign: "",
            defaultCursor: "",
            defaultPadding: "0",
            defaultBorderRadius: "0",
            defaultWidth: "0",
            defaultDisplay: "inline",
            defaultBackground: "#ffffff",
            defaultBackgroundColor: "#ffffff"
        }
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    background: {
                        default: this.options.defaultBackground,
                        parseHTML: element => element.style.background || this.options.defaultBackground,
                        renderHTML: attributes => {
                            if (attributes.background === this.options.defaultBackground) {
                                return {}
                            }

                            return { style: `background: ${attributes.background}` }
                        }
                    },
                    backgroundColor: {
                        default: this.options.defaultBackgroundColor,
                        parseHTML: element => element.style.backgroundColor || this.options.defaultBackgroundColor,
                        renderHTML: attributes => {
                            if (attributes.backgroundColor === this.options.defaultBackgroundColor) {
                                return {}
                            }

                            return { style: `background-color: ${attributes.backgroundColor}` }
                        }
                    },
                    cursor: {
                        default: this.options.defaultCursor,
                        parseHTML: element => element.style.cursor || this.options.defaultCursor,
                        renderHTML: attributes => {
                            if (attributes.cursor === this.options.defaultCursor) {
                                return {}
                            }

                            return { style: `cursor: ${attributes.cursor}` }
                        }
                    },
                    margin: {
                        default: this.options.defaultMargin,
                        parseHTML: element => element.style.margin || this.options.defaultMargin,
                        renderHTML: attributes => {
                            if (attributes.margin === this.options.defaultMargin) {
                                return {}
                            }

                            return { style: `margin: ${attributes.margin}` }
                        }
                    },
                    textAlign: {
                        default: this.options.defaultAlign,
                        parseHTML: element => element.style.textAlign || this.options.defaultAlign,
                        renderHTML: attributes => {
                            if (attributes.textAlign === this.options.defaultAlign) {
                                return {}
                            }

                            return { style: `text-align: ${attributes.textAlign}` }
                        }
                    },
                    width: {
                        default: this.options.defaultWidth,
                        parseHTML: element => element.style.width || this.options.defaultWidth,
                        renderHTML: attributes => {
                            if (attributes.width === this.options.defaultWidth) {
                                return {}
                            }

                            return { style: `width: ${attributes.width}` }
                        }
                    },
                    padding: {
                        default: this.options.defaultPadding,
                        parseHTML: element => element.style.padding || this.options.defaultPadding,
                        renderHTML: attributes => {
                            if (attributes.padding === this.options.defaultPadding) {
                                return {}
                            }

                            return { style: `padding: ${attributes.padding}` }
                        }
                    },
                    borderRadius: {
                        default: this.options.defaultBorderRadius,
                        parseHTML: element => element.style.borderRadius || this.options.defaultBorderRadius,
                        renderHTML: attributes => {
                            if (attributes.borderRadius === this.options.defaultBorderRadius) {
                                return {}
                            }

                            return { style: `border-radius: ${attributes.borderRadius}` }
                        }
                    },
                    display: {
                        default: this.options.defaultDisplay,
                        parseHTML: element => element.style.display || this.options.defaultDisplay,
                        renderHTML: attributes => {
                            if (attributes.display === this.options.defaultDisplay) {
                                return {}
                            }

                            return { style: `display: ${attributes.display}` }
                        }
                    }
                }
            }
        ]
    }
})
