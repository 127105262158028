<template>
    <flex-container
        :class="{ 'editor-font-style': isIpad() }"
        style="gap: 0.25rem"
        :align="isIpad() ? 'flex-start' : 'center'"
    >
        <EditorFont :editor="editor" />
        <div class="divider"></div>
        <EditorColors @hide="$refs.dropdownFont.hide()" :editor="editor" />
        <div class="divider"></div>

        <button
            @click="editor.chain().focus().toggleSuperscript().run()"
            :class="{ active: editor.isActive('superscript') }"
            class="editor-button"
        >
            <svg
                :data-tippy-content="t('builder.builder_supperscipt')"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
            >
                <path
                    d="M5.59567 5L10.5 10.9283L15.4043 5H18L11.7978 12.4971L18 19.9943V20H15.4091L10.5 14.0659L5.59092 20H3V19.9943L9.20216 12.4971L3 5H5.59567ZM21.5507 6.5803C21.7042 6.43453 21.8 6.22845 21.8 6C21.8 5.55817 21.4418 5.2 21 5.2C20.5582 5.2 20.2 5.55817 20.2 6C20.2 6.07624 20.2107 6.14999 20.2306 6.21983L19.0765 6.54958C19.0267 6.37497 19 6.1906 19 6C19 4.89543 19.8954 4 21 4C22.1046 4 23 4.89543 23 6C23 6.57273 22.7593 7.08923 22.3735 7.45384L20.7441 9H23V10H19V9L21.5507 6.5803V6.5803Z"
                ></path>
            </svg>
        </button>

        <button
            @click="editor.chain().focus().toggleSubscript().run()"
            :class="{ active: editor.isActive('subscript') }"
            class="editor-button"
        >
            <svg
                :data-tippy-content="t('builder.builder_subscipt')"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
            >
                <path
                    d="M5.59567 4L10.5 9.92831L15.4043 4H18L11.7978 11.4971L18 18.9943V19H15.4091L10.5 13.0659L5.59092 19H3V18.9943L9.20216 11.4971L3 4H5.59567ZM21.8 16C21.8 15.5582 21.4418 15.2 21 15.2C20.5582 15.2 20.2 15.5582 20.2 16C20.2 16.0762 20.2107 16.15 20.2306 16.2198L19.0765 16.5496C19.0267 16.375 19 16.1906 19 16C19 14.8954 19.8954 14 21 14C22.1046 14 23 14.8954 23 16C23 16.5727 22.7593 17.0892 22.3735 17.4538L20.7441 19H23V20H19V19L21.5507 16.5803C21.7042 16.4345 21.8 16.2284 21.8 16Z"
                ></path>
            </svg>
        </button>

        <div class="divider"></div>

        <button
            @click="
                editor.chain().focus().clearNodes().unsetAllMarks().run()
                $emit('toggle:button')
            "
            class="editor-button"
        >
            <svg
                :data-tippy-content="t('builder.clear_format')"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
            >
                <path
                    d="M12.6512 14.0654L11.6047 20H9.57389L10.9247 12.339L3.51465 4.92892L4.92886 3.51471L20.4852 19.0711L19.071 20.4853L12.6512 14.0654ZM11.7727 7.53009L12.0425 5.99999H10.2426L8.24257 3.99999H19.9999V5.99999H14.0733L13.4991 9.25652L11.7727 7.53009Z"
                ></path>
            </svg>
        </button>
    </flex-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import EditorColors from "@components/Editor/components/EditorColors.vue"
import EditorFont from "@components/Editor/components/EditorFont.vue"
import checkDevice from "../../../mixins/checkDevice"

export default {
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    name: "EditorFontStyleContent",
    components: { EditorColors, FlexContainer, EditorFont },
    mixins: [checkDevice]
}
</script>

<style scoped lang="sass">
.editor-font-style
    &::v-deep
        .divider
            margin-top: 4px !important
</style>
