<template>
    <div
        v-click-outside="handleClickOutside"
        ref="container"
        v-if="editor"
        class="editor-container"
        :class="{
            'editor-container-resizing': isResizing,
            'editor-container-no-placeholder': isCodeOpen || isButtonOpen || focused || forseShow
        }"
    >
        <SpinLoader v-if="loading" />

        <template v-if="!hideMenu">
            <BubbleMenu ref="menu" v-if="!isIpad() && !isMobile()" :editor="editor">
                <EditorMenu ref="menu" :editor="editor" />
            </BubbleMenu>

            <EditorMenu v-else-if="isIpad()" class="mt-5 mb-5" ref="menu" :editor="editor" />

            <transition name="fade">
                <div ref="bar" class="floating-mobile-container" v-if="isMobile()">
                    <EditorMenu v-show="shouldShowMobile(editor)" class="mt-5" ref="menu" :editor="editor" />
                </div>
            </transition>
        </template>

        <div id="layoutViewport"></div>

        <FloatingMenu v-if="!hideFloatingMenu" ref="floating" :editor="editor">
            <EditorFloatingMenu
                v-show="!isCodeOpen"
                @loading="loading = $event"
                @toggle:button="toggleButton"
                @forse:show="forseShow = $event"
                @toggle="
                    isFloatingMenuOpen = !isFloatingMenuOpen
                    singleSelection = false
                "
                :is-code-open="isCodeOpen"
                :is-menu-open="isFloatingMenuOpen || forseShow"
                :allow-files="allowFiles"
                :editor="editor"
            />
        </FloatingMenu>
        <EditorContent
            ref="content"
            spellcheck="false"
            dir="auto"
            :class="{
                'inline-editor': inline,
                dashed: dashed,
                disabled: loading,
                withScroll: isIpad()
            }"
            :editor="editor"
        />
        <transition name="fade-up">
            <red-alert v-if="hasExternalLinks" class="mt-10">
                <strong>
                    {{ t && t("expert.attention") }}
                </strong>
                {{ t && t("expert.attention_external_links") }}
            </red-alert>
        </transition>
        <CodeEditor
            v-if="isCodeInit"
            v-show="isCodeOpen"
            :html="html"
            @hide="
                isCodeOpen = false
                editor.commands.focus()
            "
            @input="
                editor.commands.setContent($event, false)
                $emit('input', $event)
            "
        />
        <portal v-if="!hideMenu" to="modals">
            <ButtonEditor @close="isButtonOpen = false" :editor="editor" :button="isButtonOpen" v-if="isButtonOpen" />
        </portal>
    </div>
</template>

<script>
import CustomCodeExtension from "@components/Editor/extensions/custom-code-extension"
import CustomHtmlExtension from "@components/Editor/extensions/custom-html-extension"
import CustomLinkExtension from "@components/Editor/extensions/custom-link-extension"
import { CustomTabExtension } from "@components/Editor/extensions/custom-tab-extension"
import { CodeBlock } from "@tiptap/extension-code-block"
import { Subscript } from "@tiptap/extension-subscript"
import { Superscript } from "@tiptap/extension-superscript"
import { BubbleMenu, Editor, EditorContent, FloatingMenu } from "@tiptap/vue-2"
import { Extension, isTextSelection } from "@tiptap/core"

import { EventBus, EventsList } from "~events"
import ClickOutside from "vue-click-outside"

import SpinLoader from "@components/Loaders/SpinLoader.vue"
import RedAlert from "@components/Alerts/RedAlert.vue"

import { TextStyle } from "@tiptap/extension-text-style"
import { FontFamily } from "@tiptap/extension-font-family"
import { TextAlign } from "@tiptap/extension-text-align"
import { ListItem } from "@tiptap/extension-list-item"
import { BulletList } from "@tiptap/extension-bullet-list"
import { OrderedList } from "@tiptap/extension-ordered-list"
import { Underline } from "@tiptap/extension-underline"
import { Color } from "@tiptap/extension-color"
import { Blockquote } from "@tiptap/extension-blockquote"
import { Highlight } from "@tiptap/extension-highlight"
import { Placeholder } from "@tiptap/extension-placeholder"
import { HardBreak } from "@tiptap/extension-hard-break"
import Table from "@tiptap/extension-table"
import TableCell from "@tiptap/extension-table-cell"
import TableHeader from "@tiptap/extension-table-header"
import TableRow from "@tiptap/extension-table-row"
import Gapcursor from "@tiptap/extension-gapcursor"
import TaskItem from "@tiptap/extension-task-item"
import TaskList from "@tiptap/extension-task-list"
import { Bold } from "@tiptap/extension-bold"
import { Code } from "@tiptap/extension-code"
import Document from "@tiptap/extension-document"
import { Dropcursor } from "@tiptap/extension-dropcursor"
import { Heading } from "@tiptap/extension-heading"
import { History } from "@tiptap/extension-history"
import { HorizontalRule } from "@tiptap/extension-horizontal-rule"
import { Italic } from "@tiptap/extension-italic"
import { Paragraph } from "@tiptap/extension-paragraph"
import { Strike } from "@tiptap/extension-strike"
import { Text } from "@tiptap/extension-text"

import { Button } from "@components/Editor/extensions/button-extension"
import { LineHeight } from "@components/Editor/extensions/line-height-extension"
import EmbedExtension from "@components/Editor/extensions/embed-extension"
import { Detail } from "@components/Editor/extensions/detail-extension"
import ButtonEditor from "@components/Editor/components/ButtonEditor.vue"
import { TrailingNode } from "@components/Editor/extensions/trailing-node-extension"
import EditorFloatingMenu from "@components/Editor/components/EditorFloatingMenu.vue"
import FileExtension from "@components/Editor/extensions/file-extension"
import CodeEditor from "./components/CodeEditor.vue"

import { FontSize } from "./extensions/font-size-extension"
import ResizableImage from "./extensions/resizable-image-extension"
import TooltipExtension from "@components/Editor/extensions/tooltip-extension"

import EditorMenu from "./components/EditorMenu.vue"

import checkDevice from "@mixins/checkDevice"

import "katex/dist/katex.min.css"

import Mathematics from "@tiptap-pro/extension-mathematics"

function setLink() {
    this.$refs.menu.setLink()
}

const LinkPaste = Extension.create({
    name: "customPaste",
    addKeyboardShortcuts() {
        return {
            "Mod-K": setLink
        }
    }
})

export default {
    components: {
        CodeEditor,
        SpinLoader,
        EditorFloatingMenu,
        FloatingMenu,
        ButtonEditor,
        RedAlert,
        EditorMenu,
        EditorContent,
        BubbleMenu
    },
    directives: {
        ClickOutside
    },
    props: {
        value: {
            type: String,
            default: ""
        },
        watch: {
            type: Boolean,
            default: false
        },
        hideMenu: {
            type: Boolean,
            default: false
        },
        hideFloatingMenu: {
            type: Boolean,
            default: false
        },
        hasExternalLinks: {
            type: Boolean,
            default: false
        },
        shouldShowMenu: {
            type: Boolean,
            default: true
        },
        allowFiles: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "quiz.quill_descr"
        },
        dashed: {
            type: Boolean,
            default: false
        }
    },
    name: "Editor",
    mixins: [checkDevice],
    data() {
        return {
            editor: null,
            focused: false,
            singleSelection: false,
            isFloatingMenuOpen: false,
            isColorOpen: false,
            isCodeOpen: false,
            isCodeInit: false,
            isButtonOpen: false,
            html: "",
            mouseDowned: false,
            loading: false,
            forseShow: false
        }
    },
    mounted() {
        this.html = this.value

        EventBus.$on("update:color-modal", val => {
            this.isColorOpen = val
        })

        if (this.isMobile()) {
            window.visualViewport.addEventListener("scroll", this.viewportHandler)
            window.visualViewport.addEventListener("resize", this.viewportHandler)
        }

        // eslint-disable-next-line no-func-assign
        setLink = setLink.bind(this)

        const selectionChange = ({ editor }) => {
            const { view, state, from, to } = editor
            const { doc } = state

            this.singleSelection =
                !doc.textBetween(from, to).length && isTextSelection(state.selection) && view.hasFocus()

            if (this.singleSelection) {
                //this.editor.chain().focus()
            }

            if (!view.hasFocus()) {
                // this.editor.commands.blur()
            }
        }

        this.editor = new Editor({
            content: this.value,
            editorProps: {
                handleClickOn: (view, pos, node) => {
                    if (node?.type?.name === "iframe") {
                        this.toggleCode()
                    }
                }
            },
            extensions: [
                Mathematics.configure({
                    // eslint-disable-next-line no-useless-escape
                    regex: /{{(.+?)}}/gi
                }),
                FileExtension,
                CustomTabExtension,
                CustomLinkExtension.configure({
                    validate: href => /^https?:\/\//.test(href),
                    autolink: true,
                    openOnClick: false
                }),
                Code,
                Document,
                Dropcursor,
                CodeBlock,
                TrailingNode.configure({
                    node: "paragraph",
                    notAfter: []
                }),
                TextStyle.configure({
                    HTMLAttributes: {}
                }),
                TaskList,
                TaskItem.configure({
                    nested: true
                }),
                Superscript,
                Subscript,
                HorizontalRule,
                Italic,
                FontFamily,
                CustomCodeExtension,
                CustomHtmlExtension,
                FontSize,
                Strike,
                HardBreak,
                Button,
                Heading,
                History,
                LinkPaste,
                Blockquote,
                Text,
                Paragraph,
                TextAlign.configure({
                    types: ["heading", "paragraph"],
                    alignments: ["left", "center", "right"]
                }),
                Underline,
                Color,
                TooltipExtension,
                ResizableImage.configure({
                    inline: true,
                    allowBase64: true
                }),
                Placeholder.configure({
                    emptyEditorClass: "is-editor-empty",
                    placeholder: this.$store && this.t ? this.t(this.placeholder) : ""
                }),
                Highlight.configure({
                    multicolor: true
                }),
                LineHeight,
                ListItem,
                BulletList,
                OrderedList,
                EmbedExtension,
                Detail,
                Gapcursor,
                Table.configure({
                    resizable: true
                }),
                TableRow,
                TableHeader,
                TableCell,
                Bold
            ],
            onSelectionUpdate: selectionChange,
            onFocus: ({ editor }) => {
                this.focused = true
                selectionChange({ editor })
            },
            onUpdate: this.onTextUpdate,
            onBlur: ({ editor }) => {
                selectionChange({ editor })

                this.$emit("blur", this.editor.getHTML())

                this.focused = false
                this.isFloatingMenuOpen = false
            }
        })

        this.editor.ref = this

        this.editor.ref.$on("open:button", link => {
            this.isButtonOpen = link
        })

        setTimeout(() => {
            this.editor.commands.fixTables()
        })

        this.editor.view.dom.addEventListener("mousedown", this.handleMouseDown)
    },
    methods: {
        onTextUpdate() {
            let html = this.editor.getHTML()

            this.singleSelection = false
            this.html = html

            if (html.includes("base64")) {
                return
            }

            this.$emit("input", html)
        },
        handleMouseDown() {
            this.mouseDowned = true

            document.addEventListener(
                "mouseup",
                () => {
                    this.mouseDowned = false
                    this.$forceUpdate()
                },
                { once: true }
            )
        },
        viewportHandler() {
            const bottomBar = this.$refs.bar

            if (!bottomBar) {
                return
            }

            this.$forceUpdate()

            const viewport = window.visualViewport

            const layoutViewport = document.getElementById("layoutViewport")

            if (!layoutViewport) {
                return
            }

            // Since the bar is position: fixed we need to offset it by the visual
            // viewport's offset from the layout viewport origin.
            const offsetLeft = viewport.offsetLeft
            const offsetTop = viewport.height - layoutViewport.getBoundingClientRect().height + viewport.offsetTop

            // You could also do this by setting style.left and style.top if you
            // use width: 100% instead.
            bottomBar.style.transform = `translate(${offsetLeft}px, ${offsetTop}px) scale(${1 / viewport.scale})`
        },
        handleClickOutside() {
            setTimeout(() => {
                this.$forceUpdate()
            })
            /*   setTimeout(() => {
                if (event.target) {
                    if (event.target.closest(".editor-container") || event.target.closest(".button-editor")) {
                        return
                    }
                }

                this.editor.commands.blur()

                console.log(window?.getSelection()?.getRangeAt(0)?.toString()?.length)

                /!*  if (!window?.getSelection()?.getRangeAt(0)?.toString()?.length) {
                    this.handleMouseDown()
                    console.log("otside")
                    this.editor.commands.focus()
                }*!/
            })*/
            //this.$refs.menu.$forceUpdate()
        },
        checkMobileShouldShow() {
            return this.isMobile() ? null : this.shouldFloatingShow
        },
        shouldShowMobile() {
            const { view } = this.editor

            //const { state } = view
            //const { selection } = state

            //const { ranges } = selection
            /*const from = Math.min(...ranges.map(range => range.$from.pos))
            const to = Math.max(...ranges.map(range => range.$to.pos))
*/
            //const { empty } = selection

            // Sometime check for `empty` is not enough.
            // Doubleclick an empty paragraph returns a node size of 2.
            // So we check also for an empty text size.
            //const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)

            // When clicking on a element inside the bubble menu the editor "blur" event
            // is called and the bubble menu item is focussed. In this case we should
            // consider the menu as part of the editor and keep showing the menu
            const isChildOfMenu = this.$el.contains(document.activeElement)

            const hasEditorFocus = view.hasFocus() || isChildOfMenu

            const select = window.getSelection()

            if (
                select &&
                select.type === "Range" &&
                select.anchorNode &&
                select.anchorNode.parentNode.closest(".editor-container")
            ) {
                return true
            }

            return hasEditorFocus
        },
        shouldShow({ view, state, from, to }) {
            const { doc, selection } = state
            const { empty } = selection

            const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)
            const isChildOfMenu = this.$el.contains(document.activeElement)

            const hasEditorFocus = view.hasFocus() || isChildOfMenu

            if (this.isResizing || this.isFileView || this.isCodeView || this.isHtmlView || this.isTooltipView) {
                return false
            }

            if (!hasEditorFocus || empty || isEmptyTextBlock || !this.editor.isEditable || !this.shouldShowMenu) {
                return false
            }

            return true
        },
        shouldFloatingShow({ state, view }) {
            if (this.forseShow) {
                return true
            }

            const { selection } = state

            const { $anchor, empty, ranges } = selection
            const isRootDepth = $anchor.depth === 1
            const isEmptyTextBlock =
                $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && !$anchor.parent.textContent

            if (this.isResizing || this.isFileView || this.isCodeView || this.isHtmlView || this.isTooltipView) {
                return false
            }

            const [range] = ranges

            if (range) {
                const { $from, $to } = range

                if (this.singleSelection && $from.pos === $to.pos) {
                    return true
                }
            }

            if (
                !this.focused ||
                !view.hasFocus() ||
                !empty ||
                !isRootDepth ||
                !isEmptyTextBlock ||
                !this.editor.isEditable
            ) {
                return false
            }

            return true
        },
        toggleBody(toggle = false) {
            if (toggle) {
                document.body.classList.add("padding_bottom")
            } else {
                document.body.classList.remove("padding_bottom")
            }
        },
        toggleButton() {
            this.isButtonOpen = !this.isButtonOpen
        },
        toggleCode() {
            this.isCodeInit = true
            this.isCodeOpen = !this.isCodeOpen
        },
        toggleResize() {
            this.editor.chain().focus().toggleResizable().run()
        }
    },
    computed: {
        isModalVisible() {
            return this.isButtonOpen
        },
        isResizing() {
            const name = this.editor?.state?.selection?.node?.type?.name
            return name === "ResizableImage"
        },
        isFileView() {
            return this.editor?.state?.selection?.node?.type?.name === "FileTemplate"
        },
        isTooltipView() {
            return this.editor?.state?.selection?.node?.type?.name === "TooltipTemplate"
        },
        isCodeView() {
            return this.editor?.state?.selection?.node?.type?.name === "CodeTemplate"
        },
        isHtmlView() {
            return this.editor?.state?.selection?.node?.type?.name === "HtmlTemplate"
        },
        isDraggable() {
            return this.editor?.state?.selection?.node?.attrs?.isDraggable
        }
    },
    watch: {
        isModalVisible(val) {
            EventBus.$emit(EventsList.BLUR_FULL_CONTENT, val)
        },
        isCodeOpen(val) {
            this.toggleBody(val)
        },
        value(value) {
            if (!this.watch) {
                return
            }

            const isSame = this.editor.getHTML() === value

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        }
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy()
        }
    }
}
</script>
<style lang="sass">
img.ProseMirror-separator
  display: none !important
#layoutViewport
    position: fixed
    width: 100%
    height: 100%
    visibility: hidden
.withScroll
    .ProseMirror
        max-height: calc(100vh - 200px)
        overflow: auto
        border-radius: 0
        &::-webkit-scrollbar-track
            background-color: #F7FAFD
            width: 8px
            height: 8px

        &::-webkit-scrollbar-thumb
            -webkit-border-radius: 100px
            border-radius: 100px
            background-color: #CFD7E0

        &::-webkit-resizer
            background-repeat: no-repeat
            width: 6px
            height: 0px
        &::-webkit-scrollbar
            width: 6px
            height: 6px
.inline-editor
    .ProseMirror
        padding: 0
        border: none
        min-height: 0
.Tiptap-mathematics-editor
    background: #202020
    padding: 0.2rem 0.2rem
    color: #fff
    border-radius: .25rem
    font-family: monospace
.Tiptap-mathematics-render
    border-radius: .25rem
    padding: 0.25rem
    transition: .2s
    cursor: pointer
    &:hover
        background-color: #eee
.editor-container
    &.small
        .ProseMirror
            padding: 12px 30px
            min-height: 45px
            font-size: 13px
            position: relative
    [data-tippy-root] .tippy-box[data-state='visible'] .tippy-content > div
        visibility: visible !important

    .spin-loader
        position: absolute
        z-index: 5
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
.ProseMirror
    outline: none !important
    border: 1px solid #d5d7d9

    .iframe-wrapper
        cursor: pointer
        iframe
            pointer-events: none

    span:has([data-node-view-wrapper]), span:has([data-enlargable])
        width: auto !important
        display: block !important

    &:focus-visible
        outline: none
    &:focus
        border-color: #c9deff
    pre
        background: #202020
        color: #FFF
        font-family: 'JetBrainsMono', monospace
        padding: 0.75rem 1rem
        border-radius: 0.5rem
        margin-bottom: 5px
        margin-top: 5px
.is-editor-empty:first-child::before
    transition: .2s
.floating-mobile-container
    height: 45px
    position: fixed
    z-index: 99999
    bottom: 0
    width: 100vw
    left: 0
    right: 0
    color: #ffff
    transform-origin: left bottom
    .editor-menu
        justify-content: space-around
        border-radius: 0
        width: 100%
        grid-gap: .1rem
        height: 100%
    .editor-button_quote, .editor-button_tooltip
        display: none

.editor-container-no-placeholder
    .is-editor-empty:first-child::before
        opacity: 0 !important
</style>
