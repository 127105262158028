<template>
    <p class="flex-wrap">
        <span v-if="itemLink">
            <a class="hover:text-blue" :href="itemLink" target="_blank" @click.stop="">{{ title }}</a>
        </span>
        <span v-else-if="title">{{ title }}</span>
    </p>
</template>

<script>
export default {
    name: "MarketingDataWidgetItemTitle",
    props: {
        type: {
            type: String,
            default: null
        },
        title: {
            type: [String, Object],
            required: true
        },
        id: {
            type: [Number, String],
            required: true
        },
        link: {
            type: String,
            default: null
        },
        toLinkTitle: {
            type: String,
            default: null
        }
    },
    computed: {
        itemLink() {
            if (!this.link) return null
            return this.link.includes("/id") ? this.link.replace("id", this.id) : this.link
        }
    }
}
</script>

<style lang="sass" scoped>
@import 'resources/sass/vars'

.to-link
    color: $text-accent-color
    svg
        width: 15px
        transition: $transitionTime
        transform: rotate(180deg)
        fill: $accent-color7
        &:hover
            fill: $accent-color5
    a
        color: $accent-color7
        text-decoration: underline
</style>
