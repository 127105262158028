<template>
    <div :class="{ disabled: !value.url }" class="player">
        <div class="flex justify-between items-center">
            <div class="flex items-center">
                <button
                    :class="{ 'pointer-events-none': loading }"
                    :title="isPlaying ? 'Pause' : 'PLay'"
                    class="mr-15 audio-button"
                    @click="togglePlay"
                >
                    <SpinLoader width="20" v-if="loading" color="#fff" />
                    <template v-else>
                        <PlayIcon v-if="!isPlaying" />
                        <PauseIcon v-else />
                    </template>
                </button>

                <span>{{ formattedCurrentTime }} / {{ formattedTotalTime }} </span>
            </div>

            <div v-click-outside="hideAll" class="flex items-center">
                <div class="control mr-5">
                    <button title="Backward" @click="backward" class="control-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000">
                            <path
                                d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"
                            ></path>
                        </svg>
                    </button>
                </div>

                <div class="control mr-5">
                    <button title="Forward" @click="forward" class="control-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000">
                            <path
                                d="M18.1716 6.99955H11C7.68629 6.99955 5 9.68584 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996H20V20.9996H11C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.53509L18.1716 6.99955Z"
                            ></path>
                        </svg>
                    </button>
                </div>

                <div v-if="withRemove" class="control mr-5">
                    <button title="Delete" @click="$emit('delete')" class="control-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000">
                            <path
                                d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                            ></path>
                        </svg>
                    </button>
                </div>

                <div v-if="withDownload" class="control mr-5">
                    <button title="Download" @click="downloadAudio" class="control-button">
                        <svg fill="#000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                            ></path>
                        </svg>
                    </button>
                </div>

                <div class="relative control mr-5">
                    <button title="Speed" @click="displaySpeed = !displaySpeed" class="control-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000">
                            <path
                                d="M12 13.3334L2.77735 19.4818C2.54759 19.635 2.23715 19.5729 2.08397 19.3432C2.02922 19.261 2 19.1645 2 19.0658V4.93433C2 4.65818 2.22386 4.43433 2.5 4.43433C2.59871 4.43433 2.69522 4.46355 2.77735 4.5183L12 10.6667V4.93433C12 4.65818 12.2239 4.43433 12.5 4.43433C12.5987 4.43433 12.6952 4.46355 12.7774 4.5183L23.376 11.584C23.6057 11.7372 23.6678 12.0477 23.5146 12.2774C23.478 12.3323 23.4309 12.3795 23.376 12.4161L12.7774 19.4818C12.5476 19.635 12.2372 19.5729 12.084 19.3432C12.0292 19.261 12 19.1645 12 19.0658V13.3334ZM10.3944 12.0001L4 7.7371V16.263L10.3944 12.0001ZM14 7.7371V16.263L20.3944 12.0001L14 7.7371Z"
                            ></path>
                        </svg>
                    </button>

                    <div v-if="displaySpeed" class="control-container">
                        <div class="control-container-modal">
                            <div class="range-container display-thumb">
                                <input
                                    :style="{ backgroundSize: `${((speed - 0.5) / 1.5) * 100}% 100%` }"
                                    type="range"
                                    v-model="speed"
                                    min="0.5"
                                    max="2"
                                    step="0.5"
                                    @input="changeSpeed"
                                />
                            </div>

                            <div class="flex justify-between control-counter">
                                <span>0.5</span>

                                <span>1</span>

                                <span>1.5</span>

                                <span>2</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative control">
                    <button title="Volume" @click="displayVolume = !displayVolume" class="control-button">
                        <svg
                            v-if="volume != 0"
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#000"
                        >
                            <path
                                d="M6.60282 10.0001L10 7.22056V16.7796L6.60282 14.0001H3V10.0001H6.60282ZM2 16.0001H5.88889L11.1834 20.3319C11.2727 20.405 11.3846 20.4449 11.5 20.4449C11.7761 20.4449 12 20.2211 12 19.9449V4.05519C12 3.93977 11.9601 3.8279 11.887 3.73857C11.7121 3.52485 11.3971 3.49335 11.1834 3.66821L5.88889 8.00007H2C1.44772 8.00007 1 8.44778 1 9.00007V15.0001C1 15.5524 1.44772 16.0001 2 16.0001ZM23 12C23 15.292 21.5539 18.2463 19.2622 20.2622L17.8445 18.8444C19.7758 17.1937 21 14.7398 21 12C21 9.26016 19.7758 6.80629 17.8445 5.15557L19.2622 3.73779C21.5539 5.75368 23 8.70795 23 12ZM18 12C18 10.0883 17.106 8.38548 15.7133 7.28673L14.2842 8.71584C15.3213 9.43855 16 10.64 16 12C16 13.36 15.3213 14.5614 14.2842 15.2841L15.7133 16.7132C17.106 15.6145 18 13.9116 18 12Z"
                            ></path>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000">
                            <path
                                d="M10 7.22056L6.60282 10.0001H3V14.0001H6.60282L10 16.7796V7.22056ZM5.88889 16.0001H2C1.44772 16.0001 1 15.5524 1 15.0001V9.00007C1 8.44778 1.44772 8.00007 2 8.00007H5.88889L11.1834 3.66821C11.3971 3.49335 11.7121 3.52485 11.887 3.73857C11.9601 3.8279 12 3.93977 12 4.05519V19.9449C12 20.2211 11.7761 20.4449 11.5 20.4449C11.3846 20.4449 11.2727 20.405 11.1834 20.3319L5.88889 16.0001ZM20.4142 12.0001L23.9497 15.5356L22.5355 16.9498L19 13.4143L15.4645 16.9498L14.0503 15.5356L17.5858 12.0001L14.0503 8.46454L15.4645 7.05032L19 10.5859L22.5355 7.05032L23.9497 8.46454L20.4142 12.0001Z"
                            ></path>
                        </svg>
                    </button>

                    <div v-if="displayVolume" class="control-container">
                        <div class="control-container-modal">
                            <div class="range-container display-thumb">
                                <input
                                    :style="{ backgroundSize: `${(volume * 100) / 1}% 100%` }"
                                    type="range"
                                    v-model="volume"
                                    min="0"
                                    max="1"
                                    step="0.05"
                                    @input="changeVolume"
                                />
                            </div>

                            <div class="flex justify-between control-counter">
                                <span>0</span>
                                <span>0.25</span>
                                <span>0.5</span>
                                <span>0.75</span>
                                <span>1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="range-container hide-thumb mt-8">
            <input
                :style="{ backgroundSize: `${duration ? (currentTime * 100) / duration : 0}% 100%` }"
                type="range"
                :value="currentTime"
                min="0"
                :max="duration"
                step="0.1"
                @input="seekAudio"
            />
        </div>

        <span v-if="name" class="mt-10 mb-10 block">{{ name }}</span>

        <span v-if="errors.length" class="error" v-html="errors.join(`</br>`)"></span>
    </div>
</template>

<script>
import { Howl } from "howler"
import PauseIcon from "@icons/PauseIcon.vue"
import PlayIcon from "@icons/PlayIcon.vue"
import IsOpen from "@mixins/is-open"
import axios from "~axios"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import { Debouncer } from "@helpers"

const errCodes = {
    1: `The fetching process for the media resource was aborted by the user agent at the user's request.`,
    2: `A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.`,
    3: `An error of some description occurred while decoding the media resource, after the resource was established to be usable.`,
    4: `The media resource indicated by the src attribute or assigned media provider object was not suitable.`
}

export default {
    name: "HowlerAudioPlayer",
    components: { SpinLoader, PlayIcon, PauseIcon },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        withRemove: {
            type: Boolean,
            default: false
        },
        withDownload: {
            type: Boolean,
            default: true
        },
        settings: {
            type: Object,
            default: () => ({})
        },
        infoUnitId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            sound: null,
            isPlaying: false,
            isSeeking: false,
            currentTime: 0,
            duration: 0,
            volume: 1,
            speed: 1,
            displayVolume: false,
            displaySpeed: false,
            loading: false,
            debouncer: new Debouncer(),
            errors: []
        }
    },
    mixins: [IsOpen],
    computed: {
        audioSrc() {
            return this.value.url || this.value.audioUrl
        },
        formattedCurrentTime() {
            return this.formatTime(this.currentTime)
        },
        formattedTotalTime() {
            return this.formatTime(this.duration)
        },
        name() {
            return ""
            // return this.value.original_name
        }
    },
    created() {
        if (this.value.duration) {
            this.duration = this.value.duration
        }
    },
    beforeDestroy() {
        if (this.sound) {
            this.sound.unload()
        }
    },
    methods: {
        hideAll() {
            this.displayVolume = this.displaySpeed = false
        },
        initHowler() {
            this.sound = new Howl({
                src: [this.audioSrc],
                html5: true,
                volume: this.volume,
                rate: this.speed,
                onload: () => {
                    this.duration = this.sound.duration()
                    if (this.infoUnitId) {
                        axios.post(`/info-units/${this.infoUnitId}/events/started`)
                    }
                },
                onloaderror: (id, error) => {
                    this.handleError(id, error)
                },
                onplayerror: (id, error) => {
                    this.handleError(id, error)
                },
                onend: () => {
                    this.isPlaying = false
                    this.currentTime = 0

                    if (this.infoUnitId) {
                        axios.post(`/info-units/${this.infoUnitId}/events/watched`)
                    }
                },
                onplay: () => {
                    setTimeout(() => {
                        this.loading = false
                    }, 300)
                    this.updateProgress()
                },
                onseek: () => {
                    this.updateProgress()
                }
            })

            if (this.currentTime > 0) {
                this.sound.seek(this.currentTime)
            }
        },
        togglePlay() {
            if (!this.sound) {
                this.loading = true
                this.initHowler()
            }

            if (this.sound.playing()) {
                this.sound.pause()
                this.isPlaying = false
            } else {
                this.sound.play()
                this.isPlaying = true
            }
        },
        handleError(id, error) {
            if (typeof error === "string") {
                this.errors.push(error)
            } else {
                this.errors.push(`${errCodes[error]} (Code ${error})`)
            }
            this.loading = false
        },
        updateProgress() {
            if (this.sound && this.sound.playing() && !this.isSeeking) {
                this.currentTime = this.sound.seek()
                requestAnimationFrame(this.updateProgress)
            } else {
                cancelAnimationFrame(this.updateProgress)
            }
        },
        forward() {
            if (this.sound) {
                this.isSeeking = true

                this.currentTime += 10

                this.debouncer.exec(() => {
                    if (this.sound.playing()) {
                        this.sound.pause()
                        this.sound.seek(this.currentTime)
                        this.sound.play()
                    } else {
                        this.sound.seek(this.currentTime)
                    }

                    this.isSeeking = false
                    this.updateProgress()
                }, 300)
            }
        },
        backward() {
            if (this.sound) {
                this.isSeeking = true

                this.currentTime -= 10

                if (this.currentTime < 0) {
                    this.currentTime = 0
                }

                this.debouncer.exec(() => {
                    if (this.sound.playing()) {
                        this.sound.pause()
                        this.sound.seek(this.currentTime)
                        this.sound.play()
                    } else {
                        this.sound.seek(this.currentTime)
                    }

                    this.isSeeking = false
                    this.updateProgress()
                }, 300)
            }
        },
        seekAudio(event) {
            this.currentTime = parseFloat(event.target.value)

            if (this.sound) {
                this.isSeeking = true

                this.debouncer.exec(() => {
                    if (this.sound.playing()) {
                        this.sound.pause()
                        this.sound.seek(this.currentTime)
                        this.sound.play()
                    } else {
                        this.sound.seek(this.currentTime)
                    }

                    this.isSeeking = false
                    this.updateProgress()
                }, 300)
            }
        },
        changeVolume() {
            if (this.sound) {
                this.sound.volume(this.volume)
            }
        },
        changeSpeed() {
            if (this.sound) {
                this.sound.rate(this.speed)
            }
        },
        downloadAudio() {
            const link = document.createElement("a")
            link.href = this.audioSrc
            link.target = "_blank"
            link.download = "audio.mp3"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60)
            const secs = Math.floor(seconds % 60)
            return `${minutes}:${secs < 10 ? "0" : ""}${secs}`
        }
    },
    watch: {
        displayVolume(val) {
            if (val) {
                this.displaySpeed = false
            }
        },
        displaySpeed(val) {
            if (val) {
                this.displayVolume = false
            }
        },
        "value.url"(newValue) {
            if (newValue) {
                this.$forceUpdate()
                this.$nextTick(() => {
                    this.initHowler()
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.control-counter {
    span {
        font-size: 10px;
        padding: 0 2px;
        color: #a5a5a5;
    }
}
.audio-button {
    height: 32px;
    width: 32px;
    background-color: #3965ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: #fff;
        width: 25px;
        height: 25px;
    }
}

@keyframes modal {
    0% {
        opacity: 0;
        transform: none;
    }
    100% {
        opacity: 1;
        transform: translateX(-15px);
    }
}

.error {
    font-size: 12px;
}

.control-container {
    position: absolute;
    top: -20px;
    right: 22px;
    width: 200px;
    opacity: 1;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25);
    transition: 0.2s;
    padding: 5px;
    border-radius: 4px;
    pointer-events: all;
    transform: none;
    animation: 0.2s modal forwards;
    padding-bottom: 10px;
}

.control-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    svg {
        width: 20px;
        height: 20px;
    }
    @media (max-width: 480px) {
        width: 24px;
        height: 24px;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    &:hover {
        background-color: #eee;
    }
}

.player {
    background: #ffffff;
    border: 1px solid #e0e0ec;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    column-gap: 12px;
    max-width: 380px;
    position: relative;
    align-items: center;
    box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25);
    z-index: 1;
}
span {
    font-size: 14px;
    font-family: Inter, sans-serif;
}
button {
    font-size: 24px;
    cursor: pointer;
}

.range-container {
    width: 100%;
    height: 24px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    input[type="range"] {
        cursor: pointer;
        width: 100%;
        -webkit-appearance: none;
        height: 6px;
        border-radius: 5px;
        background: #f1f1f1 linear-gradient(#3965ff, #3965ff) no-repeat;
        transition: height 0.2s;
        @media (max-width: 420px) {
            width: 100%;
        }
        &::-webkit-slider-runnable-track,
        &::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 12px;
            width: 12px;
            transform: scale(0);
            border-radius: 50%;
            background: #3965ff;
            box-shadow: 0 0 2px 0 #555;
            background: rgba(255, 255, 255, 0.6) linear-gradient(#fff, #fff) no-repeat;
            &::-webkit-slider-thumb {
                background: #fff;
                margin-left: 0 !important;
            }
        }
    }
    &.display-thumb {
        input {
            &::-webkit-slider-thumb {
                transform: scale(1);
            }
        }
    }
    &.hide-thumb {
        input {
            &::-webkit-slider-thumb {
                opacity: 0;
                visibility: hidden;
                transform: scale(0);
            }
        }
    }
    &:hover {
        input {
            &::-webkit-slider-thumb {
                transform: scale(1);
            }
        }
    }
}
</style>
