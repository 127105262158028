import CustomQualitySelectorMenuButton from "./chunks/CustomQualitySelectorMenuButton"

class HlsQualitySelector {
    constructor(player, options = {}) {
        this.player = player
        this.hls = null
        this.config = options
        this.qualityLevels = []
        this.retryCheckLevels = 0
        this.retryCheckLevelsTime = 300
    }
    setup() {
        if (
            !this.player ||
            (this.player && !this.player.tech_) ||
            (this.player && this.player.tech_ && !this.player.tech_.hlsjs)
        ) {
            throw new Error("[HlsQualitySelector] - No initialization")
        }

        if (this.player.techName_ !== "Html5") {
            return false
        }
        this.hls = this.player.tech_.hlsjs
        this.getLevels()
    }
    getLevels() {
        const levelsHls = this.hls.levels
        if (levelsHls.length) {
            this.qualityLevels = levelsHls
            this.renderHtml()
        } else if (this.retryCheckLevels !== 10) {
            setTimeout(() => {
                this.getLevels()
                this.retryCheckLevels++
            }, this.retryCheckLevelsTime)
        }
    }
    renderHtml() {
        const button = new CustomQualitySelectorMenuButton(this.player, this.hls, this.qualityLevels)
        button.createEl()
        const controlBar = this.player.controlBar,
            fullscreenToggle = controlBar.getChild("fullscreenToggle").el()
        controlBar.el().insertBefore(button.el(), fullscreenToggle)
    }
}
export default HlsQualitySelector
