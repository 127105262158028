const BUTTON = `<!-- Example Button -->
<a id="my-button" href="/"> My super button! </a>

<style>
#my-button {
    display: flex;
    margin: 0 auto;
    width: 200px;
    padding: 13px 20px;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    background: rgb(57, 101, 255);
    justify-content: center;
}
</style>
`

const CTA = `<!-- Example CTA -->
<div id="cta-wrapper">
    <p id="cta-text"> Some new text for the <strong>CTA!</strong> </p>
    <a id="cta-button" href="/"> JOIN COURSE </a>
</div>

<style>
#cta-wrapper {
    border-radius: 10px;
    padding: 20px;
    background: #d5d7d92b;
}
#cta-text {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px
}
#cta-button {
    display: flex;
    margin: 0 auto;
    width: max-content;
    padding: 13px 20px;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    background: #FF7439FF;
    justify-content: center;
}
</style>
`

const FRAME = `<iframe width="560" height="315" src="https://www.youtube.com/embed/7elZOs6jUIU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`

const P = `<p>
    Example P
</p>`

const UL = `<ul>
    <li>First</li>
    <li>Second</li>
    <li>Third</li>
</ul>`

const IMG = `<img width="468" height="auto" src='/build/img//home/cta.jpg' />`

export default [
    {
        name: "Button",
        code: BUTTON
    },
    {
        name: "Iframe",
        code: FRAME
    },
    {
        name: "P",
        code: P
    },
    {
        name: "Ul",
        code: UL
    },
    {
        name: "CTA",
        code: CTA
    },
    {
        name: "Img",
        code: IMG
    }
]
