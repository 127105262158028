<template>
    <div v-click-outside="hideAllStatements" class="conformity-mobile">
        <transition-group tag="div" class="conformity-block conformity-block_statements" name="conformity-animation">
            <div
                class="conformity-item-container"
                :key="statement.id"
                v-for="(statement, statement_index) in sortedStatements"
            >
                <template>
                    <AttemptResultStudentWidget
                        class="mr-10"
                        :attempt-id="attempt && attempt.id"
                        v-if="getCommentable(statement) && getCommentable(statement).has_comments && !expertView"
                        :commentable-id="getCommentable(statement).id"
                        :comments-count="getCommentable(statement).comments_count"
                        :commentable-type="'statement_id'"
                    />
                    <AttemptResultExpertWidget
                        class="mr-10"
                        :statement="statement"
                        v-bind="widget(statement)"
                        v-else-if="expertView"
                    />
                </template>

                <div class="relative">
                    <div
                        @click="handleStatementClick(statement)"
                        class="conformity-item"
                        :data-statement-id="statement.id"
                        :class="[
                            {
                                'pointer-events-none': !interactive,
                                disabled: dragged && dragged.type === 'statement' && statement !== dragged
                            },
                            `conformity-item_${getStatementModifier(statement_index, statement)}`
                        ]"
                    >
                        <img v-if="statement.image" :src="statement.image.url" alt="" />
                        <PracticeQuestionDescription :description="statement.text" />
                    </div>

                    <transition name="fade-up">
                        <div
                            style="transform-origin: top center"
                            @click="removeBind(getBindAnswer(statement), statement)"
                            class="conformity-item conformity-item_mobile"
                            :class="[
                                {
                                    'pointer-events-none': !interactive,
                                    disabled: dragged && dragged.type === 'statement' && statement !== dragged
                                },
                                `conformity-item_${getStatementModifier(statement_index, statement)}`
                            ]"
                            v-if="getBindAnswer(statement)"
                        >
                            <img
                                v-if="getBindAnswer(statement).image"
                                :src="getBindAnswer(statement).image.url"
                                alt=""
                            />
                            <PracticeQuestionDescription :description="getBindAnswer(statement).text" />
                        </div>
                    </transition>

                    <transition name="fade">
                        <div @click.stop.prevent class="statements-modal mt-10" v-if="statement.is_open">
                            <default-description text-color="#151515" :size="14" :weight="700" :line-height="18">
                                {{ t("quiz.answers") }}
                            </default-description>
                            <div class="custom-scroll mt-10">
                                <div
                                    :key="answer.id"
                                    v-for="answer in conformityAnswers"
                                    @click="handleAnswerClick(answer, statement)"
                                    class="statements-modal-item"
                                >
                                    <img
                                        style="max-width: 100%"
                                        v-if="answer.image"
                                        :src="answer.image.url || answer.image"
                                        alt=""
                                    />
                                    <PracticeQuestionDescription :description="answer.text" />
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition-group>
        <div v-if="interactive">
            <default-description text-color="#151515" :size="16" :weight="700" :line-height="21" class="mt-5">
                {{ t("quiz.answers") }}
            </default-description>
            <div class="answers">
                <div class="shadow answer" :key="answer.id + 'answer'" v-for="answer in conformityAnswers">
                    <img style="max-width: 75px" v-if="answer.image" :src="answer.image.url || answer.image" alt="" />
                    <PracticeQuestionDescription :description="answer.text" />
                </div>
            </div>
        </div>

        <div v-if="!interactive && typeShow === 'full_information' && !expertView">
            <p class="practice-question-title mt-20 mb-15">
                {{ t("quiz.correct_answer") }}
            </p>
            <div
                class="conformity-item-container"
                :key="statement_index + 'statement'"
                v-for="(statement, statement_index) in getCorrectStatements()"
            >
                <div class="relative">
                    <div
                        class="conformity-item conformity-item_green pointer-events-none"
                        :data-statement-id="statement.id"
                    >
                        <img v-if="statement.image" :src="statement.image.url" alt="" />
                        <PracticeQuestionDescription :description="statement.text" />
                    </div>

                    <div
                        class="conformity-item conformity-item_mobile conformity-item_green pointer-events-none"
                        v-if="getCorrectBindAnswer(statement)"
                    >
                        <img
                            v-if="getCorrectBindAnswer(statement).image"
                            :src="getCorrectBindAnswer(statement).image.url"
                            alt=""
                        />
                        <PracticeQuestionDescription :description="getCorrectBindAnswer(statement).text" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!interactive && typeShow === 'only_correct_or_not'">
            <PracticeCorrectStatusAlert
                :is-correct="isCorrect"
                :is-incorrect="isIncorrect"
                :is-skipped="isSkipped"
                :is-not-started="isNotStarted"
            />
        </div>
    </div>
</template>

<script>
import DefaultDescription from "../../../Typography/DefaultDescription"
import PracticeConformityOneToOne from "./PracticeConformityOneToOne"

export default {
    name: "PracticeConformityOneToOneMobile",
    components: { DefaultDescription },
    extends: PracticeConformityOneToOne
}
</script>

<style scoped lang="sass">
.conformity-animation-move
    transition: all 0.3s
.conformity-item
    box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
    border-radius: 7px
    background: #fff
    text-align: center
    padding: 10px
    border: 1px solid transparent
    cursor: pointer
    color: #52565C
    font-size: 13px
    line-height: 17px
    font-weight: 400
    transition: .3s
    width: 100%

    &_mobile
        position: relative

        &:after
            content: ''
            width: 1px
            height: 21px
            position: absolute
            bottom: 100%
            left: 50%
            background: #3965FF
            display: block

    &:not(&:last-child), &-container
        margin-bottom: 20px

    &_active
        background: #FBFCFF
        border-color: #3965FF

    &_red
        background: #FFF6F6
        border-color: #DD4141

        &:after
            background: #DD4141

    &_green
        background: #F9FEFC
        border-color: #15A06E

        &:after
            background: #15A06E

    &:hover
        color: #151515

    .default-description
        pointer-events: none

    img
        height: auto
        display: block
        margin: 10px auto
        max-width: 250px

.answers
    margin-top: 10px
    display: flex
    flex-wrap: wrap
    grid-gap: 15px 15px

    .answer
        padding: 20px 15px
        border: 1px solid transparent

        &:hover
            border-color: rgba(57, 101, 255, 1)

    .shadow
        box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
        border-radius: 10px
        font-size: 13px
        cursor: pointer
        line-height: 17px
        color: #151515
        border: 1px solid transparent

        &.red
            background: #FFF6F6
            border-color: #DD4141

        &.green
            background: #F9FEFC
            border-color: #15A06E
</style>
