import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import TooltipTemplate from "./TooltipTemplate.vue"

export default Node.create({
    name: "TooltipTemplate",

    group: "block",

    content: "inline*",

    addAttributes() {
        return {
            title: {
                default: ""
            },
            description: {
                default: ""
            }
        }
    },
    parseHTML() {
        return [
            {
                tag: "tooltip-component"
            }
        ]
    },

    renderHTML(value) {
        const { HTMLAttributes } = value
        return ["tooltip-component", mergeAttributes(HTMLAttributes), 0]
    },

    addCommands() {
        return {
            setTooltip:
                ({ title }) =>
                ({ chain }) => {
                    return chain()
                        .insertContent(`<tooltip-component title="${title}">${title}</tooltip-component>`)
                        .run()
                }
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(TooltipTemplate)
    }
})
