<template>
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.125 3C1.125 2.51675 1.51675 2.125 2 2.125H10C10.4832 2.125 10.875 2.51675 10.875 3C10.875 3.48325 10.4832 3.875 10 3.875H2C1.51675 3.875 1.125 3.48325 1.125 3ZM1.125 8C1.125 7.51675 1.51675 7.125 2 7.125H14C14.4832 7.125 14.875 7.51675 14.875 8C14.875 8.48325 14.4832 8.875 14 8.875H2C1.51675 8.875 1.125 8.48325 1.125 8ZM1.125 13C1.125 12.5168 1.51675 12.125 2 12.125H10C10.4832 12.125 10.875 12.5168 10.875 13C10.875 13.4832 10.4832 13.875 10 13.875H2C1.51675 13.875 1.125 13.4832 1.125 13Z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "AlignLeftIcon"
}
</script>

<style lang="sass" scoped>

</style>
