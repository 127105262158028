<template>
    <div class="editor-floating-menu">
        <div class="editor-menu">
            <label @mousedown.stop="handleLabel" class="editor-button editor-button_fill" :for="imageUuid">
                <svg :data-tippy-content="t('builder.image')" width="22" viewBox="0 0 24 24">
                    <path
                        d="M11.2703 12.2162L15 6L23 21H2L9 8L11.2703 12.2162ZM12.3897 14.2378L14.9873 19H19.6667L14.8976 10.058L12.3897 14.2378ZM5.34843 19H12.6516L9 12.2185L5.34843 19ZM5.5 8C4.11929 8 3 6.88071 3 5.5C3 4.11929 4.11929 3 5.5 3C6.88071 3 8 4.11929 8 5.5C8 6.88071 6.88071 8 5.5 8Z"
                    ></path>
                </svg>
                <input
                    :accept="'.jpeg, .jpg, .png, .webp'"
                    @cancel="$emit('forse:show', false)"
                    @change.stop="handleImageChange"
                    hidden="hidden"
                    type="file"
                    :name="imageUuid"
                    :id="imageUuid"
                />
            </label>

            <label
                @mousedown.stop="handleLabel"
                v-if="allowFiles"
                class="editor-button editor-button_fill"
                :for="fileUuid"
            >
                <FileIcon :data-tippy-content="t('create-course.file')" />
                <input
                    accept="*.*"
                    @cancel="$emit('forse:show', false)"
                    @change.stop="handleFileChange"
                    multiple
                    hidden="hidden"
                    type="file"
                    :name="fileUuid"
                    :id="fileUuid"
                />
            </label>

            <div class="divider"></div>

            <button @click="editor.commands.setHorizontalRule()" class="editor-button editor-button_fill">
                <svg :data-tippy-content="t('create-course.add_separator')" viewBox="0 0 24 24" width="20">
                    <path d="M2 11H4V13H2V11ZM6 11H18V13H6V11ZM20 11H22V13H20V11Z"></path>
                </svg>
            </button>

            <button @mousedown.prevent="editor.commands.toggleBlockquote()" class="editor-button editor-button_fill">
                <svg :data-tippy-content="t('builder.quote')" viewBox="0 0 24 24" width="20">
                    <path
                        d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                    ></path>
                </svg>
            </button>

            <div class="divider"></div>

            <button
                @mousedown.prevent="editor.commands.setCustomCode({ theme: 'vs-dark', language: 'plaintext' })"
                class="editor-button editor-button_fill"
            >
                <svg :data-tippy-content="t('builder.code_block')" width="20" viewBox="0 0 24 24">
                    <path
                        d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM4 5V19H20V5H4ZM12 15H18V17H12V15ZM8.66685 12L5.83842 9.17157L7.25264 7.75736L11.4953 12L7.25264 16.2426L5.83842 14.8284L8.66685 12Z"
                    ></path>
                </svg>
            </button>

            <button @mousedown.prevent="editor.commands.setCustomHtml()" class="editor-button editor-button_fill">
                <CodeIcon :data-tippy-content="'HTML'" />
            </button>

            <button
                @mousedown.prevent="
                    editor.chain().focus()
                    $emit('toggle:button')
                "
                :class="{ active: isCodeOpen }"
                class="editor-button editor-button_blue"
            >
                + {{ t("builder.button") }}
            </button>
        </div>
    </div>
</template>

<script>
import { getPersonalSettings } from "@common-store/personal-settings"
import FileIcon from "@components/Editor/icons/FileIcon.vue"
import CodeIcon from "@components/Editor/icons/CodeIcon.vue"
import { v4 } from "uuid"
import axios from "~axios"

export default {
    components: {
        CodeIcon,
        FileIcon
    },
    props: {
        isCodeOpen: {
            type: Boolean,
            default: false
        },
        isMenuOpen: {
            type: Boolean,
            default: false
        },
        allowFiles: {
            type: Boolean,
            default: false
        },
        editor: {
            type: Object,
            default: null
        }
    },
    name: "EditorFloatingMenu",
    data() {
        return {
            fileUuid: `file-${v4()}`,
            imageUuid: `image-${v4()}`
        }
    },
    mounted() {
        if (this.isIOS()) {
            return
        }
    },
    methods: {
        isIOS() {
            return (
                ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                    navigator.platform
                ) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            )
        },
        insertHeading(style) {
            this.editor.commands.insertContent(
                `<p style="text-align: left"><strong><span style="${style}">Text</span></strong></p>`
            )
            this.$emit("toggle")
        },
        handleLabel(ev) {
            if (ev) {
                this.$emit("forse:show", true)
            }
        },
        async handleFileChange(ev) {
            this.$emit("forse:show", false)

            const files = Array.from(ev.target.files)

            const [file] = ev.target.files

            if (file && file.type && file.type.startsWith("image/")) {
                this.handleImageChange(ev)
                return
            }

            const result = await Promise.all(files.map(this.upload))

            this.editor.commands.setFile({
                files: result,
                fileIds: result.map(file => file.id)
            })

            this.$el.querySelector(`#${this.fileUuid}`).value = null
        },
        handleImageChange(ev) {
            this.$emit("forse:show", false)

            const [file] = ev.target.files

            if (file) {
                if (file.type && file.type.startsWith("image/")) {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)

                    reader.onload = () => {
                        this.editor
                            .chain()
                            .focus()
                            .setImage({
                                allowzoom: getPersonalSettings("allowzoom") || false,
                                src: reader.result
                            })
                            .run()
                    }
                }
            }

            this.$el.querySelector(`#${this.imageUuid}`).value = null
        },

        async upload(file) {
            const formData = new FormData()

            formData.append("file", file)
            formData.set("user_id", this.$store.getters["user/currentUser"].id)
            formData.set("user_id_hash", this.$store.getters["user/currentUser"].id_hash)
            formData.set("cabinet_id", this.$store.getters["user/cabinets"]?.find(c => c.is_current).id)

            this.$emit("loading", true)

            try {
                const res = await axios.post(
                    window.file_storage_url + `?model=${this.model || "courseLesson"}`,
                    formData
                )
                return { ...res.data.data, allow_doc_download: false, allow_doc_preview: false }
            } catch (error) {
                console.log(error)
            } finally {
                this.$emit("loading", false)
            }
        }
    }
}
</script>

<style scoped lang="sass">
@import "../style/menu"
@import "../style/button"
.editor-floating-menu
    filter: drop-shadow(0 2px 5px rgba(128, 158, 191, 0.25))
    .editor-button
        border: none
        min-width: 24px
        min-height: 24px
        &_blue
            border-color: #3965FF
</style>
