//reanswer_type
export const REANSWER_TYPES = {
    NONE: 1,
    YES: 2
}

//return_to_previous_type
export const RETURN_TO_PREVIOUS = {
    NONE: 1,
    YES: 2
}

export const ANSWER_TYPES = {
    DEFAULT: 1,
    CUSTOM: 2
}

export const QUESTION_DISPLAY_TYPES = {
    SEPARATELY: 1,
    ALL: 2
}
