
<template>
    <svg width="20" height="20" viewBox="0 0 20 20" stroke="#323030" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.66797 9.16667V5.83333C6.66797 3.99238 8.16035 2.5 10.0013 2.5C11.8423 2.5 13.3346 3.99238 13.3346 5.83333V9.16667M4.16797 9.16667H15.8346V17.5H4.16797V9.16667Z"
            stroke-width="1.5"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    name: "LockIcon"
}
</script>
