<template>
    <node-view-wrapper :style="{ zIndex: opened ? 3 : 2 }" as="div" class="code-component" :class="node.attrs.theme">
        <DefaultDropdown
            with-search
            @open:dropdown="opened = true"
            @hide:dropdown="opened = false"
            @mousedown.native.stop
            @update="updateLanguage"
            class="lang-dropdown"
            :active-case="node.attrs.language"
            :style-modifier="['colored', `colored_${node.attrs.theme === 'vs' ? 'gray' : 'white'}`]"
            :cases="languages"
        />

        <DefaultDropdown
            @open:dropdown="opened = true"
            @hide:dropdown="opened = false"
            @mousedown.native.stop
            @update="updateTheme"
            class="theme-dropdown"
            :active-case="node.attrs.theme"
            :style-modifier="['colored', `colored_${node.attrs.theme === 'vs' ? 'gray' : 'white'}`]"
            :cases="themes"
        />
        <SpinLoader style="margin-top: 5px" v-if="loading" :color="`${node.attrs.theme === 'vs' ? '#000' : '#fff'}`" />
        <div :data-uuid="uuid" class="code-component-wrapper"></div>
    </node-view-wrapper>
</template>

<script>
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import { ExternalLoader } from "@helpers"
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"
import { v4 } from "uuid"
import { EventBus } from "~events"

export default {
    components: {
        SpinLoader,
        DefaultDropdown,
        NodeViewWrapper
    },
    props: nodeViewProps,
    name: "CodeTemplate",
    data() {
        return {
            model: null,
            uuid: v4(),
            languages: [],
            themes: ["vs", "vs-dark", "hc-black"],
            loading: true,
            opened: false
        }
    },
    mounted() {
        if (!window.monaco_loader && !window.monaco_promise) {
            window.monaco_promise = ExternalLoader.SCRIPT(["/build/js/monaco-loader.min.js"])
        }

        EventBus.$on("set:code-theme", theme => {
            this.updateAttribute({ theme })
        })

        window.monaco_promise.then(() => {
            this.loading = false
            this.initEditor()
        })
    },
    methods: {
        initEditor() {
            const container = document.querySelector(`[data-uuid='${this.uuid}']`)
            const width = this.$el.getBoundingClientRect().width

            if (container) {
                container.innerHTML = ""
            } else {
                return
            }

            window.monaco_loader.init().then(monaco => {
                this.monaco = monaco.editor.create(container, {
                    value: this.node.attrs.value,
                    padding: {
                        top: 55,
                        bottom: 15
                    },
                    language: this.node?.attrs?.language || "javascript",
                    theme: this.node?.attrs?.theme || "vs-dark",
                    scrollBeyondLastLine: false,
                    wordWrap: "on",
                    automaticLayout: true,
                    wrappingStrategy: "advanced",
                    minimap: {
                        enabled: false
                    },
                    overviewRulerLanes: 0
                })

                this.model = this.monaco.getModel()

                const editor = this.monaco

                if (!this.node.attrs.value) {
                    editor.focus()
                }

                monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
                    noSemanticValidation: true,
                    noSyntaxValidation: true // This line disables errors in jsx tags like <div>, etc.
                })

                this.languages = monaco.languages.getLanguages().map(l => l.id)

                this.model.onDidChangeContent(() => {
                    const value = this.monaco.getValue()
                    this.updateAttribute({ value })
                })

                // eslint-disable-next-line no-unused-vars
                let ignoreEvent = false
                const updateHeight = () => {
                    const contentHeight = Math.min(1000, editor.getContentHeight())
                    container.style.width = `${width}px`
                    container.style.height = `${contentHeight}px`
                    try {
                        ignoreEvent = true
                        editor.layout({ width, height: contentHeight })
                    } finally {
                        ignoreEvent = false
                    }
                }
                editor.onDidContentSizeChange(updateHeight)
                updateHeight()
            })
        },
        updateTheme(theme) {
            this.monaco._themeService.setTheme(theme)
            EventBus.$emit("set:code-theme", theme)
        },
        updateLanguage(language) {
            window.monaco.editor.setModelLanguage(this.model, language)
            this.updateAttribute({ language })
        },
        updateAttribute(attr) {
            this.updateAttributes(attr)
        }
    }
}
</script>

<style lang="sass">

.code-component
    border-radius: 6px
    position: relative
    z-index: 2
    margin: 20px 0
    min-height: 90px
    .bracket-match
        border-color: transparent !important
    &.vs-dark
      background-color: #1e1e1e
    &.vs
        border: 1px solid #dedede
    .dropdown-scroll
        max-height: 320px !important
    .lang-dropdown, .theme-dropdown
        position: absolute !important
        z-index: 1
        top: 7px
    .lang-dropdown
        left: 25px
    .theme-dropdown
        right: 25px
        .default-dropdown__modal
            left: auto
            right: -1px
    .monaco-editor
        border-radius: 6px
        .overflow-guard
           border-radius: 6px
</style>
