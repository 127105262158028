<template>
    <div v-if="attempt" class="practice-question-default-options">
        <practice-red-alert class="mb-10" v-if="isTimeNotLeft(question.left_time_in_seconds)">
            <strong> {{ t("course.practice.attension") }}</strong>
            {{ t("course.practice.question_1") }}
            <span>
                {{ t("course.practice.question_2") }}
            </span>
        </practice-red-alert>

        <practice-red-alert class="mb-10 big" v-if="isTimeNotLeft(question.left_time_in_seconds)">
            {{ t("course.practice.question_3") }}:

            <PracticeTimer v-if="!didUpdate" :attempt="question" only-display style="display: inline; margin: 0" />
        </practice-red-alert>

        <practice-red-alert class="mb-10" v-if="isTimeLeft(question.left_time_in_seconds)">
            <strong style="display: inline; margin: 0"> {{ t("course.practice.question_4") }} </strong>
            <u @click="$emit('next')">
                <template v-if="quiz.questions.indexOf(question) !== quiz.questions.length - 1">
                    {{ t("course.practice.question_5") }}
                </template>
                <template v-else>
                    {{ t("course.practice.finish") }}
                </template>
            </u>
        </practice-red-alert>
    </div>
</template>

<script>
import PracticeRedAlert from "../../common/alerts/PracticeRedAlert"
import PracticeTimer from "../../common/timer/PracticeTimer"
import TimerMixin from "@components/Practice/common/timer/_timer"
import axios from "~axios"
import BugIcon from "@icons/BugIcon.vue"

export default {
    props: {
        question: {
            type: Object,
            default: () => {}
        },
        quiz: {
            type: Object,
            default: () => {}
        },
        attempt: {
            type: Object,
            default: () => {}
        }
    },
    name: "PracticeQuestionDefaultOptions",
    components: { PracticeTimer, PracticeRedAlert },
    mixins: [TimerMixin],
    async created() {
        if (this.attempt && this.question.timer_duration) {
            if (!this.question.left_time_in_seconds) {
                try {
                    const result = await axios.post(
                        `/quiz-attempts/${this.attempt.id}/quiz-questions/${this.question.id}/timer`
                    )

                    this.$set(this.question, "left_time_in_seconds", result.data.left_time_in_seconds)
                } catch (e) {
                    if (this.$notify) {
                        this.$notify({
                            position: "top-right",
                            color: "danger",
                            time: 5000,
                            title: this.t("expert.something_went_wrong"),
                            text: e?.response?.data?.message || e?.response?.data?.error || e,
                            icon: BugIcon
                        })
                    }
                }
            }

            this.addQuestionInterval(this.question)
        }
    }
}
</script>
