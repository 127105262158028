<template>
    <node-view-wrapper class="html-component">
        <div class="html-component-wrapper">
            <label v-if="$screenWidth < 480" class="html-component-label">
                <CodeIcon :data-tippy-content="'HTML'" />
            </label>
            <div class="html-component-content" v-html="node.attrs.value"></div>
        </div>

        <div style="background-color: #1e1e1e; border-radius: 8px">
            <div class="code-component-head" :class="{ 'code-component-head_hidden': !node.attrs.open }">
                <flex-container align="center" class="py-5 px-20">
                    <template v-if="!!node.attrs.open">
                        <button
                            style="height: 24px"
                            @click="isOpenTemplates = !isOpenTemplates"
                            class="ready-template ready-template"
                        >
                            <DotsHorizontalIcon width="16" height="16" v-if="!isOpenTemplates" stroke="#ccc" />
                            <ArrowRightIcon
                                width="12"
                                height="10"
                                fill="#ccc"
                                v-if="isOpenTemplates"
                                style="transition: 0.2s"
                                :class="{ 'rotate-180': isOpenTemplates }"
                            />
                        </button>
                        <custom-collapse horizontal :is-open="isOpenTemplates">
                            <flex-container align="center">
                                <button
                                    @mousedown.prevent="create(template.code)"
                                    class="ready-template"
                                    v-for="(template, index) in templates"
                                    :key="index"
                                >
                                    {{ template.name }}
                                </button>
                            </flex-container>
                        </custom-collapse>
                    </template>
                </flex-container>

                <button
                    @mousedown.prevent="handleHide"
                    v-if="!node.attrs.open"
                    class="ready-template my-5 ready-template_hover"
                >
                    Show HTML
                </button>

                <button class="ready-template ready-template_hover" @mousedown.prevent="handleHide">
                    <MinusIcon width="16" height="16" />
                </button>
            </div>

            <custom-collapse :is-open="!!node.attrs.open">
                <div style="min-height: 50px" v-if="loading" class="relative">
                    <SpinLoader style="margin-top: -9px" :color="`#fff`" />
                </div>
                <div :data-uuid="uuid" class="code-component-wrapper"></div>
            </custom-collapse>
        </div>
    </node-view-wrapper>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import exampleCodeTemplates from "@components/Editor/extensions/example-code-templates"
import CodeIcon from "@components/Editor/icons/CodeIcon.vue"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import { ExternalLoader } from "@helpers"
import ArrowRightIcon from "@icons/ArrowRightIcon.vue"
import DotsHorizontalIcon from "@icons/DotsHorizontalIcon.vue"
import MinusIcon from "@icons/MinusIcon.vue"
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"
import { v4 } from "uuid"

export default {
    props: nodeViewProps,
    name: "HtmlTemplate",
    components: {
        SpinLoader,
        MinusIcon,
        DotsHorizontalIcon,
        ArrowRightIcon,
        FlexContainer,
        CustomCollapse,
        CodeIcon,
        NodeViewWrapper
    },
    data() {
        return {
            uuid: v4(),
            templates: exampleCodeTemplates,
            isOpenTemplates: false,
            loading: true
        }
    },
    mounted() {
        if (!window.monaco_loader && !window.monaco_promise) {
            window.monaco_promise = ExternalLoader.SCRIPT(["/build/js/monaco-loader.min.js"])
        }

        window.monaco_promise.then(() => {
            this.initEditor()
        })
    },
    methods: {
        handleHide() {
            this.updateAttribute({ open: !this.node.attrs.open })
        },
        initEditor() {
            const container = document.querySelector(`[data-uuid='${this.uuid}']`)
            const width = this.$el.getBoundingClientRect().width

            if (container) {
                container.innerHTML = ""
            } else {
                return
            }

            window.monaco_loader
                .init()
                .then(monaco => {
                    this.loading = false

                    this.monaco = monaco.editor.create(container, {
                        value: this.node.attrs.value,
                        padding: {
                            top: 15,
                            bottom: 15
                        },
                        language: "html",
                        theme: "vs-dark",
                        scrollBeyondLastLine: false,
                        wordWrap: "on",
                        automaticLayout: true,
                        wrappingStrategy: "advanced",
                        minimap: {
                            enabled: false
                        },
                        overviewRulerLanes: 0
                    })

                    this.model = this.monaco.getModel()

                    const editor = this.monaco

                    if (!this.node.attrs.value) {
                        editor.focus()
                    }

                    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
                        noSemanticValidation: true,
                        noSyntaxValidation: true // This line disables errors in jsx tags like <div>, etc.
                    })

                    this.languages = monaco.languages.getLanguages().map(l => l.id)

                    this.model.onDidChangeContent(() => {
                        const value = this.monaco.getValue()
                        this.updateAttribute({ value })
                    })

                    // eslint-disable-next-line no-unused-vars
                    let ignoreEvent = false
                    const updateHeight = () => {
                        const contentHeight = Math.min(1000, editor.getContentHeight())
                        container.style.width = `${width}px`
                        container.style.height = `${contentHeight}px`
                        try {
                            ignoreEvent = true
                            editor.layout({ width, height: contentHeight })
                        } finally {
                            ignoreEvent = false
                        }
                    }
                    editor.onDidContentSizeChange(updateHeight)
                    updateHeight()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        create(template) {
            this.model.setValue(template)
            this.updateAttribute({ value: template })
        },
        updateAttribute(attr) {
            this.updateAttributes(attr)
        }
    }
}
</script>

<style lang="sass">
.html-component
    margin: 10px 0
.ready-template
    background: #343434
    white-space: nowrap
    color: #fff
    font-size: 13px
    padding: 4px 10px
    border-radius: 25px
    margin-right: 10px
    cursor: pointer
    transition: .2s
    &:hover
        background: #494848
    &_hover
        background: transparent
        margin-right: 5px
        height: 24px
        padding: 0px 10px
.monaco-editor
    border-radius: 0 0 8px 8px
    .overflow-guard
        border-radius: 0 0 8px 8px
    .bracket-match
        border-color: transparent !important
.html-component
    border: 1px solid #d5d7d9
    background-color: #d5d7d92b
    border-radius: 8px

.html-component-wrapper
    padding: 20px
.html-component-label
    background-color: #212121
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    position: absolute
    top: 5px
    right: 5px
    width: 30px
    height: 30px
    border-radius: 4px
.code-component-head
    display: flex
    justify-content: space-between
    align-items: center
    background-color: #1e1e1e
    transition: .2s
    transition-delay: .0s
    &_hidden
        transition-delay: .2s
        border-radius: 0 0 8px 8px
</style>
