<template>
    <div class="practice-quiz-attempt">
        <transition name="fade">
            <modal-overlay black v-if="isShowFinishModal">
                <PracticeFinishModal
                    @cancel="isShowFinishModal = false"
                    @save="
                        quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.SEPARATELY
                            ? goNextQuestion({ prevent: false, is_force_finish: true, index: null, is_finish: true })
                            : submitAttempt(true)
                    "
                />
            </modal-overlay>
        </transition>

        <shadow-container
            :class="{ 'hide-border-right-radius': !!unitId }"
            class="practice-shadow-container"
            id="practice-main"
        >
            <!-- QUIZ NAME -->
            <PracticeQuizName :quiz="quiz" v-if="quiz.is_name_enabled" />

            <!-- QUIZ NAME -->
            <PracticeQuizAccess :quiz="quiz" />

            <!-- QUIZ PREV COMMENTS -->
            <PracticeQuizPrevComments :quiz="quiz" />

            <!-- QUIZ ESTIMATE -->
            <PracticeQuizEstimate :quiz="quiz" />

            <!-- NAVIGATION -->
            <PracticeQuizNavigation
                ref="navigation"
                @go="goQuestionFromNavigation($event)"
                :quiz="quiz"
                :is-display-all="quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.ALL"
                v-if="quiz.navigation_type_id === 1"
            />

            <!-- QUIZ TIMER -->
            <PracticeQuizTimer :quiz="quiz" />

            <!-- QUIZ TIMER -->
            <PracticeQuizStopwatch :quiz="quiz" />

            <!-- QUIZ TIME LEFT -->
            <PracticeQuizTimeLeft v-if="isAttemptTimeLeft()" />

            <div class="questions-container" v-else :class="{ 'attempt-loader': loading }">
                <LoaderBlock :green-color="true" v-if="loading" />

                <template v-if="quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.SEPARATELY">
                    <!-- QUESTION COMPONENT -->
                    <component
                        class="mt-20"
                        @prev="goPrevQuestion()"
                        @next="goNextQuestion()"
                        @check="goNextQuestion({ is_check: true })"
                        @finish="goNextQuestion({ is_finish: true })"
                        @set="setQuestion($event)"
                        @attempt="setAttempt($event)"
                        @forseRefresh="forseRefreshQuestion()"
                        @callout="onQuestionCallout(question)"
                        v-if="question && !didUpdate"
                        :question="question.viewed_result || question"
                        :results="getQuestionResults(question)"
                        :quiz="quiz"
                        :errors="errors"
                        :lesson="lesson"
                        :is-display-comment="
                            question.comment && question.comment_type_id !== 1 && question.has_result_answer
                        "
                        :attempt="quiz.attempt"
                        :course-id="lesson.course_id"
                        :question-number="quiz.questions.indexOf(question) + 1"
                        :interactive="isInteractive()"
                        :checkable="!isInteractive()"
                        :viewable="
                            quiz.reanswer_type !== REANSWER_TYPES.NONE &&
                            question.has_result_answer &&
                            !question.viewed_result
                        "
                        :readonly="isReadonly"
                        :is="getQuestionComponentBySlug(question.type.slug)"
                    ></component>

                    <PracticeActionsContainer
                        @prev="goPrevQuestion()"
                        @check="goNextQuestion({ is_check: true })"
                        @next="goNextQuestion()"
                        @finish="goNextQuestion({ is_finish: true })"
                        :question="question"
                        :interactive="isInteractive()"
                        :quiz="quiz"
                        v-if="
                            question && question.has_stopped_info && !!question.viewed_result && isDisplayQuestionResult
                        "
                    />
                </template>
                <template v-else>
                    <shadow-container
                        :class="{ disabled: loaders.disable, 'border-red': question.id === errorQuestion }"
                        :key="question.id"
                        :data-question="question.id"
                        v-for="(question, index) in quiz.questions"
                        class="mt-20"
                    >
                        <component
                            v-if="question && !didUpdate"
                            @updateActions="updateActions"
                            @callout="onQuestionCallout(question)"
                            :question="question.viewed_result || question"
                            :results="getQuestionResults(question)"
                            :quiz="quiz"
                            :errors="errors"
                            :lesson="lesson"
                            :is-display-comment="
                                question.comment && question.comment_type_id !== 1 && question.has_result_answer
                            "
                            :attempt="quiz.attempt"
                            :course-id="lesson.course_id"
                            :question-number="index + 1"
                            :viewable="true"
                            :interactive="true"
                            :is="getQuestionComponentBySlug(question.type.slug)"
                        ></component>
                    </shadow-container>

                    <PracticeActionsContainer
                        v-if="!didUpdateActions"
                        @next="submitAttempt()"
                        @check="goNextQuestion({ is_check: true })"
                        @clear="clearAttempt()"
                        display-all
                        :loaders="loaders"
                        :quiz="quiz"
                    />
                </template>
            </div>
        </shadow-container>
    </div>
</template>

<script>
import ShadowContainer from "@components/Containers/ShadowContainer"
import PracticeDescription from "@components/Practice/common/typography/PracticeDescription"
import PracticeTimerIcon from "@components/Practice/common/timer/PracticeTimerIcon"
import PracticeTitle from "@components/Practice/common/typography/PracticeTitle"
import PracticeBlueAlert from "@components/Practice/common/alerts/PracticeBlueAlert"
import PracticeRedAlert from "@components/Practice/common/alerts/PracticeRedAlert"
import PracticeTimer from "@components/Practice/common/timer/PracticeTimer"
import DefaultTitle from "@components/Typography/DefaultTitle"

import FlexContainer from "@components/Containers/FlexContainer"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import PracticeActionsContainer from "@components/Practice/PracticeQuestions/components/PracticeActionsContainer.vue"

import AttemptMixin from "@components/Practice/PracticeAttempt/practice-attempt-mixin"
import ModalOverlay from "@expert-components/Modals/ModalOverlay.vue"
import PracticeFinishModal from "./PracticeFinishModal.vue"
import PracticeQuizAccess from "./PracticeQuizAccess.vue"
import PracticeQuizName from "./PracticeQuizName.vue"
import PracticeQuizEstimate from "./PracticeQuizEstimate.vue"
import PracticeQuizNavigation from "./PracticeQuizNavigation.vue"
import PracticeQuizPrevComments from "./PracticeQuizPrevComments.vue"
import PracticeQuizStopwatch from "./PracticeQuizStopwatch.vue"
import PracticeQuizTimer from "./PracticeQuizTimer.vue"
import PracticeQuizTimeLeft from "./PracticeQuizTimeLeft.vue"

export default {
    name: "PracticeQuizAttempt",
    components: {
        PracticeQuizStopwatch,
        PracticeQuizPrevComments,
        PracticeQuizAccess,
        PracticeFinishModal,
        ModalOverlay,
        PracticeQuizNavigation,
        PracticeQuizTimeLeft,
        PracticeQuizTimer,
        PracticeQuizEstimate,
        PracticeQuizName,
        PracticeActionsContainer,
        ShadowContainer,
        LoaderBlock,
        PracticeTimer,
        PracticeRedAlert,
        DefaultTitle,
        PracticeBlueAlert,
        PracticeTitle,
        PracticeTimerIcon,
        PracticeDescription,
        FlexContainer
    },
    mixins: [AttemptMixin]
}
</script>

<style scoped lang="sass">
.practice-quiz-attempt
    .pracricle-timer-icon
        margin-right: 8px
    &::v-deep
        .text-answer
            width: max-content
            max-width: 100%
</style>
<style lang="sass">
.practice-tga-block
    .comments-create
        margin-bottom: 0 !important
    .ql-editor
        padding: 10px 0
        overflow: hidden
        line-height: 130%
        a
            color: #3965FF

.attempt-loader
    position: relative
    .practice-question
        opacity: .4
        pointer-events: none
        user-select: none
    .loader-block
        position: absolute
        transform: translate(-50%, -50%)
        top: 50%
        left: 50%
.practice-question
    position: relative
    transition: .2s
    .ql-editor > *
        cursor: inherit !important
    &__text-area-container
        flex: 1
        overflow: hidden
    &__text-area
        border: 1px solid #E0E0EC
        border-radius: 8px
        font-family: 'Lato', sans-serif
        font-size: 13px
        line-height: 17px
        font-weight: 500
        color: #151515
        padding: 14px 14px
        max-width: 100%
        &::placeholder
            color: #B5C1D2
    &__answers
        &_disabled
            opacity: .4
            user-select: none
            pointer-events: none
    &__statement
        box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
        border-radius: 7px
        padding: 10px
        img
            border-radius: 7px
        .text
            margin: 20px
            line-height: 130%
    &__answer
        padding: 8px 0
        border-radius: 5px
        &.pr-10
            padding-right: 10px !important
        .practice-radio, .practice-check
            margin-left: 10px
        &:not(&:last-child)
            margin-bottom: 4px
        &_draggable
            min-height: 55px
            display: flex
            align-items: flex-start
            padding-top: 16px
            justify-content: flex-start
            box-shadow: 0px 2px 5px rgba(128, 158, 191, 0.25)
            border-radius: 5px
            padding-left: 15px
            margin-bottom: 10px !important
            .move-handler
                margin-right: 20px
            &.colored.correct
                border: 1px solid #15A06E
                background: #F9FEFC
            &.colored.incorrect
                border: 1px solid #DD4141
                background: #FFF6F6
    &__text
        cursor: pointer
</style>
