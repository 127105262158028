import { getPersonalSettings } from "@common-store/personal-settings"
import { Extension } from "@tiptap/core"

export const fontSizes = () => [
    ...new Set(
        [
            ...new Array(45).fill(9).map((number, index) => `${number + index + 1}px`),
            ...(getPersonalSettings("custom_font_sizes") || [])
        ].sort((a, b) => {
            return parseFloat(a) - parseFloat(b)
        })
    )
]

export const FontSize = Extension.create({
    name: "fontSize",

    addOptions() {
        return {
            types: ["textStyle"],
            sizes: fontSizes()
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        parseHTML: element => element.style.fontSize,
                        renderHTML: attributes => {
                            return { style: `font-size: ${attributes.fontSize}` }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setFontSize:
                fontSize =>
                ({ chain }) => {
                    return chain().setMark("textStyle", { fontSize }).run()
                }
        }
    }
})
