<template>
    <div class="delete-modal" :style="containerStyle">
        <default-title large>
            {{ title }}
        </default-title>
        <default-description v-if="description" :size="12" :line-height="16">
            {{ description }}
        </default-description>
        <div v-if="target" class="delete-modal__target">
            <span v-if="typeof target === 'string'">
                {{ target }}
            </span>
            <FlatUser v-else :user="target" />
        </div>
        <div v-if="html" v-html="html" class="delete-modal__target"></div>
        <slot name="alert"></slot>
        <div class="delete-modal__actions">
            <DefaultButton
                type="button"
                full-width
                background="cancel"
                :text="translate('contacts.cancel')"
                @click.stop="$emit('cancel')"
            >
            </DefaultButton>

            <button type="button" class="btn2 btn2_danger" :class="{ loading }" @click.stop="$emit('delete')">
                <span v-if="!yesText">{{ translate("expert.yes_delete") }}</span>
                <span v-else>{{ yesText }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import DefaultTitle from "@components/Typography/DefaultTitle"
import DefaultDescription from "@components/Typography/DefaultDescription"
import FlatUser from "@components/User/FlatUser.vue"

export default {
    name: "DeleteModal",
    props: {
        title: {
            type: String,
            default: ""
        },
        langsKeys: {
            type: Object,
            default: () => {}
        },
        description: {
            type: String,
            default: ""
        },
        target: {
            type: [String, Object],
            default: ""
        },
        html: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        centred: {
            type: Boolean,
            default: false
        },
        yesText: {
            type: String,
            default: ""
        }
    },
    components: { FlatUser, DefaultButton, DefaultDescription, DefaultTitle },
    computed: {
        containerStyle() {
            const style = {}

            if (this.centred) {
                style.position = `fixed`
                style.zIndex = `9999999999999999`
                style.top = `50%`
                style.left = `50%`
                style.transform = `translate(-50%, -50%)`
            }

            return style
        }
    },
    methods: {
        translate(key) {
            if (this.$store) {
                return this.t(key, this.langs)
            }

            let value, group, groupKey

            for (group in this.langsKeys) {
                for (groupKey in this.langsKeys[group]) {
                    if (groupKey === key) value = this.langsKeys[group][groupKey]
                }
            }

            return value ? value : key
        }
    }
}
</script>

<style scoped lang="sass">
.delete-modal
    background-color: #fff
    box-shadow: 0 10px 40px rgba(128, 158, 191, 0.2)
    border-radius: 10px
    padding: 20px
    min-width: 300px
    max-width: 305px
    @media (max-width: 450px)
        width: auto
    &__actions
        display: flex
        margin-top: 25px
        .btn2
            width: 100%
            &.loading
                span
                    display: none
                &::after
                    background: url(@images/portal/loader-icon.svg) center no-repeat
    &__target
        margin-bottom: 10px
        box-shadow: 2px 2px 10px 0px #809EBF26
        border-radius: 5px
        padding: 13px 10px
        color: #323030
        overflow: hidden
        text-overflow: ellipsis
        span
            font-family: Inter, sans-serif
            font-size: 14px
            line-height: 22px
            font-weight: 400
    .default-title, .default-description
        margin-bottom: 20px
    .default-title
        text-align: center
    .red-alert
        background: #FFFDF5
        border-radius: 7px
        border: 1px solid #F9F1D0
        padding: 10px
        font-size: 12px
        line-height: 16px
        font-weight: 400
        width: 275px
        strong
            font-weight: 700
            color: #DD4141
</style>
