<template>
    <div :style="containerStyle()" :class="{ 'modal-container_block': block }" class="modal-container">
        <slot></slot>
    </div>
</template>

<script>
import { toPx } from "@components/Builder/widget/utils/style"
import { EventBus, EventsList } from "~events"

export default {
    props: {
        maxWidth: {
            type: Number,
            default: 0,
            required: false
        },
        maxHeight: {
            type: [Number, String],
            default: 0,
            required: false
        },
        top: {
            type: [Number, String],
            default: 0,
            required: false
        },
        bottom: {
            type: [Number, String],
            default: 0,
            required: false
        },
        left: {
            type: Number,
            default: 0,
            required: false
        },
        right: {
            type: Number,
            default: 0,
            required: false
        },
        minWidth: {
            type: [Number, String],
            default: 0,
            required: false
        },
        radius: {
            type: [Number, String],
            default: 0,
            required: false
        },
        minHeight: {
            type: Number,
            default: 0,
            required: false
        },
        centred: {
            type: Boolean,
            default: false,
            required: false
        },
        fullWidth: {
            type: Boolean,
            default: false,
            required: false
        },
        block: {
            type: Boolean,
            default: false,
            required: false
        },
        flat: {
            type: Boolean,
            default: false,
            required: false
        },
        blur: {
            type: Boolean,
            default: false,
            required: false
        },
        fixedEl: {
            type: HTMLElement,
            default: null
        },
        dark: {
            type: Boolean,
            default: false,
            required: false
        },
        padding: {
            type: Array,
            default: () => []
        }
    },
    name: "ModalContainer",
    created() {
        if (this.blur || this.dark) {
            EventBus.$on(EventsList.BLURED_CONTENT_CLICK, () => {
                this.$emit("close")
            })
        }
        if (this.fixedEl) {
            document.body.addEventListener("wheel", () => {
                this.$forceUpdate()
            })
        }
    },
    methods: {
        containerStyle() {
            const style = {}

            if (this.maxWidth) {
                style.maxWidth = `${this.maxWidth}px`
            }

            if (this.maxHeight) {
                if (typeof style.maxHeight === "number") {
                    style.maxHeight = `${this.maxHeight}px`
                } else {
                    style.maxHeight = this.maxHeight
                }
            }

            if (this.radius) {
                style.borderRadius = `${this.radius}px`
            }

            if (this.padding.length) {
                style.padding = this.padding.map(n => `${n}px`).join(" ")
            }

            if (this.centred) {
                style.position = `fixed`
                style.zIndex = `99999`
                style.top = this.$screenWidth > 780 ? `calc(50% - 100px)` : "50%"
                style.left = `50%`
                style.transform = `translate(-50%, -50%)`

                if (this.top) {
                    style.top = typeof this.top === "number" ? `${this.top}px` : this.top
                    style.transform = `translateX(-50%)`
                }

                if (this.bottom) {
                    style.bottom = typeof this.top === "number" ? `${this.bottom}px` : this.bottom
                }
            } else {
                if (this.left) {
                    style.left = this.left
                }

                if (this.top) {
                    style.top = this.top
                }

                if (this.bottom) {
                    style.bottom = this.bottom
                }

                if (this.right) {
                    style.right = this.right
                }
            }

            if (this.flat) {
                style.boxShadow = "none"
                style.padding = "0"
                style.backgroundColor = "transparent"
            }

            if (typeof this.minWidth === "number") {
                style.minWidth = `${this.minWidth}px`
            } else {
                style.minWidth = `${this.minWidth}`
            }

            if (this.$screenWidth <= this.maxWidth || this.$screenWidth <= this.minWidth) {
                style.maxWidth = "100vw"
                style.minWidth = "0px"
                style.width = "calc(100% - 10px)"
            }

            style.minHeight = `${this.minHeight}px`

            if (this.fullWidth) {
                style.inset = 0
                style.transform = "none"
                style.zIndex = 999999999999999
                style.backdropFilter = "blur(3px)"
                style.alignItems = "flex-start"
                style.paddingTop = "100px"
                style.position = `fixed`
                style.height = "100vh"
                style.width = "100vw"
            }

            if (this.fixedEl) {
                const { top, left } = this.fixedEl.getBoundingClientRect()

                style.top = toPx(top)
                style.left = toPx(left)
                style.position = `fixed`
                style.transition = `.2s`

                if (this.$el) {
                    const { height } = this.$el.getBoundingClientRect()
                    if (top + height > window.innerHeight) {
                        style.bottom = `20px`
                        style.top = "auto"
                    }
                }
            }

            return style
        }
    },
    mounted() {
        if (this.blur) {
            EventBus.$emit(EventsList.BLUR_FULL_CONTENT, true)
        }
        if (this.dark) {
            EventBus.$emit(EventsList.DARK_FULL_CONTENT, true)
        }
    },
    beforeDestroy() {
        if (this.blur) {
            EventBus.$emit(EventsList.BLUR_FULL_CONTENT, false)
        }
        if (this.dark) {
            EventBus.$emit(EventsList.DARK_FULL_CONTENT, false)
        }
    }
}
</script>

<style scoped lang="sass">
.modal-container
    padding: 30px 24px 20px 24px
    background-color: white
    border-radius: 10px
    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
    display: flex
    align-items: center
    justify-content: center
    position: relative
    &_block
        display: block
    &.fixed
        position: fixed
        z-index: 999
    &.absolute
        position: absolute
    @media (max-width: 430px)
        padding: 20px 12px 10px 12px
</style>
