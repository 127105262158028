<template>
    <div class="flat-user">
        <div
            v-if="avatarUrl"
            :style="{
                backgroundColor,
                minWidth: `${avatarSize}px`,
                width: `${avatarSize}px`,
                height: `${avatarSize}px`
            }"
            :class="{ 'flat-user__ava_large': large }"
            class="flat-user__ava"
        >
            <img :class="{ 'animal-ava': isAnimalAva }" :src="avatarUrl" alt="" class="flat-user__image" />
        </div>
        <span v-if="!large" class="inline-flex items-center">
            <default-title class="show-tooltip-on-hover" small>
                <TooltipOnHover :to-top="-15">
                    {{ user.email }}
                </TooltipOnHover>

                <a class="hover:text-blue cursor-pointer" v-if="href" target="_blank" :href="href(user)">
                    {{ user.name }}
                </a>

                <p v-else>
                    {{ user.name }}
                </p>
                <slot name="email"></slot>
            </default-title>
            <slot></slot>
        </span>
        <div v-else>
            <default-title :linked="!!href" small>
                <a v-if="href" target="_blank" :href="href(user)">
                    {{ user.name }}
                </a>
                <template v-else>
                    {{ user.name }}
                </template>
            </default-title>
            <default-title small black :font-size="12">
                {{ user.email }}
            </default-title>
        </div>
    </div>
</template>

<script>
import TooltipOnHover from "@components/Tooltips/TooltipOnHover.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import { copyToClipboard } from "@helpers"
import CopyIcon from "@icons/CopyIcon.vue"

export default {
    components: { TooltipOnHover, DefaultTitle },
    props: {
        user: {
            type: Object,
            default: null
        },
        href: {
            type: Function,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        avatarSize: {
            type: Number,
            default: 20
        }
    },
    name: "FlatUser",
    methods: {
        copyEmail() {
            copyToClipboard(this.user.email)
            this.$notify({
                position: "top-right",
                color: "success",
                time: 50000,
                width: 301,
                progress: "auto",
                title: this.t("common.copied"),
                text: this.user.email,
                icon: CopyIcon
            })
        }
    },
    computed: {
        isAnimalAva() {
            if (this.avatarUrl) {
                return this.avatarUrl.includes("/animals")
            }
            return false
        },
        backgroundColor() {
            return this.user?.bg_color || "#F0FAFF"
        },
        avatarUrl() {
            return this.user?.avatar_url || this.user?.avatar || null
        }
    }
}
</script>

<style lang="scss" scoped>
.flat-user {
    display: inline-flex;
    align-items: center;
    &__ava {
        margin-right: 8px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        &_large {
            margin-right: 14px;
        }
    }
    &__image {
        max-height: 100%;
        &.animal-ava {
            max-width: 85%;
        }
    }
}
</style>
