<template>
    <editor-dropdown :is-active="isActive()" :editor="editor" class="min-150" :chevron="true">
        <template #handler>
            <button :data-tippy-content="t('create-course.title')">
                <HeadingIcon :editor="editor" />
            </button>
        </template>
        <template #content>
            <template v-for="(size, index) in 6">
                <component
                    :is="`h${size}`"
                    :key="index"
                    class="editor-menu-item"
                    @click="editor.chain().focus().toggleHeading({ level: size }).run()"
                    :class="{
                        active: editor.isActive('heading', { level: size })
                    }"
                >
                    {{ t("create-course.title") + ` ${size}` }}
                </component>
            </template>
        </template>
    </editor-dropdown>
</template>

<script>
import EditorDropdown from "@components/Editor/components/EditorDropdown.vue"
import HeadingIcon from "@components/Editor/icons/HeadingIcon.vue"
import BoldIcon from "@icons/BoldIcon.vue"

export default {
    props: {
        editor: {
            type: Object
        }
    },
    name: "EditorHeading",
    components: { HeadingIcon, BoldIcon, EditorDropdown },
    methods: {
        isActive() {
            for (let i = 1; i <= 6; i++) {
                if (this.editor.isActive("heading", { level: i })) {
                    return true
                }
            }
            return false
        },
        getTitle() {
            for (let i = 1; i <= 6; i++) {
                if (this.editor.isActive("heading", { level: i })) {
                    return this.t("create-course.title") + ` ${i}`
                }
            }
            return this.t("builder.builder_paragraph")
        }
    }
}
</script>

<style scoped lang="sass">

</style>
