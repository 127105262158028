import ResolveWorker from "@workers/resolve-worker"
import UploadWorkerUrl from "@workers/upload-worker?worker&url"
import axios from "~axios"

export class FileTask {
    file = null
    result = null
    progress = 0
    fileName = ""
    worker = null
    status = "progress"
    errorMessage = ""
    constructor(file, createWorker = true) {
        this.file = file
        this.fileName = file.name

        if (createWorker) {
            const worker = ResolveWorker(UploadWorkerUrl)()

            worker.onmessage = event => this.onMessage(event)
            worker.onerror = event => this.onFailed(event)

            worker.postMessage({
                type: "start",
                file
            })

            this.worker = worker
        }
    }

    abort() {
        this.worker.postMessage({
            type: "abort"
        })

        if (this.onError) {
            this.status = "aborted"
            this.onError("aborted")
        }
    }

    onProgress(data) {
        this.status = "progress"
        this.progress = parseInt(data?.progress * 100)
    }

    checkStatus() {
        let checkStatus = setInterval(() => {
            const { uuid, url } = this.result
            if (!url) {
                axios.get(`/files/${uuid}`).then(response => {
                    let data = response.data.data
                    if (data.process_status === "completed" && !data.url.includes("fileservice")) {
                        this.result = {
                            ...this.result,
                            url: data.url,
                            uploaded_at: Date.now(),
                            ...data
                        }

                        if (this.onComplete) {
                            this.status = "completed"
                            this.onComplete(this.result)
                        }

                        clearInterval(checkStatus)
                    }
                })
            } else {
                if (this.onComplete) {
                    this.status = "completed"
                    this.onComplete(this.result)
                }
            }
        }, 2000)
    }

    onSuccess(data) {
        this.result = JSON.parse(data?.response)?.data

        this.status = "checking"
        this.checkStatus()

        this.progress = 100
    }

    onFailed(data) {
        this.status = "failed"
        this.message = data?.message
        this.progress = 0

        if (this.onError) {
            this.onError(data?.message)
        }
    }
    onMessage(event) {
        let data = event.data
        let { type } = data

        console.log(`data`, JSON.stringify(data))

        if (type === "progress") {
            this.onProgress(data)
        }

        if (type === "success") {
            this.onSuccess(data)
        }

        if (type === "failed") {
            this.onFailed(data)
        }
    }
}
