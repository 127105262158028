<template>
    <div class="course-public-units-wrapper">
        <template v-for="(unit, idx) in units">
            <!-- Text content           -->
            <CoursePublicUnitsText
                :id="`unit-${unit.id}`"
                :style="unitsStyle()"
                :key="'text-content-' + idx"
                v-if="unit.type_id === 3"
                :index="idx"
                :item="unit"
            />
            <!-- Files content           -->
            <div
                :id="`unit-${unit.id}`"
                v-if="unit.type_id === 4"
                :key="'files-content-' + idx"
                class="shadow-container mt-20 hide-border-right-radius"
            >
                <CoursePublicUnitsFiles :item="unit" />
            </div>

            <div :id="`unit-${unit.id}`" v-if="unit.type_id === 5" :key="'quiz-content-' + idx">
                <template v-for="(quiz, index) in unit.student_quizzes">
                    <component :key="`practice-styles` + index" is="style">
                        {{ getStyles(unit) }}
                    </component>

                    <PracticeContent
                        :id="`quizId=${quiz.id}`"
                        class="mt-20"
                        :data-practice-unit-id="unit.id"
                        v-if="isQuizAvailable(quiz)"
                        :lesson="lesson"
                        :unit-id="unit.id"
                        :key="index"
                        :quiz="quiz"
                    />
                    <QuizUnavailable
                        class="mb-20 mt-20 hide-border-right-radius"
                        :quiz="quiz"
                        :key="'quiz-content-unavailable-' + index"
                        v-else
                    />
                </template>
            </div>

            <!-- Audio content           -->
            <CoursePublicUnitsAudio
                style="margin-bottom: 20px"
                :id="`unit-${unit.id}`"
                :key="'audio-content-' + idx"
                v-if="unit.type_id === 1"
                :item="unit"
            />

            <div
                :key="'video-content-' + idx"
                :id="`unit-${unit.id}`"
                v-if="unit.type_id === 2"
                class="shadow-container hide-border-right-radius mt-20"
            >
                <!-- Video content           -->
                <CoursePublicUnitsVideo
                    :item="unit"
                    :lesson="lesson"
                    :courseAccess="courseAccess"
                    :productId="productId"
                    :isAuth="isAuth"
                    :isFreeCourse="isFreeCourse"
                    :orderUrl="orderUrl"
                    :csrfToken="csrfToken"
                    :priceCourse="priceCourse"
                    :priceConvertCourse="priceConvertCourse"
                    :courseTitle="courseTitle"
                />
            </div>

            <div
                :id="`unit-${unit.id}`"
                :key="'comments-content-' + idx"
                v-if="unit.type_id === 6 && !unit.is_comments_disabled && user"
                class="shadow-container hide-border-right-radius mt-20"
            >
                <CommentsPublic
                    parent="student"
                    no-shadow
                    commentable_type="info_units"
                    :comments-enable="true"
                    :preloaded-data="unit.preloadedData"
                    :allow-answer="true"
                    :hide-actions="true"
                    :allow-audio-response="unit.is_audio_comment_enabled"
                    :allow-video-response="unit.is_video_comment_enabled"
                    :allow-create-comment="true"
                    :lesson-id="lesson.id"
                    :is-private-selection="true"
                    :unit-id="unit.id"
                    :allow-upload-file="unit.is_file_comment_enabled"
                    :with-empty="false"
                    :allow-bulk-actions="false"
                    :auth="true"
                    :course-id="lesson.course.id"
                    :user="user"
                    :linked="true"
                    :with-bar="true"
                    :sticky-bottom="false"
                    :is-marathon="false"
                    :display-title="true"
                />
            </div>
            <div
                :id="`unit-${unit.id}`"
                class="mt-20"
                :key="'practices-content-' + idx"
                v-if="unit.type_id === 7 && lessonQuizzes && lessonQuizzes.length"
            >
                <CoursePracticeLists
                    class="hide-border-right-radius"
                    :style="{ background: unit.bg_color }"
                    :langs-keys="langsKeys"
                    :course-access="courseAccess"
                    :lesson="lesson"
                    :items="lessonQuizzes"
                    :current-lesson-id="lesson.id"
                    :target="unit.additional?.target"
                />
            </div>
        </template>
    </div>
</template>

<script>
import CoursePublicUnitsVideo from "@components/Course/units/CoursePublicUnitsVideo.vue"
import CoursePublicUnitsAudio from "@components/Course/units/CoursePublicUnitsAudio.vue"
import CommentsPublic from "@components/Comments/CommentsPublic.vue"
import LessonRepository from "@lesson/shared/repository/lesson-repository"
import axios from "@libs/axios"
import QuizUnavailable from "@components/Alerts/QuizUnavailable.vue"
import CoursePracticeLists from "@components/Course/CoursePracticeLists.vue"
import PracticeContent from "../../../../modules/Course/views/Practice/components/PracticeContent/PracticeContent.vue"
import CoursePublicUnitsFiles from "@components/Course/units/CoursePublicUnitsFiles.vue"
import CoursePublicUnitsText from "@components/Course/units/CoursePublicUnitsText.vue"

export default {
    name: "CoursePublicUnitsWrapper",
    components: {
        PracticeContent,
        CoursePracticeLists,
        QuizUnavailable,
        CommentsPublic,
        CoursePublicUnitsVideo,
        CoursePublicUnitsAudio,
        CoursePublicUnitsFiles,
        CoursePublicUnitsText
    },
    props: {
        units: {
            type: Array,
            default: () => []
        },
        lesson: {
            type: Object,
            required: false
        },
        noShadow: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            required: false
        },
        courseAccess: {
            type: Object,
            required: false
        },
        productId: {
            type: Number,
            required: false
        },
        isAuth: {
            type: Boolean,
            required: false
        },
        isFreeCourse: {
            type: Boolean,
            required: false
        },
        orderUrl: {
            type: String,
            required: false
        },
        csrfToken: {
            type: String,
            required: false
        },
        parent: {
            type: String,
            default: ""
        },
        priceCourse: {
            type: String
        },
        courseTitle: {
            type: String
        },
        priceConvertCourse: {
            type: String
        }
    },
    created() {
        this.checkEmptyContent()

        if (this.units.find(unit => unit.type_id === 6 || unit.type_id === 7)) {
            this.getTranslation()
            this.getUser()
        }
    },
    data() {
        return {
            readyContent: false,
            langsKeys: null,
            user: null
        }
    },
    mounted() {
        setTimeout(() => {
            this.readyContent = true

            setTimeout(() => {
                const hash = new URL(location).hash

                if (hash && (hash.includes("quizId") || hash.includes("unit-"))) {
                    location.replace(hash)
                }
            }, 200)
        }, 1000)
    },
    methods: {
        getStyles(unit) {
            const { bg_color, text_color, block_color_2, block_color, button_color } = unit.styles
            let str = `
                [data-practice-unit-id='${unit.id}'] .move-handler {
                      fill: ${text_color} !important;
                }
                [data-practice-unit-id='${unit.id}'] .shadow-container {
                    background: ${bg_color};
                    color: ${text_color}
                }
                [data-practice-unit-id='${unit.id}'] .default-description,
                [data-practice-unit-id='${unit.id}'] .empty,
                [data-practice-unit-id='${unit.id}'] .shadow,
                [data-practice-unit-id='${unit.id}'] .practice-question__answer,
                [data-practice-unit-id='${unit.id}'] .practice-question__statement {
                    color: ${text_color} !important;
                }

                [data-practice-unit-id='${unit.id}'] .shadow,
                [data-practice-unit-id='${unit.id}'] .practice-question__answer,
                [data-practice-unit-id='${unit.id}'] .conformity-item,
                [data-practice-unit-id='${unit.id}'] .practice-question__statement {
                    background: ${block_color !== "#FFFFFF" ? `${block_color} !important` : ""} ;
                }

                [data-practice-unit-id='${unit.id}'] .answers-container  {
                    border-color: ${text_color} !important;
                }

                [data-practice-unit-id='${unit.id}'] .statement-drop-container {
                    background: ${block_color_2} !important;
                }
            `

            if (button_color !== "#3965FF") {
                str += `
                 [data-practice-unit-id='${unit.id}'] .practice-next-action {
                      color: ${button_color} !important;
                      border-color: ${button_color} !important;
                 }
                 [data-practice-unit-id='${unit.id}'] .practice-next-action .spin-loader circle {
                      stroke: ${button_color} !important;
                 }
                  [data-practice-unit-id='${unit.id}'] .practice-next-action:hover {
                      background-color: ${button_color} !important;
                      color: #fff !important;
                 }
                 [data-practice-unit-id='${unit.id}'] .practice-next-action:hover .spin-loader circle {
                      stroke: #fff !important;
                 }
            `
            }

            if (block_color_2 !== "#FFFFFF") {
                str += `
                    [data-practice-unit-id='${unit.id}'] .blue-alert,
                    [data-practice-unit-id='${unit.id}'] .practice-blue-alert{
                        background: ${block_color_2} !important;
                        border: none !important;
                    }
                `
            }

            return str
        },
        isQuizAvailable(quiz) {
            if (quiz && quiz.last_attempt_id) {
                return true
            }

            if (quiz && quiz.attempt && quiz.attempt.id) {
                return true
            }
            return (
                this.isAuth &&
                (!quiz.blocked_info ||
                    !quiz.closed_info ||
                    (quiz.blocked_info.is_accessible && quiz.closed_info.is_accessible))
            )
        },
        async getUser() {
            if (this?.$store?.getters && this?.$store?.getters["user/currentUser"]) {
                this.user = this.$store.getters["user/currentUser"]
                return
            }
            await axios.get("/user").then(response => {
                this.user = response.data.data
            })
        },
        getTranslation() {
            if (this?.$store?.getters && this?.$store?.getters["langs/getLangs"]) {
                this.langsKeys = this.$store.getters["langs/getLangs"]
                return
            }
            axios
                .get(window.cached_routes["translation-groups"], {
                    params: {
                        groups: "course,common,create-course,payments,auth,quiz,expert,marathon",
                        lang: window.app_current_locale
                    },
                    headers: { "X-Language": window.app_current_locale }
                })
                .then(response => {
                    let langs = response.data
                    this.langsKeys = langs
                    window.langs = langs
                })
        },
        unitsStyle() {
            return {
                marginTop: this.noShadow ? "10px" : "20px",
                maxWidth: "100%"
            }
        },
        checkEmptyContent() {
            if (this.parent === "lesson" && this.courseAccess.is_course_subscribed && this.lesson.is_text_content) {
                setTimeout(() => {
                    this.eventsWatched()
                }, 1000)

                // this.eventsStarted()
            }
        },
        // eventsStarted() {
        //     fetch(`/api/v1/lessons/${this.lesson.id}/events/started`, {
        //         method: "post",
        //         headers: {
        //             Authorization: window.token ? `Bearer ${window.token}` : ""
        //         }
        //     }).then(() => {
        //         setTimeout(() => {
        //             this.eventsWatched()
        //         }, 1000)
        //     })
        // },
        eventsWatched() {
            fetch(`/api/v1/lessons/${this.lesson.id}/events/watched`, {
                method: "post",
                headers: {
                    Authorization: window.token ? `Bearer ${window.token}` : ""
                }
            })

            if (this.lesson.section_id) {
                LessonRepository.replaceContents({ section: this.lesson.section_id })
            }
        }
    },
    computed: {
        lessonQuizzes() {
            return this.lesson.quizzes.filter(quiz => !quiz.is_from_info_unit)
        }
    }
}
</script>
<style lang="sass" >
.course-public-units-wrapper
    max-width: calc(100vw - 40px)
    @media (max-width: 961px)
        max-width: calc(100vw - 60px)
    @media (max-width: 641px)
        max-width: calc(100vw - 40px)
    @media (max-width: 480px)
        max-width: calc(100vw - 20px)
    .practice-shadow-container
        &:not(&:last-child)
            margin-bottom: 10px
</style>

