import { mergeAttributes } from "@tiptap/core"
import { Link } from "@tiptap/extension-link"

export default Link.extend({
    draggable: true,
    renderHTML({ HTMLAttributes }) {
        if (HTMLAttributes.class && HTMLAttributes.class === "builder-button") {
            const elem = document.createElement("a")

            Object.entries(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)).forEach(([attr, val]) =>
                elem.setAttribute(attr, val)
            )

            elem.addEventListener("click", event => {
                if (this.editor && this.editor.ref) {
                    event.preventDefault()
                    event.stopPropagation()
                    if (event.target.tagName === "SPAN") {
                        this.editor.ref.$emit("open:button", elem.outerHTML)
                    }
                }
            })

            return elem
        }

        return ["a", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },
    addAttributes() {
        return {
            href: {
                default: null
            },
            target: {
                default: this.options.HTMLAttributes.target
            },
            rel: {
                default: this.options.HTMLAttributes.rel
            },
            class: {
                default: this.options.HTMLAttributes.class
            }
        }
    }
})
