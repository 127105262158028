<template>
    <div v-click-outside="hide" :data-uuid="uuid" class="default-dropdown" :class="resolveClass(`default-dropdown_`)">
        <SpinLoader v-if="loading && !isFlat" :width="26" color="#15A06E" :green-color="true" />
        <span v-if="title" @click="show">{{ title }}</span>
        <button
            type="button"
            v-if="!hasSlot('handler')"
            @click="show"
            class="default-dropdown__current"
            :hover-darken="activeCase && activeCase.color"
            :style="{
                background,
                borderColor,
                cursor: pointer ? 'pointer' : readonly ? 'default' : 'pointer',
                ...handlerStyle()
            }"
            :class="{
                disabled: loading && !isFlat,
                [resolveClass(`default-dropdown__current_`)]: !!styleModifier || isFlat,
                [`${resolveClass(`default-dropdown__current_`)}_visible`]: !!styleModifier && isVisible
            }"
        >
            <button
                v-if="withDelete && activeCase"
                @click.prevent.stop="handleDelete()"
                class="default-dropdown__remove"
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.3334 6.66797L6.66675 13.3347M6.66677 6.66797L13.3335 13.3347"
                        stroke="#323030"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>

            <slot v-if="isFlat" name="tooltip"></slot>

            <div
                :class="{
                    [resolveClass(`default-dropdown__current-container_`)]: !!styleModifier || isFlat
                }"
                ref="handler"
                class="default-dropdown__current-container"
                style="display: flex; align-items: center"
                :style="{ maxWidth: isFlat ? 'none' : 'calc(100% - 20px)', ...wrapperStyles }"
            >
                <slot v-if="activeCase" v-bind:item="activeCase"></slot>
                <slot v-if="!isFlat" name="tooltip"></slot>

                <p v-if="isFlat" class="mr-5 whitespace-nowrap inline">{{ label }}<em v-if="required">* </em>:</p>

                <img
                    class="default-dropdown__avatar"
                    :style="{
                        backgroundColor: activeCase.bg_color,
                        padding:
                            activeCase && activeCase.avatar_url && activeCase.avatar_url.includes('animal') ? '3px' : ''
                    }"
                    v-if="withAvatar && activeCase && activeCase.avatar_url"
                    :src="activeCase.avatar_url"
                    alt=""
                />

                <input
                    v-if="withInput"
                    :value="(activeCase && activeCase.name) || activeCase || ''"
                    style="max-width: calc(100% - 20px)"
                    :placeholder="placeholder"
                    @blur="
                        $emit(
                            'update',
                            simpleInput
                                ? $event.target.value
                                : {
                                      id: $event.target.value,
                                      name: $event.target.value
                                  }
                        )
                    "
                />
                <span v-else-if="fromCases && cases[activeCase]">
                    {{ cases[activeCase] }}
                </span>
                <span v-else-if="Array.isArray(activeCase) && activeCase.length">
                    {{ getNameFromArray() }}
                </span>

                <span v-html="activeCase.original_name" v-else-if="activeCase && activeCase.original_name"></span>
                <span v-html="activeCase.name_full" v-else-if="activeCase && activeCase.name_full"></span>
                <span v-html="activeCase.name" v-else-if="activeCase && activeCase.name"></span>
                <span v-html="activeCase.value" v-else-if="activeCase && activeCase.value"></span>
                <span v-html="strip(activeCase.title)" v-else-if="activeCase && activeCase.title && needStrip"></span>
                <span v-html="activeCase.title" v-else-if="activeCase && activeCase.title"></span>
                <span v-html="activeCase.label" v-else-if="activeCase && activeCase.label"></span>
                <span v-else-if="activeCase && activeCase.translate && t">
                    {{ t(activeCase.translate) }}
                </span>
                <span v-html="activeCase" v-else-if="(activeCase || activeCase === 0) && !Array.isArray(activeCase)">
                </span>

                <span class="default-dropdown__placeholder" v-else-if="!loading && placeholder">
                    {{ placeholder }}
                </span>

                <span class="default-dropdown__placeholder" v-if="description">
                    {{ description }}
                </span>

                <SpinLoader v-if="loading && isFlat" :width="16" />
            </div>

            <div v-if="hasSlot('icon')" class="relative">
                <div class="default-dropdown__icon">
                    <slot :style="{ transform: !isVisible ? 'none' : 'rotate(180deg)' }" name="icon"></slot>
                    <svg
                        :style="{ transform: !isVisible ? 'none' : 'rotate(180deg)' }"
                        class="ml-5"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.08398 5.83325L7.00065 8.74992L9.91732 5.83325"
                            stroke="#3E4755"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>

            <svg
                v-else-if="smallIcon"
                :style="{ transform: !isVisible ? 'none' : 'rotate(180deg)' }"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.08337 5.83334L7.00004 8.75001L9.91671 5.83334"
                    stroke="#3E4755"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            <svg
                v-else-if="!styleModifier.includes('colored')"
                :style="{ transform: !isVisible ? 'none' : 'rotate(180deg)', minWidth: 24 }"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.53033 9.46967C7.23744 9.17678 6.76256 9.17678 6.46967 9.46967C6.17678 9.76256 6.17678 10.2374 6.46967 10.5303L7.53033 9.46967ZM12 15L11.4697 15.5303C11.7626 15.8232 12.2374 15.8232 12.5303 15.5303L12 15ZM17.5303 10.5303C17.8232 10.2374 17.8232 9.76256 17.5303 9.46967C17.2374 9.17678 16.7626 9.17678 16.4697 9.46967L17.5303 10.5303ZM6.46967 10.5303L11.4697 15.5303L12.5303 14.4697L7.53033 9.46967L6.46967 10.5303ZM12.5303 15.5303L17.5303 10.5303L16.4697 9.46967L11.4697 14.4697L12.5303 15.5303Z"
                    fill="#3E4755"
                />
            </svg>
            <svg
                v-else
                :style="{ transform: isVisible ? 'none' : 'rotate(180deg)', minWidth: 24, minHeight: 24 }"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.91536 8.16667L6.9987 5.25L4.08203 8.16667"
                    stroke="#323030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </button>
        <slot name="handler"></slot>
        <transition name="fade-up">
            <div
                v-if="isVisible"
                ref="modal"
                class="default-dropdown__modal"
                :style="modalStyle()"
                :class="resolveClass(`default-dropdown__modal_`)"
            >
                <slot name="modal"></slot>
                <div
                    v-if="!hasSlot('modal')"
                    class="custom-scroll dropdown-scroll"
                    style="max-height: 280px; overflow: auto"
                >
                    <slot name="top-alert"></slot>
                    <div v-if="!multiple" style="padding-right: 8px">
                        <div v-if="withSearch" class="default-dropdown__seacrh-container">
                            <svg
                                class="search-icon"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.1363 17.197C16.4292 17.4899 16.9041 17.4899 17.197 17.197C17.4899 16.9041 17.4899 16.4292 17.197 16.1363L16.1363 17.197ZM14.25 8.75C14.25 11.7876 11.7876 14.25 8.75 14.25V15.75C12.616 15.75 15.75 12.616 15.75 8.75H14.25ZM8.75 14.25C5.71243 14.25 3.25 11.7876 3.25 8.75H1.75C1.75 12.616 4.88401 15.75 8.75 15.75V14.25ZM3.25 8.75C3.25 5.71243 5.71243 3.25 8.75 3.25V1.75C4.88401 1.75 1.75 4.88401 1.75 8.75H3.25ZM8.75 3.25C11.7876 3.25 14.25 5.71243 14.25 8.75H15.75C15.75 4.88401 12.616 1.75 8.75 1.75V3.25ZM17.197 16.1363L13.7073 12.6467L12.6467 13.7073L16.1363 17.197L17.197 16.1363Z"
                                    fill="#3E4755"
                                />
                            </svg>

                            <input
                                :placeholder="translateSearch()"
                                class="default-dropdown__seacrh"
                                v-model="search"
                                ref="search"
                                type="text"
                            />

                            <slot name="search-additional"></slot>
                        </div>

                        <template v-if="visibleCases">
                            <button
                                type="button"
                                v-for="(caseValue, caseKey, index) in visibleCases"
                                :key="index"
                                :style="itemStyle(caseKey, caseValue)"
                                class="default-dropdown__list-item"
                                :hover-darken="caseValue && caseValue.color"
                                :class="{
                                    'default-dropdown__list-item_divide': caseValue.divide,
                                    [resolveClass(`default-dropdown__list-item_`, caseValue)]:
                                        !!styleModifier || caseValue.itemBackground,
                                    'default-dropdown__list-item_active': isActive(caseValue),
                                    [`default-dropdown__list-item_active_${caseValue.itemBackground}`]:
                                        isActive(caseValue) && caseValue.itemBackground,
                                    disabled: caseValue.disabled
                                }"
                                @click="handleClick(caseKey, caseValue, index)"
                            >
                                <slot v-bind:item="caseValue"> </slot>

                                <slot v-if="slotOptions" v-bind:option="caseValue"></slot>

                                <template v-else-if="caseValue.name_full">
                                    {{ caseValue.name_full }}
                                </template>
                                <template v-else-if="caseValue.original_name">
                                    {{ caseValue.original_name }}
                                </template>
                                <template v-else-if="caseValue.name || caseValue.name === 0">
                                    {{ caseValue.name }}
                                </template>
                                <span v-html="caseValue.text" v-else-if="caseValue.text"> </span>
                                <template v-else-if="caseValue.value">
                                    {{ caseValue.value }}
                                </template>
                                <span
                                    v-html="strip(caseValue.title)"
                                    v-else-if="caseValue && caseValue.title && needStrip"
                                ></span>
                                <template v-else-if="caseValue.title">
                                    {{ caseValue.title }}
                                </template>
                                <template v-else-if="caseValue.label">
                                    {{ caseValue.label }}
                                </template>
                                <template v-else-if="translate">
                                    {{ t(translate + caseValue) }}
                                </template>
                                <template v-else> {{ caseValue }} </template>
                            </button>
                        </template>

                        <div v-else class="default-dropdown__list-item">
                            {{ emptyText || "Empty" }}
                        </div>

                        <slot name="dropdown-modal-sticky"></slot>
                    </div>
                    <div v-else style="padding-right: 8px">
                        <div
                            v-for="(caseValue, index) in cases"
                            :key="index"
                            class="default-dropdown__list-item"
                            :class="{
                                'default-dropdown__list-item_active': activeCase.find(c => c && c.id === caseValue.id)
                            }"
                            @click="$emit('update', withinCaseValue(caseValue))"
                        >
                            {{ needStrip ? strip(caseValue.name) : caseValue.name }}
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div
            v-if="label && !isFlat"
            class="default-dropdown__title"
            :style="{ ...labelStyles, background }"
            :class="[
                {
                    'default-dropdown__title_flexible': isLabelFlexible(),
                    disabled: loading
                },
                resolveClass(`default-dropdown__title_`)
            ]"
            v-html="`${label}${required ? '<em>*</em>' : ''}`"
        ></div>
    </div>
</template>

<script>
import ClickOutside from "vue-click-outside"
import SpinLoader from "../Loaders/SpinLoader"

export default {
    props: {
        activeCase: {
            required: false
        },
        pointer: {
            type: Boolean,
            default: false
        },
        cases: {
            type: [Array, Object],
            required: false
        },
        label: {
            type: [String, Boolean],
            default: ""
        },
        labelKey: {
            type: String,
            default: "title"
        },
        emptyText: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        description: {
            type: [String, Boolean],
            default: ""
        },
        borderColor: {
            type: String,
            default: "#E0E0EC"
        },
        background: {
            type: String,
            default: "#ffffff"
        },
        withMultiple: {
            type: Boolean,
            default: false
        },
        preventOnShow: {
            type: Boolean,
            default: false
        },
        preventOnHide: {
            type: Boolean,
            default: false
        },
        isFlat: {
            type: Boolean,
            default: false
        },
        fixed: {
            type: Boolean,
            default: false
        },
        needStrip: {
            type: Boolean,
            default: false
        },
        withDelete: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        withInput: {
            type: Boolean,
            default: false
        },
        simpleInput: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        withSearch: {
            type: Boolean,
            default: false
        },
        withAvatar: {
            type: Boolean,
            default: false
        },
        fromCases: {
            type: Boolean,
            default: false
        },
        smallIcon: {
            type: Boolean,
            default: false
        },
        simpleView: {
            type: Boolean,
            default: false
        },
        scrollActive: {
            type: Boolean,
            default: false
        },
        flexibleLabel: {
            type: Boolean,
            default: false
        },
        fontable: {
            type: Boolean,
            required: false,
            default: false
        },
        translate: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * @values "v_2"
         **/
        styleModifier: {
            type: [String, Array],
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
        slotOptions: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        labelStyles: {
            type: Object,
            default: () => {}
        },
        wrapperStyles: {
            type: Object,
            default: () => ({})
        },
        dropDownStyles: {
            type: Object,
            default: () => ({})
        }
    },
    name: "DefaultDropdown",
    components: {
        SpinLoader
    },
    directives: {
        ClickOutside
    },
    model: {
        prop: "activeCase",
        event: "update"
    },
    data() {
        return {
            isVisible: false,
            uuid: "",
            search: ""
        }
    },
    created() {
        this.uuid = this.uuidv4()
    },
    mounted() {
        if (this.fixed) {
            document.body.addEventListener("wheel", () => {
                this.$forceUpdate()
            })
        }
    },
    methods: {
        strip(val, fillEmpty = false) {
            let str = val.replace(/<[^>]*>?/gm, fillEmpty ? " " : "")

            if (fillEmpty) {
                str = str.replace(/  +/g, " ")
            }

            return str
        },
        modalStyle() {
            const style = {}
            if (this.fixed && this.$el) {
                let { top, left } = this.$el.getBoundingClientRect()

                if (this.styleModifier && this.styleModifier.includes("flat")) {
                    top -= 5
                }

                style.position = `fixed`
                style.transform = "translateY(-100%)"
                style.top = `${top}px !important`
                style.left = `auto !important`
                style.bottom = "auto"

                if (window.innerWidth < 640) {
                    style.left = `${left}px !important`
                }
            }

            if (this.dropDownStyles && Object.keys(this.dropDownStyles).length) {
                Object.assign(style, this.dropDownStyles)
            }

            if (this.isFlat && this.$el) {
                if (this.$refs.handler) {
                    const { left: modalLeft } = this.$refs.handler.getBoundingClientRect()
                    const { left: containerLeft } = this.$el.getBoundingClientRect()

                    if (modalLeft <= containerLeft) {
                        style.right = "auto"
                        style.left = 0
                    }
                } else {
                    this.$nextTick(() => {
                        // this.$forceUpdate()
                    })
                }
            }

            return style
        },
        resolveClass(selector, item) {
            if (item && item.itemBackground) {
                selector = `${selector}${item.itemBackground} ${selector}`
            }

            let styleModifier = this.styleModifier

            if (this.isFlat) {
                styleModifier = "flat"
            }
            if (typeof styleModifier === "string") {
                return selector + styleModifier
            } else {
                let str = ``

                styleModifier.forEach(style => {
                    str += `${selector + style} `
                })

                return str
            }
        },
        uuidv4() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
            )
        },
        translateSearch() {
            if (this.t) {
                return this.t("expert.search")
            }
            return "Search"
        },
        hasSlot(name = "default") {
            return !!this.$slots[name]
        },
        isActive(caseValue) {
            if (this.activeCase && this.activeCase.id && caseValue.id) {
                return this.activeCase.id === caseValue.id
            }

            if (!this.activeCase) {
                return false
            }

            if (typeof this.activeCase === "object") {
                return (
                    this.activeCase === caseValue ||
                    (caseValue.name && this.activeCase === caseValue.name) ||
                    (caseValue.value && this.activeCase === caseValue.value)
                )
            }

            if (typeof this.activeCase === "string" || typeof this.activeCase === "number") {
                if (this.translate) {
                    return this.activeCase === this.t(this.translate + caseValue)
                }
                return (
                    this.activeCase === caseValue ||
                    this.activeCase === caseValue.name ||
                    this.activeCase === caseValue.text ||
                    this.activeCase === caseValue.value ||
                    (this.translate && this.activeCase === this.t(this.translate + caseValue))
                )
            }

            if (caseValue.isActive) {
                return caseValue.isActive
            }
        },
        handleClick(caseKey, caseValue, index) {
            this.$emit("update", caseValue)
            this.$emit("update:index", index)
            this.$emit("update:key", caseKey)

            setTimeout(() => {
                if (!this.withMultiple && !this.preventOnHide) {
                    this.hide()
                }

                if (caseValue.onclick) {
                    caseValue.onclick()
                }

                this.search = ""
            })
        },
        handleDelete() {
            this.$emit("delete")
            this.hide()
        },
        isLabelFlexible() {
            if (!this.flexibleLabel) {
                return false
            } else if (this.multiple && this.activeCase.length) {
                return false
            } else if (this.activeCase || this.activeCase === 0) {
                return false
            }
            return true
        },
        withinCaseValue(caseValue) {
            const current = this.activeCase.find(c => c.id === caseValue.id)
            if (current) {
                this.activeCase.splice(this.activeCase.indexOf(current), 1)
            } else {
                this.activeCase.push(caseValue)
            }
            return this.activeCase
        },
        handlerStyle() {
            if (this.$parent && this.$parent.getStyles) {
                return this.$parent.getStyles(this.activeCase)
            }
        },
        itemStyle(key, value) {
            if (this.$parent && this.$parent.getStyles) {
                return this.$parent.getStyles(value, true)
            }
            if (this.fontable) {
                return {
                    fontFamily: key
                }
            } else {
                return {}
            }
        },
        getNameFromArray() {
            if (this.simpleView) {
                return `${this.t("common.selected")}: ${this.activeCase.length}`
            }
            return this.activeCase
                .reduce((accumulator, current) => {
                    accumulator.push(this.needStrip ? this.strip(current.name) : current.name)

                    return accumulator
                }, [])
                .join(", ")
        },
        show(event) {
            if (this.preventOnShow) {
                this.$emit("show")
                return
            }
            if (event && event.target && event.target.closest(".alert-with-question")) {
                return
            }
            if (this.readonly) {
                return
            }
            this.$nextTick(() => {
                this.isVisible = !this.isVisible
                if (this.withSearch) {
                    setTimeout(() => {
                        if (this.$refs.search) {
                            this.$refs.search.focus()
                        }
                    })
                }
                if (this.scrollActive) {
                    setTimeout(() => {
                        const selector = this.$el.querySelector(".default-dropdown__list-item_active")

                        if (selector) {
                            this.$el.querySelector(".custom-scroll").scrollTop = selector.offsetTop - 10
                        }
                    })
                }
            })
        },
        hide(event) {
            if (
                event &&
                event.target &&
                (event.target.closest(`[data-uuid="${this.uuid}"]`) ||
                    event.target.closest(`.creation-data-modal-container`) ||
                    event.target.closest(`.months`))
            ) {
                return
            }
            this.isVisible = false
            this.$emit("hide")
        }
    },
    computed: {
        visibleCases() {
            const searchValue = this.search && this.search.toLowerCase()
            if (this.withSearch && this.search && Array.isArray(this.cases)) {
                return this.cases.filter(
                    caseValue =>
                        (caseValue.name &&
                            typeof caseValue.name === "string" &&
                            caseValue.name.toLowerCase().includes(searchValue)) ||
                        (caseValue.value &&
                            typeof caseValue.value === "string" &&
                            caseValue.value.toLowerCase().includes(searchValue)) ||
                        (caseValue.email &&
                            typeof caseValue.email === "string" &&
                            caseValue.email.toLowerCase().includes(searchValue)) ||
                        (caseValue.original_name &&
                            typeof caseValue.original_name === "string" &&
                            caseValue.original_name.toLowerCase().includes(searchValue)) ||
                        (typeof caseValue === "string" && caseValue.toLowerCase().includes(searchValue))
                )
            }

            if (Array.isArray(this.cases) && !this.cases.length) {
                return null
            } else if (typeof this.cases === "object" && Object.keys(this.cases).length === 0) {
                return null
            }

            return this.cases
        }
    },
    watch: {
        isVisible(val) {
            this.$emit(val ? "open:dropdown" : "hide:dropdown")
        }
    }
}
</script>
<style lang="sass">
.dropdown-modal-sticky
    position: sticky
    height: 45px
    bottom: 0
    background: #fff
    padding: 0 13px
    display: flex
    align-items: center
    font-size: 13px
    font-weight: 400
    border-top: 1px solid #eee
.default-dropdown
    &__list-item
        min-height: 40px
        height: auto
        width: 100%
        display: flex
        text-align: left
        cursor: pointer
        font-family: Lato, sans-serif
        align-items: center
        padding: 5px 14px
        font-size: 13px
        font-weight: 400
        line-height: 17px
        border-radius: 5px
        transition: .3s
        &:last-child
            margin-bottom: 0 !important
        &:hover, &_active
            background: #F2F7FF
        &_red, &_orange, &_green, &_blue
            margin-bottom: 10px !important
            font-family: Inter, sans-serif !important
            min-height: 32px !important
            &:last-child
                margin-bottom: 0 !important
        &_blue
            background: #3965FF1A
            color: #3965FF
            &:hover
                background: #3965FF33 !important
        &_red
            background: hsla(0, 70%, 56%, 0.1)
            color: hsla(0, 70%, 56%, 1)
            &:hover
                background: hsla(0, 70%, 56%, 0.2) !important
        &_active_red
            background: hsla(0, 70%, 56%, 0.2) !important

        &_green
            background: hsla(158, 77%, 35%, 0.1)
            color: hsla(158, 77%, 35%, 1)
            &:hover
                background: hsla(158, 77%, 35%, 0.2) !important
        &_active_green
            background: hsla(158, 77%, 35%, 0.2) !important
        &_orange
            background: hsla(18, 100%, 61%, 0.1)
            color: hsla(18, 100%, 61%, 1)
            &:hover
                background: hsla(18, 100%, 61%, 0.2) !important
        &_active_orange
            background: hsla(18, 100%, 61%, 0.2) !important
        &:not(&:last-child)
            margin-bottom: 2px
</style>
<style lang="sass" scoped>
.default-dropdown
    position: relative
    min-width: 120px
    color: #000
    &_flat
        max-width: max-content
        &::v-deep
            .alert-with-question
                margin-top: 1px
    &__avatar
        width: 30px
        height: 30px
        border-radius: 50%
        margin-right: 8px
        transform: translateY(10px)
    &_tooltip
        .default-dropdown__title
            left: 30px !important
    &_pointer
        width: 100%
        max-width: 350px
    &::v-deep
        em
            color: #DD4141
    &__remove
        margin-left: auto
        display: flex
        align-items: center
        justify-content: center
        position: relative
        z-index: 2
        &:hover
            path
                stroke:  #DD4141
    &__seacrh
        height: 45px
        width: 100%
        border: 1px solid hsla(240, 24%, 90%, 1)
        outline: none
        border-radius: 7px
        font-size: 14px
        font-weight: 400
        font-family: Inter, sans-serif
        text-indent: 48px
    &__seacrh-container
        margin-bottom: 10px
        position: sticky
        top: 0
        background-color: #fff
        z-index: 2
        display: flex
        align-items: center
        .search-icon
            position: absolute
            left: 14px
            top: 13px
    &_colored, &_popup
        min-width: 0
    &_popup
        .default-dropdown__title
            left: 28px
    &::v-deep
        .spin-loader
            position: absolute
            z-index: 2
            padding: 10px 0
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
    &::after
        content: attr(data-error)
        position: absolute
        bottom: -20px
        white-space: nowrap
        font-size: 12px
        left: 0
        line-height: 12px
        color: #DD4141
    &__placeholder
        transition: .2s
        color: #B5C1D2
    &::v-deep
        .simplebar-vertical
            max-width: 4px
    svg
        transition: .1s
    &__icon
        display: flex
        position: absolute
        height: 45px
        border-left: 1px solid rgb(224, 224, 236)
        transform: translateY(-50%)
        width: 60px
        align-items: center
        right: -15px
        padding-left: 15px
    &__title
        color: #B5C1D2
        font-size: 10px
        line-height: 130%
        font-weight: 600
        padding: 0 4px
        background: #fff
        position: absolute
        top: -5px
        pointer-events: none
        left: 27px
        transition: .2s
        &.small-indent, &_small-indent
            left: 10px !important
        &_flexible
            font-size: 13px
            font-weight: 500
            top: 15px
    &__current-container
        &_flat
            color: #323030
            display: inline !important
            line-height: 160%
            span:not(.default-dropdown__placeholder)
                color: #3965FF
            .default-dropdown__placeholder
                color: #3965FF
            .spin-loader
                position: relative
                left: 2px
                padding: 0
                display: inline-flex
                top: 3px
                transform: none
    &__current
        text-align: left
        font-family: Lato, sans-serif
        position: relative
        border: 1px solid
        width: 100%
        background-color: #fff
        border-radius: 8px
        height: 45px
        padding-left: 32px
        font-size: 13px
        font-weight: 500
        max-width: 100%
        display: flex
        align-items: center
        color: #52565C
        line-height: 17px
        justify-content: space-between
        cursor: pointer
        padding-right: 15px
        transition: .2s
        &.small-indent,  &_small-indent
            padding-left: 14px
            padding-right: 8px
        //&:focus
        //  border-color: #97AEFF !important
        &_flat
            height: initial
            padding: 0
            border: 0
            font-size: 14px
            align-items: flex-start
            font-weight: 400
            font-family: Inter, sans-serif
            span
                display: inline
            svg
                display: none
            .additional-icon
                display: block
                margin-right: 8px
        &_arrow
            svg
                display: flex
            &:hover
                svg
                    path
                        fill: #3965FF
        &_new
            padding-left: 15px
            font-family: Inter, sans-serif
        &_tooltip
            padding-left: 8px
        &_pointer
            justify-content: flex-end
            flex-direction: row-reverse
            height: 38px
            padding-left: 10px
            padding-right: 4px
            width: 100%
            max-width: 350px
            &_visible, &:hover
                color: #3965FF
                .default-dropdown__placeholder
                    color: #3965FF
                    border-color: #3965FF !important
            svg
                margin-right: 5px
        &.error
            border-color: #DD4141
            margin-bottom: 15px
        &_capitalize
            text-transform: capitalize
        &_colored
            height: 28px
            padding-left: 14px
            padding-right: 14px
            font-weight: 600
            border-radius: 5px
            width: max-content
            border: none
            &_white
                background-color: rgb(255 255 255 / 10%) !important
                color: #fff !important
                &:hover
                    background-color: rgb(255 255 255 / 20%) !important
                path
                    fill: #9f9f9f
            &_green
                background-color: #15A06E1A !important
                color: #15A06E !important
            &_yellow
                background: #FFFDF5 !important
                color: #52565C !important
            &_red
                background-color: rgba(221, 65, 65, 0.07) !important
                color: #dd4141c2 !important
            &_gray
                background-color: #52565C1A !important
                color: #52565C !important
            &_blue
                background-color: #3965FF1A !important
                color: #3965FF !important
            svg
                margin-left: 7px
        &_v
            &_2
                background: transparent
                border: none
                padding: 9px 14px
                height: auto
                align-items: flex-end
                justify-content: flex-start
                font-size: 14px
                border-radius: 4px
                transition: .2s
                svg
                    fill: #3E4755
                    width: 14px
                    height: 14px
                    margin-left: 2px
                &_visible, &:hover
                    box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
                    background: #fff
            &_3
                background: transparent
                border: 1px solid transparent !important
                padding: 0
                height: auto
                align-items: center
                justify-content: flex-start
                font-size: 14px
                font-family: Inter, sans-serif
                border-radius: 4px
                transition: .2s
                line-height: 22px
                color: #323030
                svg
                    width: 22px
                    height: 22px
                    margin-left: 5px
                    path
                        transition: .2s
                        fill: #323030
                &_visible, &:hover
                    color: #3965FF
                    svg
                        path
                            fill: #3965FF


        &_orange
            border: none
            border-bottom: 2px solid #ff7439 !important
            border-radius: 0
            min-height: 55px
            padding: 10px 10px 0 0
            font-size: 16px

    &__modal
        position: absolute
        left: -1px
        top: 50px
        width: calc(100% + 2px)
        background-color: #fff
        padding: 10px 4px 10px 8px
        z-index: 55333
        box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
        border-radius: 10px
        border: 1px solid #fff
        margin-bottom: 10px
        transition: .2s
        &_flat
            max-width: 300px
            min-width: 250px
            top: 15px
            right: 0
            left: auto

        &_pointer
            max-width: 350px
        &_colored
            top: calc(100% + 10px)
            width: 300px
            .custom-scroll
                max-height: none !important
        &_large
            .custom-scroll
                max-height: 300px !important
        &_capitalize
            text-transform: capitalize
        &_bottom
            top: auto
            bottom: -10px
        &_bottom_max-width
            top: auto
            width: auto
            bottom: -10px
            padding: 10px 8px 10px 8px
        &_top_max-width
            top: 0
            width: auto
            bottom: -10px
            padding: 10px 8px 10px 8px
        &_popup
            top: 0
            min-width: 400px
            padding: 10px
        &_v
            &_2
                width: 225px
            &_3
                top: 30px
        &::v-deep
            .custom-calendar
                width: 100%
    &__list-item
        min-height: 40px
        height: auto
        display: flex
        cursor: pointer
        align-items: center
        padding-left: 14px
        font-size: 13px
        font-weight: 400
        line-height: 17px
        border-radius: 5px
        transition: .3s
        &:hover, &_active
            background: #F2F7FF
        &:not(&:last-child)
            margin-bottom: 2px
        &_divide
            margin-bottom: 21px !important
            position: relative
            &::after
                content: ''
                position: absolute
                display: block
                width: 100%
                height: 1px
                background: #F3F3F3
                left: 0
                bottom: -10px

    &_v
        &_2
            span
                white-space: nowrap
                margin-right: 5px
            min-width: 0
    &_full
        width: 100%
</style>
