
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#3E4755" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5 17.25C10.0858 17.25 9.75 17.5858 9.75 18C9.75 18.4142 10.0858 18.75 10.5 18.75V17.25ZM13.01 18.75C13.4242 18.75 13.76 18.4142 13.76 18C13.76 17.5858 13.4242 17.25 13.01 17.25V18.75ZM10.5 18.75H13.01V17.25H10.5V18.75ZM6.5 3.75H17.5V2.25H6.5V3.75ZM18.75 5V19H20.25V5H18.75ZM17.5 20.25H6.5V21.75H17.5V20.25ZM5.25 19V5H3.75V19H5.25ZM6.5 20.25C5.80964 20.25 5.25 19.6904 5.25 19H3.75C3.75 20.5188 4.98122 21.75 6.5 21.75V20.25ZM18.75 19C18.75 19.6904 18.1904 20.25 17.5 20.25V21.75C19.0188 21.75 20.25 20.5188 20.25 19H18.75ZM17.5 3.75C18.1904 3.75 18.75 4.30964 18.75 5H20.25C20.25 3.48122 19.0188 2.25 17.5 2.25V3.75ZM6.5 2.25C4.98122 2.25 3.75 3.48122 3.75 5H5.25C5.25 4.30964 5.80964 3.75 6.5 3.75V2.25Z"
        />
    </svg>
</template>
<script>
export default {
    name: "TabletIcon"
}
</script>
