<template>
    <div
        :class="{ loader: !file.url }"
        v-if="isVideo && shortFile"
        style="width: max-content; position: relative; margin: 10px 0"
    >
        <video
            width="340"
            :key="(file && file.url) || 'video'"
            style="border-radius: 10px; box-shadow: 0 10px 40px rgba(213, 221, 230, 0.6)"
            :controls="!!file.url"
            preload="metadata"
            playsinline
            :poster="file.children.length ? file.children[0].url : ''"
            :src="file.url"
        ></video>
    </div>
    <div style="width: 100%" v-else-if="isAudio">
        <HowlerAudioPlayer
            :value="file"
            :key="(file && file.url) || 'audio'"
            :with-remove="parent === 'expert' || parent === 'creator'"
            @delete="checkTypeEvent"
        />
    </div>
    <div :class="{ 'full-width': listFile }" v-else>
        <div class="file-new-view" :class="{ 'file-new-view__short': shortFile, 'file-new-view__list': listFile }">
            <div class="file-new-view__icon flex-center">
                <LoaderBlock v-if="!file.extension" :noPadding="true" :green-color="true" />
                <img
                    height="24"
                    v-else-if="isImageFile"
                    :src="file.url || file.preview_path"
                    :alt="file.original_name"
                />
                <component v-else-if="showFileTypeIcon" :is="setComponentName(file.extension)" />
                <DocFileIcon v-else />
            </div>
            <div
                @click="downloadWithChoosingPath()"
                class="file-new-view__name"
                :class="{ 'cursor-pointer': isCanDownload(file) }"
            >
                <span
                    class="file-new-view__name_self"
                    :title="file.original_name"
                    :class="{ 'show-overflow': fileNameCount > 20 && !listFile }"
                >
                    {{ sliceText(file.original_name) }}
                </span>
                <template v-if="!example">.{{ file.extension }}</template>
            </div>
            <div class="file-new-view__download flex-center" @click="checkTypeEvent">
                <DeleteIcon class="hovered hovered-red" width="22" v-if="parent === 'expert' || parent === 'creator'" />
                <DownloadIcon v-else-if="isCanDownload(file)" />
            </div>
        </div>
        <div v-if="isDocPreviewVisible(file)">
            <flex-container class="mt-10 ml-10 cursor-pointer">
                <default-description
                    class="mr-16"
                    @click.native="
                        file.allow_doc_preview = !file.allow_doc_preview
                        $emit('save')
                    "
                    :size="14"
                    :line-height="16"
                    :weight="400"
                >
                    {{ t("create-course.doc_preview") }}
                </default-description>
                <CustomSwitcher @update="$emit('save')" v-model="file.allow_doc_preview" />
            </flex-container>

            <flex-container class="mt-15 ml-10 cursor-pointer">
                <default-description
                    class="mr-16"
                    @click.native="
                        file.allow_doc_download = !file.allow_doc_download
                        $emit('save')
                    "
                    :size="14"
                    :line-height="16"
                    :weight="400"
                >
                    {{ t("create-course.doc_download") }}
                </default-description>
                <CustomSwitcher @update="$emit('save')" v-model="file.allow_doc_download" />
            </flex-container>
        </div>
    </div>
</template>

<script>
import FileDocxIcon from "@icons/FileDocxIcon"
import FileExcelIcon from "@icons/FileExcelIcon"
import FilePdfIcon from "@icons/FilePdfIcon"
import FilePptIcon from "@icons/FilePptIcon.vue"
import FileSimpleIcon from "@icons/FileSimpleIcon"
import DownloadIcon from "@icons/DownloadIcon"
import DeleteIcon from "@icons/DeleteIcon"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import RecordingTrack from "@components/Recording/components/RecordingTrack.vue"
import DocFileIcon from "@icons/DocFileIcon"
import DeleteModal from "@expert-components/CreateCourse/DeleteModal"
import SimpleDeleteModal from "@components/ModalWindow/SimpleDeleteModal"
import RecordingScript from "@components/Recording/components/RecordingScript.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import CustomSwitcher from "@components/Forms/CustomSwitcher.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import HowlerAudioPlayer from "@components/Recording/components/HowlerAudioPlayer.vue"
//
export default {
    name: "FileNewView",
    components: {
        HowlerAudioPlayer,
        FlexContainer,
        CustomSwitcher,
        DefaultDescription,
        RecordingScript,
        FilePptIcon,
        RecordingTrack,
        LoaderBlock,
        SimpleDeleteModal,
        DeleteModal,
        DeleteIcon,
        DocFileIcon,
        DownloadIcon,
        FileSimpleIcon,
        FilePdfIcon,
        FileExcelIcon,
        FileDocxIcon
    },
    props: {
        file: {
            type: Object,
            required: true
        },
        shortFile: {
            type: Boolean,
            default: false
        },
        listFile: {
            type: Boolean,
            default: false
        },
        parent: {
            type: String,
            default: ""
        },
        example: {
            type: Boolean,
            default: false
        },
        showFileTypeIcon: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        docDownload(val) {
            if (val === null) {
                this.file.allow_doc_download = true
            }
        }
    },
    created() {
        if (this.file.allow_doc_download === null) {
            this.file.allow_doc_download = true
        }
    },
    computed: {
        docDownload() {
            return this.file.allow_doc_download
        },
        isVideo() {
            // if (this.shortFile) {
            //     return false
            // }
            if (!this.file || (this.file && !this.file.mime_type)) {
                return false
            }
            return this.file.mime_type.includes("video")
        },
        isImageFile() {
            const validMimeTypes = [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/webp",
                "image/svg+xml",
                "image/apng",
                "image/x-icon",
                "image/bmp"
            ]

            return validMimeTypes.includes(this.file.mime_type)
        },
        isAudio() {
            // if (this.shortFile) {
            //     return false
            // }
            if (!this.file || (this.file && !this.file.mime_type)) {
                return false
            }
            return this.file.mime_type.includes("audio")
        }
    },
    data() {
        return {
            fileNameCount: 0
        }
    },
    methods: {
        isDocPreviewVisible(file) {
            return this.parent === "expert" && this.isDocPreviewVisibleExtension(file)
        },
        isDocPreviewVisibleExtension(file) {
            return (
                file.extension === "pdf" ||
                file.extension === "doc" ||
                file.extension === "docx" ||
                file.extension === "ppt" ||
                file.extension === "pptx"
            )
        },
        isCanDownload(file) {
            if (this.example) {
                return true
            }

            if (this.isDocPreviewVisibleExtension(file)) {
                if (file.hasOwnProperty("allow_doc_download")) {
                    return file.allow_doc_download
                }

                if (file.pivot && file.pivot.hasOwnProperty("allow_doc_download")) {
                    return file?.pivot?.allow_doc_download
                }
            }

            return true
        },
        checkTypeEvent() {
            if (this.parent === "expert" || this.parent === "creator") {
                this.$emit("file:delete", this.file)
            } else {
                this.downloadWithChoosingPath()
            }
        },
        downloadWithChoosingPath() {
            if (!this.isCanDownload(this.file)) {
                return
            }
            if (this.file.uuid && this.isVideo) {
                let endPoint = `/api/v1/files/${this.file.uuid}/download?origin=1`

                const newWindow = window.open(endPoint, "_blank")
                newWindow.document.title = this.file.original_name
            } else {
                const tag = document.createElement("a")
                tag.href = this.file.url
                tag.download = this.file.original_name + "." + this.file.extension
                tag.target = "_blank"
                tag.style.opacity = 0
                tag.click()
            }
        },
        sliceText(fileName) {
            if (this.example) {
                return fileName
            }
            const match = fileName.match(/(.+)\.\w+$/)
            if (match) {
                const name = match[1]
                this.fileNameCount = name.length
                return name
            }
            return "file"
        },
        setComponentName(fileType) {
            switch (fileType) {
                case "docx":
                case "doc":
                    return "FileDocxIcon"
                case "ppt":
                case "pptx":
                    return "FilePptIcon"
                case "pdf":
                    return "FilePdfIcon"
                case "xlsx":
                case "xls":
                case "csv":
                case "xlm":
                    return "FileExcelIcon"
                default:
                    return "FileSimpleIcon"
            }
        },
        deleteFile() {
            this.$emit("delete")
            this.handleDeleteModal(false)
        }
    }
}
</script>

<style scoped lang="sass">
@import 'resources/sass/vars'
.mr-16
    margin-right: 16px !important
.file-new-view
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 5px
    background: #fff
    width: 320px
    padding: 7px 15px 7px 7px
    box-shadow: 0px 2px 5px 0px #809EBF40
    @media screen and (max-width: 1300px)
        width: 305px
    @media screen and (max-width: 990px)
        width: 273px
    @media screen and (max-width: 640px)
        width: 100%
    &__short
        border: 1px solid #E0E0EC
        padding: 6px 10px
        width: auto
        @media screen and (max-width: 990px)
            width: 214px
        @media screen and (max-width: 640px)
            width: 100%
    &__list
        border: none
        padding: 0
        width: 100%
        background-color: transparent
        box-shadow: none

        .file-new-view__download
            order: 0
            margin-right: 12px
            width: 20px
            height: 20px
            min-width: 20px
            &:hover
                box-shadow: 0px 2px 5px 0px #809EBF40
                background-color: #fff
            svg
                fill: #DD4141
        .file-new-view__icon
            order: 2
            width: 20px
            height: 20px
            min-width: 20px
        .file-new-view__name
            order: 3
            justify-content: flex-start
    &__icon
        min-width: 40px
        width: 40px
        height: 40px
        border-radius: 4px
    &__short &__icon
        min-width: 24px
        width: 24px
        height: 24px
    &__download
        min-width: 24px
        width: 24px
        height: 24px
        border-radius: 4px
        cursor: pointer
        svg
            transition: $transitionTime
            &:hover
                fill: $accent-color5
        &_delete
            svg
                transition: $transitionTime
                fill: $svg-color
                &:hover
                    fill: $danger-color
    &__name
        flex: 1
        padding-left: 10px
        padding-right: 15px
        display: flex
        align-items: center
        justify-content: center
        font-weight: 400
        font-size: 13px
        line-height: 130%
        color: #52565C
        @media screen and (max-width: 990px)
            padding-left: 5px
            padding-right: 10px
        @media screen and (max-width: 480px)
            font-size: 12px
        &_self
            white-space: nowrap
            overflow: hidden
            max-width: 165px
            padding: 2px 0
            display: inline-block
            @media screen and (max-width: 1300px)
                max-width: 146px
            @media screen and (max-width: 990px)
                max-width: 130px
            @media screen and (max-width: 640px)
                max-width: 250px
            @media screen and (max-width: 480px)
                max-width: 136px
            &.show-overflow
                position: relative
                &::after
                    content: ""
                    position: absolute
                    top: 0
                    bottom: 0
                    right: 0
                    width: 30px
                    background: linear-gradient(90deg, rgba(2,0,36,0.020028079591211445) 0%, rgba(255,255,255,0.8015406846332283) 35%, rgba(255,255,255,1) 100%)
    &__short &__name_self
        @media screen and (max-width: 990px)
            max-width: 107px
        @media screen and (max-width: 640px)
            max-width: 190px
        @media screen and (max-width: 480px)
            max-width: 107px
</style>
