import axios from "~axios"

export default {
    namespaced: true,
    state: {
        langs: []
    },
    getters: {
        getLangs(state) {
            return state.langs
        }
    },
    mutations: {
        setLangs(state, payload) {
            state.langs = payload
        }
    },
    actions: {
        async fetchLangs({ commit }, groups = "") {
            const saveLangs = (data, keys) => {
                    commit("setLangs", data)

                    localStorage.setItem(
                        "translations",
                        JSON.stringify({
                            locale: window.app_current_locale,
                            groups: keys,
                            keys: data
                        })
                    )
                },
                partOfTranslation = "expert,create-account",
                allTranslations =
                    "app-meta,action,analytic,auth,automation,cabinet,common,contact,course,create-account,create-course,expert," +
                    "footer,partners,integrations,marketing,mediateka,notifications," +
                    "pagination,passwords,payments,portal,product,profile,profile-edit,settings,order," +
                    "streams,marathon,webinar,user,user-menu,validation,communication,builder,letter-builder,quiz," +
                    "closed-groups,cabinet,pass-sharing,automation-condition,managers",
                translations = JSON.parse(localStorage.getItem("translations"))

            let updateTranslations = Boolean(translations)
            if (!translations || updateTranslations) {
                await axios
                    .get(window.cached_routes["translation-groups"], {
                        params: {
                            groups: updateTranslations ? groups || allTranslations : partOfTranslation,
                            lang: window.app_current_locale
                        }
                    })
                    .then(async response => {
                        const data = response.data

                        saveLangs(data, updateTranslations ? allTranslations : partOfTranslation)

                        if (!updateTranslations) {
                            await axios
                                .get(window.cached_routes["translation-groups"], {
                                    params: {
                                        groups: allTranslations,
                                        lang: window.app_current_locale
                                    }
                                })
                                .then(response => {
                                    const data = response.data

                                    saveLangs(data, allTranslations)
                                })
                        }
                    })
            } else {
                commit("setLangs", translations.keys)
            }
        }
    }
}
