<script>
const props = {
    tag: {
        type: String,
        default: "button",
        required: false
    },
    attrs: {
        type: Object,
        default: () => {},
        required: false
    },
    /**
     * @values "small", "sm", "medium", "x-medium", "large", "x-large", "large-float"
     **/
    size: {
        type: String,
        default: "medium",
        required: false
    },
    /**
     * @values "green", "orange", "blue", "white", "red", "cancel"
     **/
    background: {
        type: String,
        default: "orange",
        required: false
    },
    /**
     * @example "Save", "Submit"
     **/
    text: {
        type: String,
        default: "Button Text",
        required: false
    },
    /**
     * @example "10px", "20px 20px 15px 5px"
     **/
    padding: {
        type: String,
        default: "",
        required: false
    },
    /**
     * @example "blue", "orange", "gray", "black", "dark-blue"
     **/
    textColor: {
        type: String,
        default: "",
        required: false
    },
    /**
     * @values "gray", "black", "orange"
     **/
    borderColor: {
        type: String,
        default: "",
        required: false
    },
    type: {
        type: String,
        default: "button",
        required: false
    },
    additionalClass: {
        type: String
    },
    /**
     * @example 100, 25
     **/
    borderRadius: {
        type: Number,
        default: 7,
        required: false
    },
    width: {
        type: [Number, String],
        default: 0,
        required: false
    },
    height: {
        type: [Number, String],
        default: 0,
        required: false
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false
    },
    noShadow: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false,
        required: false
    },
    fullWidth: {
        type: Boolean,
        default: false,
        required: false
    },
    useRouter: {
        type: Boolean,
        default: true
    },
    reverseIcon: {
        type: Boolean,
        default: false
    },
    fontWeight: Number,
    fontSize: Number
}

export default {
    name: "DefaultButton",
    props,
    render(createElement) {
        return createElement(
            this.tag,
            {
                class: [this.buttonClass, this.additionalClass],
                style: this.buttonStyle,
                attrs: {
                    type: this.tag === "a" ? "" : this.type,
                    ...this.attrs
                },
                on: {
                    mouseenter: this.mouseEnterHandler,
                    mouseleave: this.mouseLeaveHandler,
                    click: this.clickHandler
                }
            },
            this.reverseIcon ? [this.text, this.$slots.icon] : [this.$slots.icon, this.text]
        )
    },
    data() {
        return {
            hovered: false
        }
    },
    methods: {
        mouseEnterHandler() {
            this.hovered = true
        },
        mouseLeaveHandler() {
            this.hovered = false
        },
        clickHandler(e) {
            if (this.tag === "a" && this.attrs.target !== "_blank") {
                if (this.useRouter) {
                    e.preventDefault()
                    this.$router.push(this.attrs.href).catch()
                }
            } else {
                this.$emit("click", e)
            }
        }
    },
    computed: {
        includesDefaultColor() {
            return ["green", "orange", "blue", "white", "red", "cancel", "green-light", "dark-blue"].includes(
                this.background
            )
        },
        buttonClass() {
            let base = `default-button default-button_${this.size}`
            if (this.includesDefaultColor) {
                base += ` default-button_${this.background}`
                if (this.loading) {
                    base += ` default-button_${this.background}_loader`
                }
            }
            if (this.$slots.icon) {
                base += ` default-button_${this.size}_with_icon`
                base += ` default-button_${this.background}_with_icon`
            }
            if (this.borderColor) {
                base += ` default-button_border-color__${this.borderColor}`
            }
            if (this.textColor) {
                base += ` default-button_text-color__${this.textColor}`
            }
            if (this.loading) {
                base += ` loader`
            }
            return base
        },
        buttonStyle() {
            let style = {}
            style.borderRadius = `${this.borderRadius}px`
            if (!this.includesDefaultColor) {
                style.background = this.background
            }
            if (this.disabled) {
                style.opacity = 0.4
                style.pointerEvents = "none"
            }
            if (this.fontSize) {
                style.fontSize = `${this.fontSize}px`
            }
            if (this.fontWeight) {
                style.fontWeight = this.fontWeight
            }
            if (this.width && this.height) {
                style.width = `${this.width}px`
                style.height = `${this.height}px`
            }
            if (this.width) {
                style.width = `${this.width}px`
            }
            if (this.fullWidth) {
                style.width = "100%"
            }
            if (this.padding) {
                style.padding = this.padding
            }
            if (this.noShadow) {
                style.boxShadow = "none"
            }
            if (this.width) {
                style.width = `${this.width}px`
            }
            if (this.height) {
                style.height = `${this.height}px`
            }
            return style
        }
    }
}
</script>
<style lang="sass">
@import "resources/sass/vars"
.default-button
    display: flex
    align-items: center
    justify-content: center
    font-weight: 500
    transition: .3s
    color: #fff
    position: relative
    font-family: "Inter", sans-serif !important
    &.blue-hover
        &:hover
            color: #3965FF
            border-color: transparent
            svg
                stroke: #3965FF
    .file-search
        margin-right: 8px
    &.inter
        font-family: Inter, sans-serif
    &_text-color
        &__orange
            &:hover
                border: 1px solid #FF7439 !important
            .plus-icon
                stroke: #FF7439 !important
    &.loader
        &::after
            background: url(@images/portal/loader-icon.svg) center no-repeat
        *
            opacity: 0
    &_cancel
        color: #323030
        background: transparent
        font-weight: 400 !important
        text-underline-offset: 3px
        &:hover
            text-decoration: underline
    &_orange
        background-color: #FF7439
        &:hover
            background-color: #F26122
    &_blue
        background-color: #5E7BE0
        &:hover
            background-color: #4261CF
    &_dark-blue
        background-color: #3F58AC
        &_with_icon
            padding-left: 10px !important
        &:hover
            background-color: #2F479A
    &_red
        background-color: #EB6565
        &:hover
            background-color: #DD4141
    &_white
        background-color: #ffffff
        box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25)
        border: 1px solid #fff
        color: #3965FF
        transition: .3s
        &_loader
            &::after
                width: 26px
                height: 26px
                margin-top: -13px
                margin-left: -13px
                background: url(@images/portal/loader-icon-blue.svg) center no-repeat !important
        &_with
            &_icon
                .update-icon
                    margin-left: 4px
                .plus-icon
                    min-width: 23px
                    margin-right: 8px
                    stroke: #3965FF
        &:hover
            box-shadow: 0 2px 5px rgba(128, 158, 191, 0.25)
            border: 1px solid #3965FF
    &_text-color
        &__orange
            .plus-icon
                stroke: #FF7439 !important
            &::after
                background: url(@images/portal/loader-icon-orange.svg) center no-repeat !important
    &_green
        background-color: #30C58F
        &:hover
            background-color: #15A06E
    &_x-large
        height: 45px
        padding: 0 20px
        font-size: 16px
    &_large-float
        padding: 12px 30px
        font-size: 16px
        font-weight: 500
        line-height: 160%
    &_large
        height: 40px
        padding: 0 20px
        font-size: 15px
    &_x-medium
        font-weight: 400
        height: 40px
        padding: 0 20px
        font-size: 14px
    &_medium
        height: 38px
        padding: 0 20px
        font-size: 14px
        font-weight: 500
        font-family: Inter, sans-serif !important
    &_sm
        height: 33px
        padding: 0 20px
        font-size: 14px
    &_small
        height: 25px
        padding: 0 17px
        font-size: 12px
        &.p-0
            padding: 0
        &_with_icon
            padding-left: 10px
            i
                margin-right: 2px
    &_text-color
        &__orange
            color: #FF7439
            &:hover
                border-color: #FF7439
        &__gray
            color: $text-accent-color
    &_border-color
        &__gray
            border-color: #E0E0EC
        &__orange
            border-color: #FF7439
    &.loader
        &::after
            width: 26px
            height: 26px
            margin-top: -13px
            margin-left: -13px
            background: url(@images/portal/loader-icon.svg) center no-repeat
    &_green-light
        height: 36px
        background-color: #F5FFF2
        padding: 10px
        color: #323030
        font-weight: 400
        box-shadow: 0px 2px 5px 0px #809EBF40
        &:hover
            background-color: #E4FDDB
</style>
